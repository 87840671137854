<div class="contact-right">
    <ng-container *ngIf="leftCard && !rightCard && !loadingCard && !isMobile">
        <!-- <ng-container *ngIf="isMobile" ></ng-container> -->
        <h3 class="heading-new font-bold mb-3">
            {{leftAgentDetails?.roles?.role?.title == 'Agent' ? 'Speak With An Agent' : 'Talk To The Builder'}}
        </h3>
        <span class="star-ico-arrow-right" (click)="toggleRight()"><i class="fa fa-angle-right"></i></span>
        <!-- <span class="star-ico-arrow-right" (swiperight)="swipe($event.type)"><i class="fa fa-angle-right"></i></span> -->
        <div class="trust-sec pad-new-add">
            <ng-container *ngIf="leftAgentDetails.claim_status == 1">
                <ng-container *ngIf="leftAgentDetails?.claim_details">
                    <div class="builder_logo_sm">
                        <img src="{{leftAgentDetails?.builder?.business_profile?.logo?.thumb}}"
                            style="cursor: pointer;">
                        <h3 class="font-bold new-card-heading" *ngIf="leftAgentDetails?.builder?.first_name">
                            {{leftAgentDetails?.builder?.first_name}}
                        </h3>
                        <h3 class="font-bold new-card-heading" *ngIf="leftAgentDetails?.builder?.first_name">
                            {{leftAgentDetails?.builder?.last_name || ''}}</h3>
                    </div>
                    <div class="pl-3 claim-builder">
                        <h3 class="font-bold">{{leftAgentDetails?.claim_details?.first_name}}
                        </h3>
                        <h3 class="font-bold">{{leftAgentDetails?.claim_details?.last_name || ''}}</h3>
                        <ng-container *ngIf="leftAgentDetails?.assigned_rep">
                            <h5 *ngIf="leftAgentDetails?.claim_details?.phone"><i class="fa fa-phone mr-1"
                                    aria-hidden="true"></i><a
                                    href="{{'tel:'+leftAgentDetails?.claim_details?.phone}}">{{leftAgentDetails?.claim_details?.phone | phone}}</a>
                            </h5>
                        </ng-container>
                        <ng-container *ngIf="!leftAgentDetails?.assigned_rep">
                            <h5 *ngIf="leftAgentDetails?.claim_details?.phone"><i class="fa fa-phone mr-1"
                                    aria-hidden="true"></i>{{leftAgentDetails?.claim_details?.phone | phone}}
                            </h5>
                        </ng-container>
                        <!-- <h5 class="email-contact-card" *ngIf="leftAgentDetails?.claim_details?.email">
                            <i class="fa fa-envelope-o mr-1"
                                aria-hidden="true"></i>{{leftAgentDetails?.claim_details?.email}}
                        </h5> -->
                        <h5 *ngIf="leftAgentDetails?.email">
                            <i class="fa fa-envelope-o mr-1" aria-hidden="true"></i>{{leftAgentDetails?.email}}
                        </h5>
                    </div>
                </ng-container>
                <ng-container *ngIf="!leftAgentDetails?.claim_details && leftAgentDetails.builder">
                    <div class="builder_logo_sm">
                        <img src="{{leftAgentDetails?.builder?.business_profile?.logo?.thumb}}"
                            style="cursor: pointer;">
                    </div>
                    <div class="pl-3 claim-builder">
                        <h3 class="font-bold new-card-heading">{{leftAgentDetails?.builder?.first_name}}</h3>
                        <h3 class="font-bold new-card-heading">{{leftAgentDetails?.builder?.last_name || ''}}</h3>
                        <h5 *ngIf="leftAgentDetails?.builder?.business_profile?.business_phone"><i
                                class="fa fa-phone mr-1" aria-hidden="true"></i><a
                                href="{{'tel:'+leftAgentDetails?.builder?.business_profile?.business_phone}}">{{leftAgentDetails?.builder?.business_profile?.business_phone | phone}}</a>
                        </h5>
                        <h5
                            *ngIf="leftAgentDetails?.builder?.phone && !leftAgentDetails?.builder?.business_profile?.business_phone">
                            <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                            <a
                                href="{{'tel:'+leftAgentDetails?.builder?.phone}}">{{leftAgentDetails?.builder?.phone | phone}}</a>
                        </h5>
                        <h5 class="email-contact-card" *ngIf="leftAgentDetails?.builder?.email">
                            <i class="fa fa-envelope-o mr-1" aria-hidden="true"></i>{{leftAgentDetails?.builder?.email}}
                        </h5>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="leftAgentDetails.claim_status != 1 && leftAgentDetails">
                <div class="builder_logo_sm">
                    <img src="{{leftAgentDetails?.business_profile?.logo?.thumb}}" style="cursor: pointer;">
                    <h3 class="font-bold new-card-heading" *ngIf="leftAgentDetails?.business_profile?.broker_name">
                        {{leftAgentDetails?.business_profile?.broker_name}}</h3>
                </div>
                <div class="pl-3 claim-builder">
                    <h3 class="font-bold">{{leftAgentDetails?.first_name}} </h3>
                    <h3 class="font-bold">{{leftAgentDetails?.last_name || ''}}</h3>
                    <ng-container>
                        <h5 *ngIf="leftAgentDetails?.business_profile?.business_phone"><i class="fa fa-phone mr-1"
                                aria-hidden="true"></i><a
                                href="{{'tel:'+leftAgentDetails?.business_profile?.business_phone}}">{{leftAgentDetails?.business_profile?.business_phone | phone}}</a>
                        </h5>
                        <h5 *ngIf="leftAgentDetails?.phone && !leftAgentDetails?.business_profile?.business_phone">
                            <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                            <a href="{{'tel:'+leftAgentDetails?.phone}}">{{leftAgentDetails?.phone | phone}}</a>
                        </h5>
                        <h5 *ngIf="leftAgentDetails?.email">
                            <i class="fa fa-envelope-o mr-1" aria-hidden="true"></i><a
                            href="{{'mailto:'+leftAgentDetails?.email}}">{{leftAgentDetails?.email}}</a>
                        </h5>
                        <h5 class="licno" *ngIf="leftAgentDetails?.business_profile?.license_number">
                            <label>LIC#</label><a>{{leftAgentDetails?.business_profile?.license_number}}</a>
                        </h5>
                        <!-- <p *ngIf="leftAgentDetails?.business_profile?.website_url"><a
                                href="{{leftAgentDetails?.business_profile?.website_url}}"
                                target="_blank">{{leftAgentDetails?.website_url}}</a></p> -->
                    </ng-container>
                </div>

            </ng-container>
        </div>
        <div class="business_about_div" *ngIf="leftAgentDetails.claim_status != 1">
            <p>
                {{show_more_btn ? leftAgentDetails?.business_profile?.about_business : (leftAgentDetails?.business_profile?.about_business | slice:0:85)}}
                <ng-container
                    *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.business_profile && leftAgentDetails?.business_profile?.about_business && leftAgentDetails?.business_profile?.about_business.length > 85">
                    ...</ng-container>
            </p>
            <a href="javascript:void(0);" class="ux-show-more"
                *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.business_profile && leftAgentDetails?.business_profile?.about_business && leftAgentDetails?.business_profile?.about_business.length > 85"
                (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                *ngIf="show_more_btn && leftAgentDetails && leftAgentDetails?.business_profile && leftAgentDetails?.business_profile?.about_business && leftAgentDetails?.business_profile?.about_business.length > 85"
                (click)="showLessClick($event)">[Show Less]</a>
        </div>
        <div class="business_about_div" *ngIf="leftAgentDetails.claim_status == 1 && leftAgentDetails?.claim_details">
            <p>
                {{show_more_btn ? leftAgentDetails?.claim_details?.business_profile?.about_business : (leftAgentDetails?.claim_details?.business_profile?.about_business | slice:0:85)}}
                <ng-container
                    *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.claim_details?.business_profile && leftAgentDetails?.claim_details?.business_profile?.about_business && leftAgentDetails?.claim_details?.business_profile?.about_business.length > 85">
                    ...</ng-container>
            </p>
            <a href="javascript:void(0);" class="ux-show-more"
                *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.claim_details?.business_profile && leftAgentDetails?.claim_details?.business_profile?.about_business && leftAgentDetails?.claim_details?.business_profile?.about_business.length > 85"
                (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                *ngIf="show_more_btn && leftAgentDetails && leftAgentDetails?.claim_details?.business_profile && leftAgentDetails?.claim_details?.business_profile?.about_business && leftAgentDetails?.claim_details?.business_profile?.about_business.length > 85"
                (click)="showLessClick($event)">[Show Less]</a>
        </div>
        <div class="business_about_div"
            *ngIf="leftAgentDetails.claim_status == 1 && !leftAgentDetails?.claim_details && leftAgentDetails.builder">
            <p>
                {{show_more_btn ? leftAgentDetails?.builder?.business_profile?.about_business : (leftAgentDetails?.builder?.business_profile?.about_business | slice:0:85)}}
                <ng-container
                    *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.builder?.business_profile && leftAgentDetails?.builder?.business_profile?.about_business && leftAgentDetails?.builder?.business_profile?.about_business.length > 85">
                    ...</ng-container>
            </p>
            <a href="javascript:void(0);" class="ux-show-more"
                *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.builder?.business_profile && leftAgentDetails?.builder?.business_profile?.about_business && leftAgentDetails?.builder?.business_profile?.about_business.length > 85"
                (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                *ngIf="show_more_btn && leftAgentDetails && leftAgentDetails?.builder?.business_profile && leftAgentDetails?.builder?.business_profile?.about_business && leftAgentDetails?.builder?.business_profile?.about_business.length > 85"
                (click)="showLessClick($event)">[Show Less]</a>
        </div>
        <ng-container
            *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id)&& (propertyDetails.claim_status == 0 && !claimSubmit)">
            <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3" (click)="goPremium()">Claim Your
                Spot</button>
        </ng-container>
        <ng-container
            *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id)&& propertyDetails?.claim_status == 1">
            <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3"
                (click)="openClaimModal()">Update Contact</button>
        </ng-container>
        <ng-container
            *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id)&& propertyDetails.claim_status == 2">
            <button type="submit" class="btn btn-danger pull-right btn-fav w-100 mt-3">Contact Spot
                Rejected</button>
        </ng-container>
        <!-- <ng-container
            *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id) && (propertyDetails.claim_status == 3 || claimSubmit)"> -->
        <ng-container
            *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id) && (propertyDetails.claim_status == 3)">
            <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3" disabled="true">Model Claim
                Initiated</button>
        </ng-container>
        <!-- <ng-container *ngIf="(user && user.role_slug == 'buyer') || !user">
            <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3"
                (click)="openContactModal()">Contact
                {{leftAgentDetails?.roles?.role?.title == 'Agent' ? 'Agent' : 'Builder'}}</button>
        </ng-container> -->
    </ng-container>
    <ng-container *ngIf="rightCard && !leftCard && !loadingCard && !isMobile">
        <div class="swipe" (swipe)="onSwipe($event, 1)"></div>
        <h3 class="heading-new font-bold mb-3">
            {{rightAgentDetails?.roles?.role?.title == 'Agent' ? 'Speak With An Agent' : 'Talk To The Builder'}}
        </h3>
        <span class="star-ico-arrow-left" (click)="toggleLeft()"><i class="fa fa-angle-left"></i></span>
        <span *ngIf="!isClaimedLeft && noPreferredAgent" class="star-ico-arrow-right" (click)="toggleRight()"><i
                class="fa fa-angle-right"></i></span>
        <div class="trust-sec pad-new-add">
            <div class="builder_logo_sm">
                <img src="{{rightAgentDetails?.business_profile?.logo?.thumb}}" style="cursor: pointer;">
                <h3 class="font-bold new-card-heading" *ngIf="rightAgentDetails?.business_profile?.broker_name">
                    {{rightAgentDetails?.business_profile?.broker_name}}</h3>
            </div>
            <div class="pl-3 claim-builder">
                <h3 class="font-bold">{{rightAgentDetails?.first_name}} </h3>
                <h3 class="font-bold">{{rightAgentDetails?.last_name || ''}}</h3>
                <ng-container *ngIf="rightAgentDetails?.business_profile?.business_phone">
                    <h5 *ngIf="rightAgentDetails?.business_profile?.business_phone"><i class="fa fa-phone mr-1"
                            aria-hidden="true"></i><a
                            href="{{'tel:'+rightAgentDetails?.business_profile?.business_phone}}">{{rightAgentDetails?.business_profile?.business_phone | phone}}</a>
                    </h5>
                    <h5 *ngIf="rightAgentDetails?.phone && !rightAgentDetails?.business_profile?.business_phone">
                        <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                        <a href="{{'tel:'+rightAgentDetails?.phone}}">{{rightAgentDetails?.phone | phone}}</a>
                    </h5>

                </ng-container>
                <h5 *ngIf="rightAgentDetails?.email">
                    <i class="fa fa-envelope-o mr-1" aria-hidden="true"></i><a
                    href="{{'mailto:'+rightAgentDetails?.email}}">{{rightAgentDetails?.email}}</a>
                </h5>
                <h5 class="licno" *ngIf="rightAgentDetails?.business_profile?.license_number">
                    <label>LIC#</label><a>{{rightAgentDetails?.business_profile?.license_number}}</a>
                </h5>
                <!-- <p *ngIf="rightAgentDetails?.business_profile?.website_url"><a
                        href="{{rightAgentDetails?.business_profile?.website_url}}"
                        target="_blank">{{rightAgentDetails?.website_url}}</a></p> -->
            </div>
        </div>
        {{rightAgentDetails?.business_profile?.about_business}}
        <div class="business_about_div">
            <p>
                {{show_more_btn ? rightAgentDetails?.business_profile?.about_business : (rightAgentDetails?.business_profile?.about_business | slice:0:85)}}
                <ng-container
                    *ngIf="!show_more_btn && rightAgentDetails && rightAgentDetails?.business_profile && rightAgentDetails?.business_profile?.about_business && rightAgentDetails?.business_profile?.about_business.length > 85">
                    ...</ng-container>
            </p>
            <a href="javascript:void(0);" class="ux-show-more"
                *ngIf="!show_more_btn && rightAgentDetails && rightAgentDetails?.business_profile && rightAgentDetails?.business_profile?.about_business && rightAgentDetails?.business_profile?.about_business.length > 85"
                (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                *ngIf="show_more_btn && rightAgentDetails && rightAgentDetails?.business_profile && rightAgentDetails?.business_profile?.about_business && rightAgentDetails?.business_profile?.about_business.length > 85"
                (click)="showLessClick($event)">[Show Less]</a>
        </div>
        <!-- <ng-container *ngIf="(user && user.role_slug == 'buyer') || !user">
            <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3"
                (click)="openContactModal()">Contact
                {{rightAgentDetails?.roles?.role?.title == 'Agent' ? 'Agent' : 'Builder'}}</button>
        </ng-container> -->
    </ng-container>


    <ng-container *ngIf="leftCard && !rightCard && isMobile && !loadingCard">
        <div class="swipe" (swipe)="onSwipe($event, 0)">
            <h3 class="heading-new font-bold mb-3">
                {{leftAgentDetails?.roles?.role?.title == 'Agent' ? 'Speak With An Agent' : 'Talk To The Builder'}}
            </h3>
            <div class="swipe-new" *ngIf="!firstAppear"><span><img src="/assets/images/swipe.png"></span>Swipe</div>

            <span class="star-ico-arrow-right" (click)="toggleRight()"><i class="fa fa-angle-right"></i></span>
            <div class="trust-sec pad-new-add">
                <ng-container *ngIf="leftAgentDetails.claim_status == 1">
                    <ng-container *ngIf="leftAgentDetails?.claim_details">
                        <div class="builder_logo_sm">
                            <img src="{{leftAgentDetails?.builder?.business_profile?.logo?.thumb}}"
                                style="cursor: pointer;">
                            <h3 class="font-bold new-card-heading" *ngIf="leftAgentDetails?.builder?.first_name">
                                {{leftAgentDetails?.builder?.first_name}}
                            </h3>
                            <h3 class="font-bold new-card-heading" *ngIf="leftAgentDetails?.builder?.first_name">
                                {{leftAgentDetails?.builder?.last_name || ''}}</h3>
                        </div>
                        <div class="pl-3 claim-builder">
                            <h3 class="font-bold">{{leftAgentDetails?.claim_details?.first_name}}
                            </h3>
                            <h3 class="font-bold">{{leftAgentDetails?.claim_details?.last_name || ''}}</h3>
                            <ng-container *ngIf="leftAgentDetails?.assigned_rep">
                                <h5 *ngIf="leftAgentDetails?.claim_details?.phone"><i class="fa fa-phone mr-1"
                                        aria-hidden="true"></i><a
                                        href="{{'tel:'+leftAgentDetails?.claim_details?.phone}}">{{leftAgentDetails?.claim_details?.phone | phone}}</a>
                                </h5>
                            </ng-container>
                            <ng-container *ngIf="!leftAgentDetails?.assigned_rep">
                                <h5 *ngIf="leftAgentDetails?.claim_details?.phone"><i class="fa fa-phone mr-1"
                                        aria-hidden="true"></i>{{leftAgentDetails?.claim_details?.phone | phone}}
                                </h5>
                            </ng-container>
                            <!-- <h5 class="email-contact-card" *ngIf="leftAgentDetails?.claim_details?.email">
                                <i class="fa fa-envelope-o mr-1"
                                    aria-hidden="true"></i>{{leftAgentDetails?.claim_details?.email}}
                            </h5> -->
                            <h5 *ngIf="leftAgentDetails?.email">
                                <i class="fa fa-envelope-o mr-1"
                                    aria-hidden="true"></i>{{leftAgentDetails?.email}}
                            </h5>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!leftAgentDetails?.claim_details && leftAgentDetails.builder">
                        <div class="builder_logo_sm">
                            <img src="{{leftAgentDetails?.builder?.business_profile?.logo?.thumb}}"
                                style="cursor: pointer;">
                        </div>
                        <div class="pl-3 claim-builder">
                            <h3 class="font-bold new-card-heading">{{leftAgentDetails?.builder?.first_name}}</h3>
                            <h3 class="font-bold new-card-heading">{{leftAgentDetails?.builder?.last_name || ''}}</h3>
                            <h5 *ngIf="leftAgentDetails?.builder?.business_profile?.business_phone"><i
                                    class="fa fa-phone mr-1" aria-hidden="true"></i><a
                                    href="{{'tel:'+leftAgentDetails?.builder?.business_profile?.business_phone}}">{{leftAgentDetails?.builder?.business_profile?.business_phone | phone}}</a>
                            </h5>
                            <h5
                                *ngIf="leftAgentDetails?.builder?.phone && !leftAgentDetails?.builder?.business_profile?.business_phone">
                                <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                                <a
                                    href="{{'tel:'+leftAgentDetails?.builder?.phone}}">{{leftAgentDetails?.builder?.phone | phone}}</a>
                            </h5>
                            <h5 class="email-contact-card" *ngIf="leftAgentDetails?.builder?.email">
                                <i class="fa fa-envelope-o mr-1"
                                    aria-hidden="true"></i>{{leftAgentDetails?.builder?.email}}
                            </h5>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="leftAgentDetails.claim_status != 1 && leftAgentDetails">
                    <div class="builder_logo_sm">
                        <img src="{{leftAgentDetails?.business_profile?.logo?.thumb}}" style="cursor: pointer;">
                        <h3 class="font-bold new-card-heading" *ngIf="leftAgentDetails?.business_profile?.broker_name">
                            {{leftAgentDetails?.business_profile?.broker_name}}</h3>
                    </div>
                    <div class="pl-3 claim-builder">
                        <h3 class="font-bold">{{leftAgentDetails?.first_name}} </h3>
                        <h3 class="font-bold">{{leftAgentDetails?.last_name || ''}}</h3>
                        <ng-container>
                            <h5 *ngIf="leftAgentDetails?.business_profile?.business_phone"><i class="fa fa-phone mr-1"
                                    aria-hidden="true"></i><a
                                    href="{{'tel:'+leftAgentDetails?.business_profile?.business_phone}}">{{leftAgentDetails?.business_profile?.business_phone | phone}}</a>
                            </h5>
                            <h5 *ngIf="leftAgentDetails?.phone && !leftAgentDetails?.business_profile?.business_phone">
                                <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                                <a href="{{'tel:'+leftAgentDetails?.phone}}">{{leftAgentDetails?.phone | phone}}</a>
                            </h5>
                            <h5 *ngIf="leftAgentDetails?.email">
                                <i class="fa fa-envelope-o mr-1" aria-hidden="true"></i><a
                                href="{{'mailto:'+leftAgentDetails?.email}}">{{leftAgentDetails?.email}}</a>
                            </h5>
                            <h5 class="licno" *ngIf="leftAgentDetails?.business_profile?.license_number">
                                <label>LIC#</label><a>{{leftAgentDetails?.business_profile?.license_number}}</a>
                            </h5>
                            <!-- <p *ngIf="leftAgentDetails?.business_profile?.website_url"><a
                                    href="{{leftAgentDetails?.business_profile?.website_url}}"
                                    target="_blank">{{leftAgentDetails?.website_url}}</a></p> -->
                        </ng-container>
                    </div>

                </ng-container>
            </div>
            <div class="business_about_div" *ngIf="leftAgentDetails.claim_status != 1">
                <p>
                    {{show_more_btn ? leftAgentDetails?.business_profile?.about_business : (leftAgentDetails?.business_profile?.about_business | slice:0:85)}}
                    <ng-container
                        *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.business_profile && leftAgentDetails?.business_profile?.about_business && leftAgentDetails?.business_profile?.about_business.length > 85">
                        ...</ng-container>
                </p>
                <a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.business_profile && leftAgentDetails?.business_profile?.about_business && leftAgentDetails?.business_profile?.about_business.length > 85"
                    (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="show_more_btn && leftAgentDetails && leftAgentDetails?.business_profile && leftAgentDetails?.business_profile?.about_business && leftAgentDetails?.business_profile?.about_business.length > 85"
                    (click)="showLessClick($event)">[Show Less]</a>
            </div>
            <div class="business_about_div"
                *ngIf="leftAgentDetails.claim_status == 1 && leftAgentDetails?.claim_details">
                <p>
                    {{show_more_btn ? leftAgentDetails?.claim_details?.business_profile?.about_business : (leftAgentDetails?.claim_details?.business_profile?.about_business | slice:0:85)}}
                    <ng-container
                        *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.claim_details?.business_profile && leftAgentDetails?.claim_details?.business_profile?.about_business && leftAgentDetails?.claim_details?.business_profile?.about_business.length > 85">
                        ...</ng-container>
                </p>
                <a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.claim_details?.business_profile && leftAgentDetails?.claim_details?.business_profile?.about_business && leftAgentDetails?.claim_details?.business_profile?.about_business.length > 85"
                    (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="show_more_btn && leftAgentDetails && leftAgentDetails?.claim_details?.business_profile && leftAgentDetails?.claim_details?.business_profile?.about_business && leftAgentDetails?.claim_details?.business_profile?.about_business.length > 85"
                    (click)="showLessClick($event)">[Show Less]</a>
            </div>
            <div class="business_about_div"
                *ngIf="leftAgentDetails.claim_status == 1 && !leftAgentDetails?.claim_details && leftAgentDetails.builder">
                <p>
                    {{show_more_btn ? leftAgentDetails?.builder?.business_profile?.about_business : (leftAgentDetails?.builder?.business_profile?.about_business | slice:0:85)}}
                    <ng-container
                        *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.builder?.business_profile && leftAgentDetails?.builder?.business_profile?.about_business && leftAgentDetails?.builder?.business_profile?.about_business.length > 85">
                        ...</ng-container>
                </p>
                <a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="!show_more_btn && leftAgentDetails && leftAgentDetails?.builder?.business_profile && leftAgentDetails?.builder?.business_profile?.about_business && leftAgentDetails?.builder?.business_profile?.about_business.length > 85"
                    (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="show_more_btn && leftAgentDetails && leftAgentDetails?.builder?.business_profile && leftAgentDetails?.builder?.business_profile?.about_business && leftAgentDetails?.builder?.business_profile?.about_business.length > 85"
                    (click)="showLessClick($event)">[Show Less]</a>
            </div>
            <ng-container
                *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id)&& (propertyDetails.claim_status == 0 && !claimSubmit)">
                <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3" (click)="goPremium()">Claim
                    Your Spot</button>
            </ng-container>
            <ng-container
                *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id)&& propertyDetails?.claim_status == 1">
                <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3"
                    (click)="openClaimModal()">Update Contact</button>
            </ng-container>
            <ng-container
                *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id)&& propertyDetails.claim_status == 2">
                <button type="submit" class="btn btn-danger pull-right btn-fav w-100 mt-3">Contact Spot
                    Rejected</button>
            </ng-container>
            <ng-container
                *ngIf="(user && user.role_slug == 'builder' && user.id == propertyDetails?.builder_id) && (propertyDetails.claim_status == 3)">
                <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3" disabled="true">Model Claim
                    Initiated</button>
            </ng-container>
            <!-- <ng-container *ngIf="(user && user.role_slug == 'buyer') || !user">
                <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3"
                    (click)="openContactModal()">Contact
                    {{leftAgentDetails?.roles?.role?.title == 'Agent' ? 'Agent' : 'Builder'}}</button>
            </ng-container> -->
        </div>
    </ng-container>
    <ng-container *ngIf="rightCard && !leftCard && isMobile && !loadingCard">
        {{"4th"}}
        <div class="swipe" (swipe)="onSwipe($event, 1)">
            <h3 class="heading-new font-bold mb-3">
                {{rightAgentDetails?.roles?.role?.title == 'Agent' ? 'Speak With An Agent' : 'Talk To The Builder'}}
            </h3>
            <!-- <div class="swipe-new"><span><img src="/assets/images/swipe.png"></span>Swipe</div> -->
            <span class="star-ico-arrow-left" (click)="toggleLeft()"><i class="fa fa-angle-left"></i></span>

            <span *ngIf="!isClaimedLeft && noPreferredAgent" class="star-ico-arrow-right" (click)="toggleRight()"><i
                    class="fa fa-angle-right"></i></span>
            <div class="trust-sec pad-new-add">
                <div class="builder_logo_sm">
                    <img src="{{rightAgentDetails?.business_profile?.logo?.thumb}}" style="cursor: pointer;">
                    <h3 class="font-bold new-card-heading" *ngIf="rightAgentDetails?.business_profile?.broker_name">
                        {{rightAgentDetails?.business_profile?.broker_name}}</h3>
                </div>
                <div class="pl-3 claim-builder">
                    <h3 class="font-bold">{{rightAgentDetails?.first_name}} </h3>
                    <h3 class="font-bold">{{rightAgentDetails?.last_name || ''}}</h3>
                    <ng-container *ngIf="rightAgentDetails?.business_profile?.business_phone">
                        <h5 *ngIf="rightAgentDetails?.business_profile?.business_phone"><i class="fa fa-phone mr-1"
                                aria-hidden="true"></i><a
                                href="{{'tel:'+rightAgentDetails?.business_profile?.business_phone}}">{{rightAgentDetails?.business_profile?.business_phone | phone}}</a>
                        </h5>
                        <h5 *ngIf="rightAgentDetails?.phone && !rightAgentDetails?.business_profile?.business_phone">
                            <i class="fa fa-phone mr-1" aria-hidden="true"></i>
                            <a href="{{'tel:'+rightAgentDetails?.phone}}">{{rightAgentDetails?.phone | phone}}</a>
                        </h5>
                        <h5 *ngIf="rightAgentDetails?.email">
                            <i class="fa fa-envelope-o mr-1"
                                aria-hidden="true"></i>{{rightAgentDetails?.email}}
                        </h5>

                    </ng-container>
                    <h5 class="licno" *ngIf="rightAgentDetails?.business_profile?.license_number">
                        <label>LIC#</label><a>{{rightAgentDetails?.business_profile?.license_number}}</a>
                    </h5>
                    <!-- <p *ngIf="rightAgentDetails?.business_profile?.website_url"><a
                                href="{{rightAgentDetails?.business_profile?.website_url}}"
                                target="_blank">{{rightAgentDetails?.website_url}}</a></p> -->
                </div>
            </div>
            {{rightAgentDetails?.business_profile?.about_business}}
            <div class="business_about_div">
                <p>
                    {{show_more_btn ? rightAgentDetails?.business_profile?.about_business : (rightAgentDetails?.business_profile?.about_business | slice:0:85)}}
                    <ng-container
                        *ngIf="!show_more_btn && rightAgentDetails && rightAgentDetails?.business_profile && rightAgentDetails?.business_profile?.about_business && rightAgentDetails?.business_profile?.about_business.length > 85">
                        ...</ng-container>
                </p>
                <a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="!show_more_btn && rightAgentDetails && rightAgentDetails?.business_profile && rightAgentDetails?.business_profile?.about_business && rightAgentDetails?.business_profile?.about_business.length > 85"
                    (click)="showMoreClick($event)">[Show More]</a><a href="javascript:void(0);" class="ux-show-more"
                    *ngIf="show_more_btn && rightAgentDetails && rightAgentDetails?.business_profile && rightAgentDetails?.business_profile?.about_business && rightAgentDetails?.business_profile?.about_business.length > 85"
                    (click)="showLessClick($event)">[Show Less]</a>
            </div>
            <!-- <ng-container *ngIf="(user && user.role_slug == 'buyer') || !user">
                <button type="submit" class="btn btn-success pull-right btn-fav w-100 mt-3"
                    (click)="openContactModal()">Contact
                    {{rightAgentDetails?.roles?.role?.title == 'Agent' ? 'Agent' : 'Builder'}}</button>
            </ng-container> -->
        </div>
    </ng-container>
    <div *ngIf="loadingCard" class="contact-card-loader">
        <img class="no-prop-loader" src="../../../assets/images/loader-home.gif">

    </div>

    <!-- {{user.id}} {{propertyDetails?.builder_id}} {{user.role_slug}} {{propertyDetails?.claim_status}} -->

</div>






<ng-template #contactUsTemplate>
    <div class="modal-dialog contant-bulder">
        <strong class="ml-3 contact-txt">Contact</strong>
        <button type="button" class="close assign-close" data-dismiss="modal" aria-label="Close"
            (click)="closeContactModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <hr>

        <div class="modal-content" style="border: 0; margin: 0;">
            <div class="modal-body">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm)">
                    <div class="row">
                        <div class="col-sm-6 ux-schedule ml-3 mb-2"><a (click)="toggleSchedule()">Schedule a
                                showing?</a></div>
                        <div class="col-sm-6"></div>

                    </div>
                    <div class="row" *ngIf="isShowSchedule">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Date & Time</label>
                                <input type="text" class="form-control" name="name" formControlName="scheduled_date"
                                    [ngClass]="{ 'is-invalid': submitted && contactForm.controls.scheduled_date.errors }"
                                    placeholder="Date (mm/dd/yyyy)" [min]="today" (change)="onChangeDate()" readonly
                                    [autocomplete]="false" placement="down" bsDatepicker [bsConfig]="dateConfig"
                                    datepicker-append-to-body="true">
                                <div *ngIf="(submitted && contactForm.controls.scheduled_date.invalid) || (!contactForm.get('scheduled_date').value && invalidForm) || (contactForm.get('scheduled_date').invalid && contactForm.get('scheduled_date').touched)"
                                    class="has-error">Date is required
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group ux-contact-time-picker">
                                <ngb-timepicker name="scheduled_time" formControlName="scheduled_time"></ngb-timepicker>
                                <div *ngIf="(submitted && contactForm.controls.scheduled_time.invalid) || (!contactForm.get('scheduled_time').value && invalidForm) || (contactForm.get('scheduled_time').invalid && contactForm.get('scheduled_time').touched)"
                                    class="has-error">Time is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Name</label>
                                <input type="text" class="form-control" name="name" formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && contactForm.controls.name.errors }"
                                    placeholder="Name" [readonly]="loggedIn">
                                <div *ngIf="(submitted && contactForm.controls.name.invalid) || (!contactForm.get('name').value && invalidForm) || (contactForm.get('name').invalid && contactForm.get('name').touched)"
                                    class="has-error">Name is required
                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input class="form-control" name="email" placeholder="Email"
                                    [ngClass]="{ 'is-invalid': submitted && contactForm.controls.email.errors }"
                                    autocomplete="off" formControlName="email" [readonly]="loggedIn">
                                <div *ngIf="(submitted && contactForm.controls.email.invalid) || (!contactForm.get('email').value && invalidForm) || (contactForm.get('email').invalid && contactForm.get('email').touched)"
                                    class="has-error">
                                    {{ (contactForm.get('email').value && contactForm.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Phone</label>
                                <input class="form-control" name="phone" (keypress)="keyPress($event)" minlength=10
                                    maxlength=10 formControlName="phone" placeholder="Phone">
                                <div *ngIf="(submitted && contactForm.controls.phone.invalid) || (!contactForm.get('phone').value && invalidForm) || (contactForm.get('phone').invalid && contactForm.get('phone').touched)"
                                    class="has-error">
                                    {{ (contactForm.get('phone').value && contactForm.get('phone').invalid) ? 'phone number must be 10 digit' : 'phone is required'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6"></div>

                    </div>
                    <div class="row text-div">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="exampleInputEmail1">About</label>
                                <textarea class="form-control ux-textarea" formControlName="message"
                                    placeholder="About"></textarea>
                            </div>
                        </div>
                        <div class="col-sm-12">

                        </div>


                    </div>
                    <!-- sfdf
                    {{leftAgentDetails?.roles?.role?.title }} -->
                    <div class="row text-div" *ngIf="leftAgentDetails?.roles?.role?.title != 'Agent' && leftCard">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <!-- <label for="exampleInputEmail1">About</label> -->
                                <input type="checkbox"
                                    (change)="checkBuilderContact(contactForm.get('isChecked').value)"
                                    formControlName="isChecked"> Already have an Agent?
                            </div>
                        </div>
                        <div class="col-sm-12" [hidden]="!agentOpen">
                            <div class="form-group">
                                <label for="agent_name">Agent Name</label>
                                <input class="form-control" name="agent_name" placeholder="Agent Name"
                                    [ngClass]="{ 'is-invalid': agentOpen && contactForm.controls.agent_name.errors && submitted}"
                                    autocomplete="off" formControlName="agent_name">
                                <div *ngIf="(agentOpen && submitted && contactForm.controls.agent_name.invalid) || (!contactForm.get('agent_name').value && invalidForm) || (contactForm.get('agent_name').invalid && contactForm.get('agent_name').touched)"
                                    class="has-error">
                                    Agent Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group float-right">
                                <button class="btn btn-success mb-2" [disabled]="loading"><span *ngIf="loading"
                                        class="spinner-border spinner-border-sm mr-1 "></span>Submit</button>
                            </div>

                        </div>


                    </div>

                </form>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #claimBuildingTemplate>
    <app-claim-building [propertyDetails]="propertyDetails" (closed)="closeClaimModal()"
        (submittedClaim)="claimSubmitCheck($event)"></app-claim-building>
</ng-template>

<ng-template #subscriptionTemplate>
    <div class="new-modal-sec">
        <div class="modal-header">
            <h2 class="payment-title payment-orange">Please Subscribe For Premium Membership To Claim Models</h2>
            <button type="button" class="close" (click)="subscriptionModalClose()">&times;</button>
        </div>
        <div class="modal-body">
            <app-builder-subscription-list (subsVal)="subscriptionValue($event)"></app-builder-subscription-list>
        </div>
    </div>
</ng-template>

<ng-template #paymentTemplate>
    <div class="new-modal-sec">
        <div class="modal-header">
            <div class="am-center">
                <h2 class="payment-title">Payment for {{tierName}} </h2>
                <h2 class="payment-title"><span class="amount-title">(Amount: {{tierPrice | currency}})</span></h2>
            </div>
            <button type="button" class="close" (click)="paymentModalClose()">&times;</button>
        </div>
        <div class="modal-body">
            <app-payment [pageType]="'edit-profile'" [tier]="tierDetails" (saved)="savePaymentModal($event)">
            </app-payment>
        </div>
    </div>
</ng-template>

<ng-template #savedCardTemplate>
    <div class="new-modal-sec">
        <div class="modal-header">
            <div class="am-center">
                <h2 class="payment-title">Payment for {{tierName}} </h2>
                <h2 class="payment-title"><span class="amount-title">(Amount: {{tierPrice | currency}})</span></h2>
            </div>
            <button type="button" class="close" (click)="savedCardModalClose()">&times;</button>
        </div>
        <div class="modal-body">
            <app-saved-card-list [tier]="tierDetails" (savedCard)="savedCardCheck($event)"
                (newCard)="newCardCheck($event)"></app-saved-card-list>
        </div>
    </div>
</ng-template>