import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import * as API from '../_constants/api.constants';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
	login(params: any) {
		return this.callLoginApi(API.User.LOGIN, 'POST', params);
	}

	me() {
		return this.callApi(API.User.ME, 'GET');
	}
	  
	registration(params: any) {
		return this.callApi(API.User.SIGNUP, 'POST', params, false);
	}

	registrationForBuilder(params: any) {
		return this.callApi(API.User.SIGNUP_BUILDER, 'POST', params, false);
	}

	registrationForAgent(params: any) {
		return this.callApi(API.User.SIGNUP_AGENT, 'POST', params, false);
	}

	socialSignupLogin(params: any) {
		return this.callLoginApi(API.User.SIGNUP_LOGIN_SIGNUP, 'POST', params);
	}

	forgotPassword(params:any)
	{
		return this.callLoginApi(API.User.FORGOT_PASSWORD, 'POST', params);
		
	}

	emailVerify(params:any)
	{
		return this.callLoginApi(API.User.EMAIL_VERIFY, 'POST', params);
		
	}

	profileImageChange(params:any)
	{
	  return this.callApiFileUpload(API.User.CHANGE_PROFILE_IMAGE, 'POST', params);
	}

	businessLogoChange(params:any)
	{
	  return this.callApiFileUpload(API.User.CHANGE_LOGO, 'POST', params);
	}

	changePasswordFromProfile(params:any)
	{
		return this.callApi(API.User.CHANGE_PASSWORD_PROFILE, 'POST', params);
		
	}
	resetPassword(params:any)
	{
		return this.callLoginApi(API.User.SET_PASSWORD, 'POST', params);
		
	}

	updateProfile(params: any, uid) {
		return this.callApi(API.User.SAVE + '/' + uid, 'PUT', params);
	}

	updateBusinessProfile(params: any, uid) {
		return this.callApi(API.User.SAVE_BUSINESS_PROFILE + '/' + uid, 'PUT', params);
	}

	  statusChange(params:any)
	{
		return this.callApi(API.User.CHANGE_STATUS, 'POST', params);
		
	}

	fetchDetailsWithoutLogin(params:any, uid){
		return this.callApi(API.User.USER_DETAILS_WITHOUT_LOGIN+'/'+uid, 'GET', {}, false, params);
	}

	fetchBanners(params:any, isLogin:boolean){
		if(isLogin) {
			return this.callApi(API.User.FETCH_BANNER, 'GET', {}, true, params);
		}
		else {
			return this.callApi(API.User.FETCH_BANNER, 'GET', {}, false, params);
		}
	}

	getPropertyNearby(params:any){
		return this.callApi(API.User.FETCH_PROPERTY_NEARBY, 'POST', params);
	}

	getInviteeDetails(params:any, uid){
		return this.callLoginApi(API.Agent.FETCH_DETAILS_INVITES+'/'+uid, 'GET', {});
	}

	updateStatusInvitee(params:any, id){
		return this.callLoginApi(API.User.UPDATE_STATUS_INVITEE+'/'+id, 'PUT', params);
	}

	validateEmail(params:any){
		return this.callApi(API.User.VALIDATE_EMAIL , 'POST', params);
	
	}

	getAgentSubs() {
		return this.callApi(API.Agent.AGENT_SUBSCRIPTION_LIST, 'GET', {}, false, {});
	}

	getBuilderSubs() {
		return this.callApi(API.Agent.BUILDER_SUBSCRIPTION_LIST, 'GET', {}, false, {});
	}

	getCardList() {
		return this.callApi(API.Agent.CARD_DETAILS, 'GET', {}, true, {});
	}

	makePayment(params: any) {
		return this.callApi(API.Agent.PAYMENT , 'POST', params);
	}

	makeSubscriptionPayment(params: any) {
		return this.callApi(API.Agent.SUBSCRIPTION_CARD_PAYMENT , 'POST', params);
	}

	saveExistingAgent(params: any) {
		return this.callApi(API.Agent.CARD_PAYMENT_EXISTING , 'POST', params);
	}
	
	changeAgentSubscription(params:any){
		return this.callApi(API.Agent.CHANGE_SUBSCRIPTION, 'POST', params);
	}

	submitBuilderSubscription(params:any){
		return this.callApi(API.Agent.BUILDER_SUBSCRIPTION, 'POST', params);
	}
	

	repeatPayment(params:any){
		return this.callApi(API.Agent.SUBSCRIPTION_REPAYMENT, 'POST', params);
	}

	repeatBuilderPayment(params:any){
		return this.callApi(API.Agent.BUILDER_SUBSCRIPTION_REPAYMENT, 'POST', params);
	}

	verifyQRRegister(params:any){
		return this.callApi(API.User.STORE_BUYER_INVITE_DATA, 'POST', params, false);
	}

	unSubscribeBuilder(){
		return this.callApi(API.Agent.BUILDER_SUBSCRIPTION_REMOVE, 'POST', {});
	}

	
}