import { Component, OnInit, ViewChild, ElementRef, Input,Output, EventEmitter } from '@angular/core';
import { StripeService } from 'src/app/services/stripe.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements OnInit {
 
  @Input() payment_methodAmt: any;
  @Input() payment_methodID: any;
  @Input() payment_api: any;
  @Input() payment_type: any;
  @ViewChild('cardElement') cardElement: ElementRef;
  @Output() callpaymentModalClose = new EventEmitter<void>();
  stripeLoaded = false;
  card: any;

  constructor(
    private stripeService: StripeService,
    private toaster: ToastrService,
    private loader: LoaderService) { }

  ngOnInit(): void {
    this.loader.show();
    this.stripeService.loadStripe().then(() => {
      this.stripeLoaded = true;
      this.initializeStripeElements();
      //
    });
  }

  initializeStripeElements() {
    const elements = this.stripeService.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);   
    this.loader.hide(); 
  }

  async submitPayment() {
    try {
      this.loader.show();
        const paymentamt = this.payment_methodAmt;
        const subscription_id = this.payment_methodID;
        const payment_api =this.payment_api;
        const payment_type = this.payment_type;
        
        // Create a new token for this payment attempt
        const token = await this.stripeService.createToken(this.card);
        
        // Create a payment intent using the newly generated token
        const clientSecret = await this.stripeService.createPaymentIntent(payment_api,subscription_id, token.id, payment_type);
        
        if(clientSecret){
          this.callpaymentModalClose.emit();
          this.loader.hide();
        }
        this.toaster.success("Payment Successfully Done.");
    } catch (error) {
        console.error('Error submitting payment:', error);
        // Handle error
    }
  }
}
