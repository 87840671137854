<div class="modal-dialog mt-2 mr-2 ml-2">
        <strong class="ml-3">Share</strong>
        <button type="button" class="close assign-close close-btn-m" data-dismiss="modal" aria-label="Close" (click)="closeAssignModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        <hr>
        
        <div class="modal-content" style="border: 0; margin: 0;">
            
            <div class="modal-body">
                <!-- <p>Check out the {{modelName}} by {{builderName}} on Newbuilt.com</p> -->
                <share-buttons theme="material-dark" show="11" image="https://loremflickr.com/320/240" url="{{qr}}"  (click)="closeModal()"></share-buttons>
                <!-- <div>
                    <div [socialShare]="'fb'">Facebook</div>
                    <div [socialShare]="'tw'">Twitter</div>
                    <div [socialShare]="'in'">Linkdedin</div>
                    <div [socialShare]="'tr'">Tumblr</div>
                    <div [socialShare]="'rd'">Reddit</div>
                    <div [socialShare]="'pn'">Pinterest</div>
                  </div>
                 -->
            </div>
        </div>
    </div>
