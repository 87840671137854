import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Regex, LocalVars, DefaultAddress, DefaultAddressArr, DefaultLatLng } from '../../_constants/others.constants';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TitleService } from 'src/app/services/title.service';
import $ from 'jquery'
import { NgForm } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';
import { CmsService } from 'src/app/services/cms.service';
import { CommonService } from 'src/app/services/common.service';
import { CommonStorageService } from 'src/app/services/common-storage.service';


@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.css']
})
export class WebFooterComponent implements OnInit {
  builderList: any;
  imageArr: any = [];
  bottomList: any = [];
  footerList: any = [];
  public contents: any;
  public fbLink: any;
  public instaLink: any;
  public pinterestLink: any;
  public youtubeLink: any;
  public currentYear: any;
  public conpanyName: any;
  public conpanyMail: any;
  public conpanyPhone: any;
  public isShowBrowseByBuilder: boolean = false;
  myObserver = null;
  public isAdminLogin : boolean = false;
  public sliderValue: any = 7;
  public updateCurrentAddressSub: any = null;
  public state: any;
  public city: any;
  public latitude: any;
  public longitude: any;
  public isLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private cmsService: CmsService,
    private modalService: BsModalService,
    private titleService: TitleService,
    public propertyService: PropertyService,
    public commonService : CommonService
  ) {

    this.myObserver = this.router.events.subscribe(re => {
      if (re instanceof NavigationEnd) {
        if(re.url == '/' || re.url == '/home' || re.url == '/property/search') {
          this.isShowBrowseByBuilder = true;
        } else {
          this.isShowBrowseByBuilder = false;
        }
      }
    })




    this.updateCurrentAddressSub = this.commonService.addressUpdate.subscribe(r => {
      let resp = r.res;
      if (resp != null) {
        this.handleAddressChange(resp)
      }
    })

    let user = this.commonStorage.getDetailsItem(LocalVars.USER);
    if(user) {
      this.isLogin = true;
    }

    if (localStorage.getItem('currentLocationObj') && localStorage.getItem('currentLocationObj') != '') {
      let getCurrentLocationStr = JSON.parse(localStorage.getItem('currentLocationObj'));
      if (getCurrentLocationStr) {
        for (const iterator of getCurrentLocationStr) {
          if (iterator['city']) {
           this.city = iterator['city']
          }
          if (iterator['state']) {
            this.state = iterator['state']
          }
          // this.getAll(this.city, this.state);
        }

      }
    }
    
    if (localStorage.getItem('currentLatLng') && localStorage.getItem('currentLatLng') != '') {
      let getLatLngStr = JSON.parse(localStorage.getItem('currentLatLng'));
      if (getLatLngStr) {
        for (const iterator of getLatLngStr) {
          if (iterator['lat']) {
           this.latitude = iterator['lat']
          }
          if (iterator['lng']) {
            this.longitude = iterator['lng']
          }
        }

      }
      setTimeout(() => {
        // console.log('lat-lng', this.latitude, this.longitude)
        this.getAll(this.city, this.state, this.latitude, this.longitude);
      }, 500);
    }
  }

  public handleAddressChange(address) {
    var addArr = [];
    let addressStr = '';
    let latlng = [];
    let isValidCountry = false;
    for (const iterator of address.address_components) {
      if (iterator.types.includes('country')) {
        if (iterator.short_name == 'US') {
          isValidCountry = true;
        }
      }
    }
    if (isValidCountry) {
      latlng = [{ lat: address.geometry.location.lat() }, { lng: address.geometry.location.lng() }]
      for (const iterator of address.address_components) {
        if (iterator.types.includes('locality')) {
          addArr.push({ 'city': iterator.short_name })
          addressStr += iterator.short_name + ', '
        }
        if (iterator.types.includes('administrative_area_level_1')) {
          addArr.push({ 'state': iterator.short_name })
          addressStr += iterator.short_name
        }
        // if (iterator.types.includes('postal_code')) {
        //   addArr.push({ 'zipcode': iterator.long_name })
        //   addressStr += ', ' + iterator.long_name
        // }
      }

    } else {
      addressStr = DefaultAddress;
      addArr = DefaultAddressArr;
      latlng = DefaultLatLng;
    }

    let val =  addressStr.split(", ")
    this.state = val[1];
    this.city = val[0];
    for (const iterator of latlng) {
      if (iterator['lat']) {
       this.latitude = iterator['lat']
      }
      if (iterator['lng']) {
        this.longitude = iterator['lng']
      };
    }
    setTimeout(() => {
      // console.log('lat-lng', this.latitude, this.longitude)
      this.getAll(this.city, this.state, this.latitude, this.longitude)
    }, 500);
  }

  getAll(city, state, lat, lng) {
    // console.log('lat-lng2', lat, lng)
    // this.state = val[1];
    // this.city = val[0];
    let promises = [];
    let param1 = {
      offset_val : 2,
      type : 1,
      latitude : lat, 
      longitude : lng,
      state: this.state,
      city: this.city
    }
    let param2 = {
      offset_val : 4,
      type : 2,
      latitude : lat, 
      longitude : lng,
      state: this.state,
      city: this.city
    }
    this.loader.show()
		promises.push(this.userService.fetchBanners(param1, this.isLogin));
    promises.push(this.userService.fetchBanners(param2, this.isLogin));
    promises.push(this.propertyService.fetchBuilders({}));
		Promise.all(promises).then(allData => {
      this.loader.hide();
			if (allData && allData.length == 3) {
        if (allData[0] && allData[0].data && allData[0].data.list) {
          this.bottomList = allData[0].data.list;
        }
        if (allData[1] && allData[1].data && allData[1].data.list) {
          this.footerList = allData[1].data.list;
        }
        if (allData[2] && allData[2].data && allData[2].data.list) {
          this.builderList = allData[2].data.list;
          let groupImage = [];
          for (const iterator of this.builderList) {
            if (iterator && iterator.business_profile && iterator.business_profile.logo) {
              let data = {
                id: iterator.id,
                uid: iterator.uid,
                file: iterator.business_profile.logo.thumb,
                name: iterator.first_name + ' ' + iterator.last_name
              }
              this.imageArr.push(data);
              //this.gr
            }
          }
          setTimeout(() => {
            var ele = document.getElementById('footer-carousel');
            if(ele){
              ele.style.display = 'block'
            }
            
          }, 1500);
        }
      }
    }).finally(() => {
    })

  }

  ngOnInit(): void {
    this.detectMob();
    //this.getAllBuilder()
    // this.getBottomBanner()
    // this.getFooterBanner()
    // let currentUrl = window.location.href;
    // var pathname = new URL(currentUrl).pathname;
    // if (currentUrl.includes("search") || currentUrl.includes("home") ) {
    //   this.isShowBrowseByBuilder = true;
    // } else if (pathname == '/') {
    //   this.isShowBrowseByBuilder = true;
    // } else {
    //   this.isShowBrowseByBuilder = false;
    // }
    let currentUrl = window.location.href;
    if (currentUrl.includes("admin")) {    
      this.isAdminLogin = true;
    }
    this.getFooterContent();
   
  }

  getFooterContent() {
    let promises = [];
    this.loader.show()
    promises.push(this.cmsService.getHeaderFooterContents({}));
    Promise.all(promises).then(allData => {
      if (allData) { 
        this.loader.hide();
        if (allData[0] && allData[0].data && allData[0].data.list) {
          this.contents = allData[0].data.list;
          this.currentYear = new Date().getFullYear();
          this.contents.forEach(x => {
            if(x.key == "company_name") {
              this.conpanyName = x.val ? x.val : '';
            }
            if(x.key == "contact_mail") {
              this.conpanyMail = x.val ? x.val : '';
            }
            if(x.key == "contact_phone") {
              this.conpanyPhone = x.val ? x.val : '';
            }
            if(x.key == "facebook_link") {
              this.fbLink = x.val ? x.val : '';
            }
            if(x.key == "instagram_link") {
              this.instaLink = x.val ? x.val : '';
            }
            if(x.key == "pinterest_link") {
              this.pinterestLink = x.val ? x.val : '';
            }
            if(x.key == "youtube_link") {
              this.youtubeLink = x.val ? x.val : '';
            }
          });
          
         // console.log('contacts-header', this.contents)
        }
      }
    }).finally(() => {
    })
  }



  getAllBuilder() {

    this.loader.show()
    this.propertyService.fetchBuilders({})
      .then(r => {
        this.loader.hide();
        this.builderList = r.data.list;
        let c = 1;
        let groupImage = [];
        for (const iterator of this.builderList) {
          if (iterator && iterator.business_profile && iterator.business_profile.logo) {
            let data = {
              id: iterator.id,
              uid: iterator.uid,
              file: iterator.business_profile.logo.thumb,
              name: iterator.first_name + ' ' + iterator.last_name
            }
            this.imageArr.push(data)
            //this.gr
          }
        }
        setTimeout(() => {
          var ele = document.getElementById('footer-carousel');
          ele.style.display = 'block'
        }, 1500);
        // var i,j,temparray,chunk = 4;
        // for (i=0,j=groupImage.length; i<j; i+=chunk) {
        //     temparray = groupImage.slice(i,i+chunk);
        //     this.imageArr.push(temparray);
        // }
        //console.log(this.imageArr, 'builderList')
      }).catch(err => {
        this.loader.hide();
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })

  }

  gotoBuilderPropertyList(uid, id) {
    this.commonStorage.setItem(LocalVars.BUILDER_ID, btoa(JSON.stringify(id)));
    this.router.navigate(['/property/builder/' + btoa(uid)]);
  }

  getBottomBanner(){
    this.loader.show()
    let param = {
      offset_val : 2,
      type : 1,
      city: this.city,
      state: this.state
    }
    this.userService.fetchBanners(param, this.isLogin)
      .then(r => {
        this.loader.hide();
        this.bottomList = r.data.list;
       // console.log('bottomList', this.bottomList)
      }).catch(err => {
        this.loader.hide();
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
    
  }

  getFooterBanner(){

    this.loader.show()
    let param = {
      offset_val : 6,
      type : 2,
      city: this.city,
      state: this.state
    }
    this.userService.fetchBanners(param, this.isLogin)
      .then(r => {
        this.loader.hide();
        this.footerList = r.data.list;
        //console.log('ppjhj', this.footerList)
      }).catch(err => {
        this.loader.hide();
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })


  }

  // goToPrivacy(){
  //   this.router.navigate(['/terms-of-use'])
  // }

  // goToTerms(){
  //   this.router.navigate(['/privacy-policy'])
  // }

  ngOnDestroy() {
    if(this.myObserver){
      this.myObserver.unsubscribe();
    }


    if (this.updateCurrentAddressSub) {
      this.updateCurrentAddressSub.unsubscribe();
    }

    
  }

  bannerViewCount(row){
    if (row.id) {
      let params = {
        banner_id: row.id,
      }
      this.commonService.viewCountBanner(params)
        .then(r => {
          if (r['data']) {

          } else {
            this.toaster.error(r.message, 'Oops')
          }

        }).catch(err => {
          if (err.status == 400) {
            this.toaster.error(err.error.message, 'Oops')
          } else if (err.message) {
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }

        })

    } else {
      console.log('Banner id not exist')
    }
  }


  detectMob() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    var element = document.getElementById('container_details');
    if (isMobile) {
      this.sliderValue = 3
    } else {
      this.sliderValue = 7
    }
  }
  

  

}
