import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-web-social-share',
  templateUrl: './web-social-share.component.html',
  styleUrls: ['./web-social-share.component.css']
})
export class WebSocialShareComponent implements OnInit {
  @Input('propertyDetails') propertyDetails : any = null;
  // @Input('show') show : any = null;
  @Output('closed') closed: EventEmitter<any> = new EventEmitter;
  public detailsLink : any = ''
  public builderName : any = ''
  public modelName : any = ''
  constructor() { }

  ngOnInit(): void {
     console.log(this.propertyDetails, 'propertyDetails')
     this.modelName = this.propertyDetails.model_name;
     this.builderName = this.propertyDetails.builder.business_profile.business_name;
     this.detailsLink = environment.SITE_URL+'/property/details/'+btoa(this.propertyDetails.uid)
  }

  closeModal(){
    this.closed.emit(null)
  }

}
