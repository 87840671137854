import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'customDatetime'
})
export class CustomDatetimePipe implements PipeTransform {

  transform(date: Date): string {
    //date = new Date(date);  // if orginal type was a string
    let dt = moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm a');
    return dt;
  }

}
