<div>
    <!-- Navbar -->
    <!-- <app-navbar></app-navbar> -->
    <!-- /.navbar -->
    <div class="menu-fixed">
      <app-web-header></app-web-header>
    </div>
    
    <!-- Main Sidebar Container -->
    <!-- <div id="page-wrapper" class="gray-bg dashbard-1"> -->
      <!-- <div class="row border-bottom">
        <app-sidebar></app-sidebar>
      </div> -->
      <!-- Content Wrapper. Contains page content -->
      <router-outlet></router-outlet>
      <!-- /.content-wrapper -->
      <app-web-footer></app-web-footer>
  
    <!-- </div> -->
  </div>
