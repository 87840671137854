<div class="modal-dialog property-list-modal claim-building-modal">
    <button type="button" class="close assign-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-content">
        <div class="modal-header text-center">
            <strong>Please Enter Contact Details Who Will Receive Leads On This Model</strong>

        </div>
        <div class="modal-body">
            <form [formGroup]="addForm">
                <div class="row">
                    <!-- <div class="col-sm-12"> -->
                    <div class="col-sm-6">
                        <div class="form-group">

                            <input type="text" placeholder="First Name" name="first_name" class="form-control" formControlName="first_name"
                                [ngClass]="{ 'is-invalid': submitted && addForm.controls.first_name.errors }">
                            <div *ngIf="(submitted && addForm.controls.first_name.invalid) || (!addForm.get('first_name').value && invalidForm) || (addForm.get('first_name').invalid && addForm.get('first_name').touched)"
                                class="has-error">First name is required
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">

                            <input type="text" placeholder="Last Name" name="last_name" class="form-control" formControlName="last_name"
                                [ngClass]="{ 'is-invalid': submitted && addForm.controls.last_name.errors }">
                            <!-- <div *ngIf="(submitted && addForm.controls.last_name.invalid && addForm.invalid && invalidForm) || (!addForm.get('last_name').value ) || (addForm.get('last_name').invalid && addForm.get('last_name').touched)"
                                class="has-error">Last name is required</div> -->

                                <div *ngIf="(submitted && addForm.controls.last_name.invalid) || (!addForm.get('last_name').value && invalidForm) || (addForm.get('last_name').invalid && addForm.get('last_name').touched)"
                                class="has-error">Last name is required
                            </div>

                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Email" name="email" class="form-control" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && addForm.controls.email.errors }">
                            <div *ngIf="(submitted && addForm.controls.email.invalid) || (!addForm.get('email').value && invalidForm) || (addForm.get('email').invalid && addForm.get('email').touched)"
                                class="has-error">
                                {{ (addForm.get('email').value && addForm.get('email').invalid) ? 'Email is invalid' : 'Email is required'}}
                            </div>

                        </div>

                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Phone" name="phone" (keypress)="keyPress($event)" minlength=10 maxlength=10 class="form-control" formControlName="phone"
                                [ngClass]="{ 'is-invalid': submitted && addForm.controls.phone.errors }">
                            <div *ngIf="(submitted && addForm.controls.phone.invalid) || (!addForm.get('phone').value && invalidForm) || (addForm.get('phone').invalid && addForm.get('phone').touched)"
                                class="has-error">
                                {{ (addForm.get('phone').value && addForm.get('phone').invalid) ? 'Phone is invalid' : 'Phone is required'}}
                            </div>

                        </div>

                    </div>
                   
                </div>
        <!-- </div> -->
                <div class="claim-form-div">
                    <button class="btn reg_loginbtn w-40 ux-submit" [disabled]="loading" (click)="claimBuilding(addForm)">Submit</button>
                    <!-- <button class="btn reg_loginbtn w-40 ml-2 ux-submit skip-claim-btn" [disabled]="loading" (click)="claimBuildingToBuilder()">Skip and Use Default</button> -->

                </div>
                
                <!-- <button class="btn btn-secondary ml-2">Cancel</button>    -->

            </form>
        </div>
    </div>
</div>