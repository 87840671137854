import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

// Ideally this component should be included in app.html

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
	private loaderSubscription: Subscription;
	public showLoader: boolean = false;

	constructor(
		private loader: LoaderService, 
		private cdr: ChangeDetectorRef
	) {
		
	}

	ngOnInit() {
		this.loaderSubscription = this.loader.loader.subscribe(val => {
			this.showLoader = val
			this.cdr.detectChanges();
		});
	}

	ngOnDestroy() {
		this.loaderSubscription.unsubscribe();
	}

}
