import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Regex, LocalVars } from 'src/app/_constants/others.constants';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { CommonStorageService } from 'src/app/services/common-storage.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @Input('emailAgent') emailAgent : string = '';
  @Input('pageType') pageType : string = '';
  @Input('tier') set setValues(val) {
    if(val) {
      console.log('tier', val)
      if( val[0] ) {
        this.tierDetails = val[0];
      }
      else {
        this.tierDetails = val;
      }
      if(this.paymentForm) {
        this.paymentForm.get('amount').setValue(this.tierDetails.price)
      }
    }
  } 

  @Output('saved') private saveEvent: EventEmitter<any> = new EventEmitter();

  public paymentForm : FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  public invalidForm: boolean = false;
  public tierDetails : any;
  public existingAgentEmail : any;

  constructor(
    private _fb: FormBuilder,
    private userService: UserService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
  ) { 
    this.setUpForm();
    let user = this.commonStorage.getDetailsItem(LocalVars.USER);
    console.log('user-payment', user)
    if(user) {
       this.existingAgentEmail = user.email
    }
  }

  ngOnInit(): void {
  }

  private setUpForm() {
      this.paymentForm = this._fb.group({
        card_number: ['', [Validators.required, this.validateBlankValue.bind(this), Validators.pattern(Regex.number), Validators.minLength(15), Validators.maxLength(16)]],
        card_holder_name: ['', [Validators.required, this.validateBlankValue.bind(this)]],
        amount: ['', [Validators.required, Validators.pattern(Regex.currency), this.validateBlankValue.bind(this)]],
        cvc: ['', [Validators.required, this.validateBlankValue.bind(this), Validators.maxLength(3), Validators.pattern(Regex.number)]],
        date: ['', [Validators.required, Validators.maxLength(6), this.validateBlankValue.bind(this), Validators.pattern(Regex.number)]]
      });
  } 

  validateBlankValue(control: FormControl): any {
		return control && control.value && control.value.toString().trim() ? null : { emptyValue: true };
	}

  closePayment(value) {
    console.log('value-payment', value)
    this.saveEvent.emit(value)
  }

  onSubmit(form: FormGroup) {
    // console.log('form', form)
		this.invalidForm = !form.valid;
    if (this.paymentForm.invalid) {
        return;
    }
    else {
      const params = {
				...form.getRawValue(),
				email: this.emailAgent
      }
      params['exp_month'] = params['date'][0] + params['date'][1]
      params['exp_year'] = params['date'][2] + params['date'][3] + params['date'][4] + params['date'][5];
      delete params['date'];
      if(this.pageType == 'web-header') {
        this.savePayment(params);
      }
      if(this.pageType == 'edit-profile') {
        delete params['email'];
        this.saveProfileUpdateSubmit(params);
			}
      if(this.pageType == 'existing-agent') {
        params['email'] = this.existingAgentEmail;
        params['subscription_id'] = this.tierDetails.id;
        console.log('ppp', params)
        this.saveExistingAgentSubmit(params);
			}
    }
  }


    savePayment(params) {
      this.loader.show();
      this.loading = true;
      this.userService.makePayment(params)
        .then(response => {
          this.loader.hide();
          if (response['data']) {
            this.closePayment(response['data'])
            // this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
          } else {
            this.toaster.error(response.message, 'Oops')
          }
          this.loading = false;
        }).catch(err => {
          // this.loader.hide();
          this.loading = false;
          if (err.status == 400) {
            if (err.error.data.errors.email[0]) {
              this.toaster.error(err.error.data.errors.email[0], 'Oops')
            } else {
              this.toaster.error(err.error.message, 'Oops')
            }

          } else if (err.message) {
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }

        })

    }

    saveProfileUpdateSubmit(params) {
      this.loader.show();
      this.loading = true
      this.userService.makeSubscriptionPayment(params)
        .then(response => {
          this.loader.hide();
          if (response['data']) {
            this.closePayment(response['data'])
            // this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
          } else {
            this.toaster.error(response.message, 'Oops')
          }
          this.loading = false;
        }).catch(err => {
          // this.loader.hide();
          this.loading = false;
          if (err.status == 400) {
            if (err.error.data.errors.email[0]) {
              this.toaster.error(err.error.data.errors.email[0], 'Oops')
            } else {
              this.toaster.error(err.error.message, 'Oops')
            }

          } else if (err.message) {
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }
        })
    }

    saveExistingAgentSubmit(params) {
      this.loader.show();
      this.loading = true
      this.userService.saveExistingAgent(params)
        .then(response => {
          this.loader.hide();
          if (response['data']) {
            this.closePayment(response['data'])
            // this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
          } else {
            this.toaster.error(response.message, 'Oops')
          }
          this.loading = false;
        }).catch(err => {
          // this.loader.hide();
          this.loading = false;
          if (err.status == 400) {
            if (err.error.data.errors.email[0]) {
              this.toaster.error(err.error.data.errors.email[0], 'Oops')
            } else {
              this.toaster.error(err.error.message, 'Oops')
            }

          } else if (err.message) {
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }
        })
    }


    

}


