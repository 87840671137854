import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.css']
})
export class SubscriptionListComponent implements OnInit {

  @Output('subsVal') subscriptionValue: EventEmitter<any> = new EventEmitter;


  public subscriptionAgentList : Array<any> = [];
  public agent_subscription_id: string;
  public subscriptionForm: FormGroup;
  public tierDetails: any = {};

  constructor(
    private userService: UserService,
    private toaster: ToastrService,
  ) {
    this.getSubsAgentList();

    this.subscriptionForm = new FormGroup({
      subscription_id: new FormControl('', [Validators.required])
    })
  }

  ngOnInit(): void {
  }

  getSubsAgentList() {
    // this.loader.show();
    this.userService.getAgentSubs()
    .then(res => {
      // this.loader.hide();
      this.subscriptionAgentList = res.data.list.data;

    }).catch(err => {
      // this.loader.hide();
      // this.loading = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
  }

  changeSubscriptionPlan() {
    if(this.subscriptionForm.get('subscription_id').value) {
      this.tierDetails = this.subscriptionAgentList.filter(val => {
        return val.id == this.subscriptionForm.get('subscription_id').value;
      })
      console.log('subs', this.tierDetails)
      this.subscriptionValue.emit(this.tierDetails)
    }
    else {
      this.toaster.error('Please select one subscription to continue')
    }
  }

}
