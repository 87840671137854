import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(date: Date): string {
    // date = new Date(date);  // if orginal type was a string
    let dt = moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY');
    return dt;
  }

}
