<button type="button" class="close assign-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div id="ux-modal-body">


    <ng-container *ngIf="isShowMap">
      <ng-container *ngIf="currentMapCoords.length == 0">
        <img id="zoom-image" [src]="currentImageMap">
      </ng-container>
      <div style="position: relative; overflow: hidden; display: block;" *ngIf="currentMapCoords.length">

        <maphilight id="statesMapInterior" [config]="mapConfig">
          <img id="zoom-image" [src]="currentImageMap">
          <map>
            <ng-container *ngIf="user && user.role_slug == 'buyer'">
              <ng-container *ngFor="let item of currentMapCoords; let i=index;">
                <ng-container *ngIf="item.is_saved == 0">
                  <area class="map-area" shape="circle" coords="{{item.coord}}" placement="top"
                    tooltip="<p class='ux_stl_tooltip_info'><span class='ux_close_pop ux_tooltip_close_{{i}}'><i class='fa fa-times-circle' aria-hidden='true'></i></span><p class='ux-link-save save_link_{{i}}'><i class='fa fa-plus-circle' aria-hidden='true'></i> Click here to save the look</p><strong>{{item.title}}</strong><p>{{item.description}}</p><a target='_blank' class='ux-link view_link_{{i}}' href='{{item.url}}'><i class='fa fa-cart-plus' aria-hidden='true'></i> Click here to buy</a></p>"
                    [options]="item.myopt" tooltip-class="ux-tooltip ux_tooltip_link_{{i}}" (click)="setClick(i, item)">

                </ng-container>
                <ng-container *ngIf="item.is_saved == 1">
                  <area class="map-area" shape="circle" coords="{{item.coord}}" placement="top"
                    tooltip="<p class='ux_stl_tooltip_info'><span class='ux_close_pop ux_tooltip_close_{{i}}'><i class='fa fa-times-circle' aria-hidden='true'></i></span><p class='ux-link-save'>Saved Look</p><strong>{{item.title}}</strong> <p>{{item.description}}</p><a target='_blank' class='ux-link view_link_{{i}}' href='{{item.url}}'><i class='fa fa-cart-plus' aria-hidden='true'></i> Click here to buy</a></p>"
                    [options]="item.myopt" tooltip-class="ux-tooltip ux_tooltip_link_{{i}}" (click)="setClick(i, item)">
                </ng-container>
              </ng-container>

            </ng-container>
            <ng-container *ngIf="!user || (user && user.role_slug != 'buyer') ">
              <ng-container *ngFor="let item of currentMapCoords; let i=index;">
                <area class="map-area" shape="circle" coords="{{item.coord}}" placement="top"
                  tooltip="<p class='ux_stl_tooltip_info'><span class='ux_close_pop ux_tooltip_close_{{i}}'><i class='fa fa-times-circle' aria-hidden='true'></i></span><strong>{{item.title}}</strong><p>{{item.description}}</p><a target='_blank' class='ux-link view_link_{{i}}' href='{{item.url}}'><i class='fa fa-cart-plus' aria-hidden='true'></i> Click here to buy</a></p>"
                  [options]="item.myopt" tooltip-class="ux-tooltip ux_tooltip_link_{{i}}" (click)="setViewClick(i, item)">
              </ng-container>

            </ng-container>


          </map>
        </maphilight>

      </div>
    </ng-container>
  </div>
