import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import * as API from '../_constants/api.constants';

@Injectable({
  providedIn: 'root'
})
export class CmsService extends BaseService {

  getCMSContents(params:  any) {
    return this.callApi(API.Property.CMS, 'GET', {}, true, params);
  }

  getHeaderFooterContents(params:  any) {
    return this.callApi(API.Property.SITE_SETTINGS, 'GET', {}, true, params);
  }


}
