export const environment = {
  
    production: false,
    //API: 'https://newbuild-dev.dreamztesting.com/api/v1/',
    API: 'https://backend.newbuilt.com/api/v1/',
    BASE_URL: 'https://www.newbuilt.com/',
    API_BASE_URL: 'https://backend.newbuilt.com',
    ADMIN_URL: 'https://backend.newbuilt.com/admin',
    ASSET_URL: 'https://newbuilt.glohtesting.com/',
    SOCKET_URL: 'https://newbuilt-backend.glohtesting.com/',
    SITE_URL: 'https://www.newbuilt.com/',
    PUSHER_KEY: '934096a51d5f68961e41',
    PUSHER_CLUSTER: 'us2',
    ASSET_360_URL: 'https://newbuilt.glohtesting.com/property360files/',


    firebaseConfig : {
        apiKey: "AIzaSyBsMR_Yr5ylL6KJQmqnPArBgwu9Fheh4Tg",
        authDomain: "new-built-9846a.firebaseapp.com",
        databaseURL: "https://new-built-9846a.firebaseio.com",
        projectId: "new-built-9846a",
        storageBucket: "new-built-9846a.appspot.com",
        messagingSenderId: "408907478974",
        appId: "1:408907478974:web:1e82699d19a0fa593a6bef",
        measurementId: "G-9037FMMWRQ"
    },
        
    stripeConfig : {
        production: false,
        stripe: {
          publicKey: 'pk_test_51It9ILAGj9CW0UnGsWxYRPGqn9F6B5Yh9142YEAtM2gS1lVL5jbAnTjUgKStvGNdaPWNkwUY0bjWpm67zS6AcbyG006pU4orl8',
        }
      }

    
};
