import { Directive } from '@angular/core';

@Directive({
  selector: '[appEasypin]'
})
export class EasypinDirective {

  constructor() { }

}
