<form [formGroup]="subscriptionForm">
        <div class="row">
            <div class="col-lg-12 col-md-12" *ngFor="let subList of subscriptionAgentList; index as i">
                 <div class="input-sec" > 
                <div class="input-inner subs-input"><input formControlName="subscription_id" type="radio"
                        id="{{'tier-'+i}}" [value]="subList.id">
                    <label for="{{'tier-'+i}}">{{subList.name}}</label></div>
                <div class="pay-list-sub">
                    <p class="subsceri">{{subList.description}}
                    </p>
                    <p class="edit-profile-price"><strong>{{subList.price | currency}}</strong></p>
                </div>
                 </div> 
            </div>
            <div class="col-lg-4 col-md-4">
                <button class="btnbanner_search btn mt-2 pl-4 pr-4 profileBtn"
                    (click)="changeSubscriptionPlan()">Proceed</button>
            </div>
        </div>
</form>