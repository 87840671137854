import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalVars } from '../_constants/others.constants';

@Injectable({
  providedIn: 'root'
})
export class CommonStorageService {

  public localStorage : any;

  constructor() {
    this.localStorage = localStorage;
   }

  getItem(index: string): any {
		return this.localStorage.getItem(index);
	}

	// getAll(): any {
	// 	return this.localStorage.getAll();
	// }

	setItem(index: string, data: any, expires?: number | Date): void {
		if (!expires) {
			expires = 365;
		}

		let path = '/';
		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";
		console.log('common-storage', index, data)
		var r = this.localStorage.setItem(index, data);
	}

	getDetailsItem(index: string): any {
		if(this.localStorage.getItem(index)){
			let data = JSON.parse(decodeURIComponent(escape(window.atob(this.localStorage.getItem(index)))))
			return data;
		}
		
	}

	setDetailsItem(index: string, data: any, expires?: number | Date): void {
		if (!expires) {
			expires = 365;
		}
		let path = '/';
		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";
		let encodedData = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))))
		var r = this.localStorage.setItem(index, encodedData);
	}

	removeItem(index: string, path?: string): void {
		if (!path) {
			path = '/';
		}

		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";

		this.localStorage.removeItem(index);
	}

	clear(path?: string): void {
		if (!path) {
			path = '/';
		}
		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";

		this.localStorage.removeItem(LocalVars.DOMAIN);
		this.localStorage.removeItem(LocalVars.TOKEN_VAR);
		this.localStorage.removeItem(LocalVars.USER);
		this.localStorage.removeItem(LocalVars.USER_VAR);
		this.localStorage.removeItem(LocalVars.FILTER_PARMS);
		
	}

	checkItem(index: string) {
		if (this.localStorage.getItem(index)) {
			return true;
		} else {
			return false;
		}
	}

}
