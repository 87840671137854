import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-report-property',
  templateUrl: './report-property.component.html',
  styleUrls: ['./report-property.component.css']
})
export class ReportPropertyComponent implements OnInit {
  reportForm: FormGroup;
  @Input('propertyDetails') propertyDetails : any = null;
  @Input('user') user : any = null;
  reportPropertyComments :any;

  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private toaster: ToastrService,
    ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.reportForm = this.fb.group({
      reportPropertyComments: ['', Validators.required]
    });

    this.reportPropertyComments = this.reportForm.get('reportPropertyComments');
  }
  

  get reportFormControl() {
    return this.reportForm.controls;
  }

  
  reportOfProperty(){

    if (this.reportForm.valid && this.propertyDetails.id >0){

      let isLogin = this.user ? true : false;
      let params = {
        comments : this.reportPropertyComments.value,
        property_id : this.propertyDetails ? this.propertyDetails.id : 0
      }
    
      this.propertyService.addReportOfProperty(params, isLogin)
      .then(r => {
        if (r['data']) {
          this.toaster.success('Thank you for your message. Our site admin will review and get back to you soon', 'Success');
          this.reportForm.reset();
        } else {
          this.toaster.error(r.message, 'Oops');
        }
      }).catch(err => {
        this.toaster.error('Something went wrong!', 'Oops');
      })
    }else{
      this.toaster.error('Data Not valid');
    }
    
  }
}

