import { environment } from '../../environments/environment';

const prefixes = {
	user: 'buyer/',
	auth: 'auth/',
	user_builder:'builder/',
	user_agent:'agent/',
	upload: 'upload/',
	property: 'property/',
	user_profile:'user/',
	change_password:'user/password/',
	status_change:'user/status/',
	business_logo:'user/business-profile/',
	update_business_profile:'user/update/',
};

export const User = {
	LOGIN: environment.API + 'login',
	LOGOUT: environment.API + prefixes.auth + 'logout',
	SIGNUP: environment.API + prefixes.user + 'registration',
	ME: environment.API + 'init',
	SIGNUP_BUILDER: environment.API + prefixes.user_builder + 'registration',
	SIGNUP_AGENT: environment.API + prefixes.user_agent + 'registration',
	SIGNUP_LOGIN_SIGNUP : environment.API + 'social-login',
	FORGOT_PASSWORD:environment.API + 'forgot-password',
	SET_PASSWORD:environment.API + 'set-password',
	SAVE : environment.API + prefixes.user_profile + 'update', 
	CHANGE_PASSWORD_PROFILE:environment.API + prefixes.change_password + 'update',
	CHANGE_PROFILE_IMAGE:environment.API + prefixes.user_profile + 'avatar',
	CHANGE_STATUS:environment.API + prefixes.status_change + 'update',
	EMAIL_VERIFY:environment.API + 'verify-email',
	USER_DETAILS_WITHOUT_LOGIN: environment.API + 'builders',
	CHANGE_LOGO:environment.API + prefixes.business_logo + 'logo',
	SAVE_BUSINESS_PROFILE : environment.API + prefixes.update_business_profile + 'business-profile',
	FETCH_BANNER : environment.API + 'banners',
	FETCH_PROPERTY_NEARBY : environment.API + 'geofence-notification',
	UPDATE_STATUS_INVITEE : environment.API + 'invitees',
	VALIDATE_EMAIL : environment.API + 'email/unique',
	STORE_BUYER_INVITE_DATA : environment.API + 'storeBuyerAndInviteeData',
};

export const Property = {
	SAVE : environment.API + 'property', 
	FETCH : environment.API + 'property',
	FETCH_CLUSTER : environment.API + 'property-all-list',
	FETCH_DETAILS_WITHOUT_LOGIN: environment.API + 'property-list',
	FETCH_DETAILS:	environment.API + 'property',
	FETCH_WITHOUT_LOGIN : environment.API + 'property-list',
	FETCH_PROPERTY_NEIGHBOUR : environment.API + 'propertybyneighbourhoodname',
	FETCH_BUILDERS : environment.API + 'builders',
	UPLOAD_INTERIOR_IMAGE : environment.API + prefixes.upload + 'property-interior-image',
	UPLOAD_INTERIOR_VIDEO : environment.API + prefixes.upload + 'property-interior-video',
	UPLOAD_EXTERIOR_IMAGE : environment.API + prefixes.upload + 'property-exterior-image',
	UPLOAD_EXTERIOR_VIDEO : environment.API + prefixes.upload + 'property-exterior-video',
	UPLOAD_FLOOR_PLAN_IMAGE : environment.API + prefixes.upload + 'property-floor-plan-image',
	SET_PROPERTY_DEFAULT_IMAGE : environment.API + 'property',
	MEDIA_DESCRIPTION : environment.API + 'media-file',
	DELETE_FILE : environment.API + 'delete/property-media-file',
	FETCH_AGENTS : environment.API + 'agents',
	FAVOURITE : environment.API + 'favourite/add-or-remove',
	FETCH_FAVOURITE_PROPERTY : environment.API + 'my-favourite-properties',
	SAVED_SEARCH : environment.API + 'saved-search',
	SAVE_RATING : environment.API + 'property-rating',
	FETCH_RECENT_SEEN : environment.API + 'recent-properties',
	SET_RECENT_SEEN : environment.API + 'add-recent-property',
	UPLOAD_360_IMAGE_VIDEO : environment.API + 'upload/property-360-image-video',
	AMENITY_LIST: environment.API + 'amenities-all',
	SET_AMENITIES: environment.API + 'set-amenities',
	DETAILS_FILE_SUBMIT: environment.API + 'upload/property-details-pdf',
	NEIGHBORHOOD_FILE_SUBMIT: environment.API + 'upload/property-neighbourhood-attachment',
	PROPERTY_VIEW: environment.API + 'increase-view',
	GET_LOCAL_AGENT: environment.API + 'property-local-agent',
	GET_LOCAL_AGENT_AUTH_WITHOUT_LEFT: environment.API + 'property-local-agent',
	GET_LOCAL_AGENT_AUTH_LEFT: environment.API + 'property-local-agent-auth',
	GET_LOCAL_AGENT_AUTH_WITHOUT_RIGHT: environment.API + 'property-local-agent-right',
	GET_LOCAL_AGENT_AUTH_RIGHT: environment.API + 'property-local-agent-right-auth',
	ADD_CONTACT: environment.API + 'lead', 
	ADD_CONTACT_WITHOUT_LOGIN : environment.API + 'leads',
	FETCH_CONTACT: environment.API + 'lead',
	FETCH_AGENT_FOR_CONTACT: environment.API + 'associated-agents',
	FETCH_CONTACT_FOR_BUYER: environment.API + 'contacted-builders',
	FETCH_ALL_REVIEW: environment.API + 'buyer-ratings',
	FETCH_MY_REVIEWS: environment.API + 'my-reviews',
	FETCH_PROPERTY_FOR_DROP_DOWN: environment.API + 'my-properties',
	FETCH_ADVANCE_SEARCH_FILTER : environment.API + 'advance-search-filters',
	GET_CONTACT: environment.API + 'contact',
	GET_MESSAGE: environment.API + 'message',
	CONATCT_AGENT: environment.API + 'contact-agent',
	MAKE_PREFFER_AGENT: environment.API + 'set-preferred-agent',
	REMOVE_PREFER_AGENT: environment.API + 'removepreferredagent',
	FETCH_SHOP_THE_LOOK : environment.API + 'shop-the-look',
	SAVE_SHOP_THE_LOOK : environment.API + 'shop-the-look',
	REMOVE_SHOP_THE_LOOK : environment.API + 'shop-the-look',
	SAVE_VIEW_SHOP_THE_LOOK : environment.API + 'buyer/shop-the-look',
	LIST_SAVED_SHOP_THE_LOOK : environment.API + 'buyer/shop-the-look',
	DELETE_REVIEW : environment.API + 'property-rating',
	REPLY_LEAD : environment.API + 'reply-to-lead',
	LIST_AUTH_SHOP_THE_LOOK : environment.API + 'shop-the-look-auth',
	LIST_WITHOUT_AUTH_SHOP_THE_LOOK : environment.API + 'shop-the-look-noauth',
	FETCH_ALL_PROPERTY_FOR_DROP_DOWN :environment.API + 'property-dropdown',
	VIEW_COUNT_SHOP_THE_LOOK : environment.API + 'increase-view-stl',
	CREATE_APPOINTMENT : environment.API + 'event-task-appointment',
	CANCEL_APPOINTMENT : environment.API + 'event-task-appointment/cancel',
	ACCEPT_APPOINTMENT : environment.API + 'accept-lead-appointment',
	CMS : environment.API + 'cms',
	SITE_SETTINGS : environment.API + 'site-settings',
	VALIDATE_ADDRESS : environment.API + 'check-restriction',
	REMOVE_SAVED_SEARCH : environment.API + 'saved-search',
	MODEL_COUNT : environment.API + 'model-count',
	PROPERTY_QUICK_STAT : environment.API + 'property-quick-stat',
	FETCH_ROOM_TYPES : environment.API + 'room-type-all',
	FETCH_PRODUCT_TYPES : environment.API + 'product-type-all',
	SHOW_CLAIM_DETAILS : environment.API + 'show-claim-details',
	SAVE_ROOM_TYPE : environment.API + 'shop-the-look/room-type',
	SAVE_CLAIM_DETAILS : environment.API + 'claim-details',
	UPDATE_CLAIM_DETAILS : environment.API + 'update-claim-details',
	SHARE_AGENT_PROPERTY : environment.API + 'share-agent-property',
	FETCH_SIMILAR_PROPERTY : environment.API + 'property-list-similar-model',
	SHARED_PROPERTY_WITH_CLIENTS : environment.API + 'shared-property-with-clients',
	SHARED_PROPERTY_LIST : environment.API + 'shared-module-list',
	ADD_REPORT_OF_PROPERTY : environment.API + 'report',
	DELETE_PROPERTY : environment.API + 'property',
}

export const Common = {
	SAVE_CONTACT_US : environment.API + 'contact-us',
	FETCH_SITE_SETTING : environment.API + 'site-settings',
	FETCH_CITIES : environment.API + 'cities',
	FETCH_STATES : environment.API + 'states',
	WEB_TOKEN : environment.API + 'web-token',
	VIEW_COUNT_BANNER : environment.API + 'increase-ad-view',
	FETCH_ALL_CITIES : environment.API + 'cities-all',
	FETCH_ALL_STATES : environment.API + 'states-all',
}

export const Buyer = {
	LIST_BUILDER : environment.API + 'builderslist',
	LIST_AGENT : environment.API + 'agentslist',
	LIST_AGENT_PREFFERED : environment.API + 'preferredagentslist',
	FETCH_AGENT_DETAILS: environment.API + 'agentslist',
	LIST_NEIGHBORHOOD : environment.API + 'neighbourhood-list'
}

export const Agent = {
	LIST_INVITES : environment.API + 'invitee',
	SAVE_INVITEE : environment.API + 'invitee',
	FETCH_DETAILS_INVITES : environment.API + 'invitees',
	CANCEL_INVITATION : environment.API + 'invitee',
	AGENT_SUBSCRIPTION_LIST : environment.API + 'agent-subscription-list',
	BUILDER_SUBSCRIPTION_LIST : environment.API + 'builder-subscription-list',
	CARD_DETAILS : environment.API + 'card-details',
	PAYMENT : environment.API + 'subscription-card-payment-register',
	SUBSCRIPTION_CARD_PAYMENT : environment.API + 'subscription-card-payment',
	CARD_PAYMENT_EXISTING : environment.API + 'card-payment-existing',
	CHANGE_SUBSCRIPTION : environment.API + 'change-subscription',
	BUILDER_SUBSCRIPTION : environment.API + 'subscription-unlimited',
	SUBSCRIPTION_REPAYMENT : environment.API + 'subscription-repayment',
	BUILDER_SUBSCRIPTION_REPAYMENT : environment.API + 'builder-subscription-repayment',
	BUILDER_SUBSCRIPTION_REMOVE : environment.API + 'remove-builder-subscription',
	LIST_CLIENTS : environment.API + 'my-clients',
}


