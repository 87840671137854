import { Component, OnInit, ViewChild, TemplateRef, Input, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

import { Regex, LocalVars, DefaultAddress, DefaultAddressArr, DefaultLatLng } from '../../_constants/others.constants';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2'
import { CommonService } from 'src/app/services/common.service';
import { SocialAuthService } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { CmsService } from 'src/app/services/cms.service';
import { interval } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Location } from '@angular/common';
import { NotifierService } from "angular-notifier";
import * as $ from 'jquery';
import { CommonStorageService } from 'src/app/services/common-storage.service';
// declare var $ : any;

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.css']
})

export class WebHeaderComponent implements OnInit {
  @ViewChild("customNotification", { static: true }) customNotificationTmpl;
  @ViewChild('loginTemplate', { static: true }) loginTemplate: TemplateRef<any>;
  @ViewChild('signupTemplate', { static: true }) signupTemplate: TemplateRef<any>;
  @ViewChild('socialSignupTemplate', { static: true }) socialSignupTemplate: TemplateRef<any>;
  @ViewChild('socialSignupAgentTemplate', { static: true }) socialSignupAgentTemplate: TemplateRef<any>;
  @ViewChild('paymentTemplate', { static: true }) paymentTemplate: TemplateRef<any>;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild('navButtonCls') navButton: ElementRef<HTMLElement>;
  currLat: number;
  currLng: number;
  address: any;
  locations: any;
  request: any = {}
  public agentStep2 = false;
  public agentStep1 = true;
  public agentEmail: any;
  public agentDetails: any;
  public subscriptionAgentList: Array<any> = [];



  // public _userTypeForSignUp = null;
  // @Input('userTypeForSignUp')
  // get userTypeForSignUp() {
  // 	return this._userTypeForSignUp;
  // }
  public profileUpdateSubs: any = null;
  public modalRef: BsModalRef;
  public modalBusinessRef: BsModalRef;
  public modalAgentBusinessRef: BsModalRef;
  public modalPaymentRef: BsModalRef;
  public contents: any;
  public fbLink: any;
  public instaLink: any;
  public pinterestLink: any;
  public youtubeLink: any;
  loading = false;
  loadingAgent = false;
  submitted = false;
  submittedAgent = false;
  returnUrl: string;
  user: any;
  isSocialLogIn: boolean = false;
  currentRoute: any;
  homeLink: string = '/home';
  role_id: any;
  signupForm: FormGroup;
  signupAgentForm: FormGroup;
  searchForm: FormGroup;
  public invalidForm = false;
  public invalidAgentForm = false;
  public socialSignupUser: any;
  public userType: string = 'buyer';
  type: any;
  public isLoginOpen: boolean = true;
  public isSignupOpen: boolean = true;
  public timeInterval: number = 10000;
  public deviceToken: any;
  public updateAddressFromHomeSub: any = null;
  public autocompleteAddress: string = '';
  public additionalLat: number = 0;
  public additionalLng: number = 0;
  public isShowReview: number = 0;
  public isMobile: boolean = false;
  public currentDetailsLink: any = '';
  public intervalObservable: any = null;
  public isAdminLogin: boolean = false;
  public mySubscription: any;
  public valueCheck: any;
  public tierDetails : any = {};
  target: number = 0;
  public clicked: boolean = false;
  public builderNotApproved: boolean = false;
  public tierName: string;
  public tierPrice: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private modalService: BsModalService,
    private commonService: CommonService,
    private cmsService: CmsService,
    private authService: SocialAuthService,
    private notificationSErvice: NotificationService,
    private _location: Location,
    private cookieService: CookieService,
    private notifier: NotifierService,
    private _el: ElementRef
  ) {


    this.getSubsAgentList();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    this.router.events.subscribe(re => {
      if (re instanceof NavigationEnd) {
        switch (re.url) {
          case '/':
          case '/home':
            this.currentRoute = 'home';
            break;
          case '/property/search':
            this.currentRoute = 'property/search';
            break;
          case '/property/shop-the-look-list':
            this.currentRoute = 'property/shop-the-look-list';
            break;
          case '/contact':
            this.currentRoute = 'contact';
            break;
          case '/home/login':
            this.currentRoute = 'home';
            this.openLoginTemplate();
            break;
          case '/property/list':
            this.currentRoute = 'property/list';
            break;
          case '/property/recents':
            this.currentRoute = 'property/recents';
            break;
          case '/property/saved-search':
            this.currentRoute = 'property/saved-search';
            break;
          case '/profile/edit-profile':
            this.currentRoute = 'profile/edit-profile';
            break;
          case '/profile/edit-profile':
            this.currentRoute = 'profile/edit-profile';
            break;
          case '/profile/business-profile':
            this.currentRoute = 'profile/business-profile';
            break;

          default:
            console.log(re.url)
        }
      }
    })
    if (localStorage.getItem('currentLocationStr')) {
      this.locations = localStorage.getItem('currentLocationStr');
    }


  }

  ngOnInit(): void {
    let currentUrl = window.location.href;
    this.smothScroll();
    if (currentUrl.includes("manage")) {
      this.isAdminLogin = true;
    }

    this.getHeaderContent();
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    var element = document.getElementById('ux-web-header');
    if (isMobile) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }




    if (localStorage.getItem('currentLocationStr') == null || localStorage.getItem('currentLocationObj') == null) {
      this.getCurrentLocation();
    }

    this.signupForm = this.formBuilder.group({
      business_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),

    });
    this.signupAgentForm = this.formBuilder.group({
      business_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      license_number: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      broker_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      agent_subscription_id: new FormControl('', [Validators.required]),

    });
    this.searchForm = this.formBuilder.group({
      location: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),

    });


    this.notificationSErvice.currentToken.subscribe(params => {
      this.deviceToken = params;
      if (this.deviceToken) {
        this.addDeviceToken(this.deviceToken);
        this.getPWALocation(this.deviceToken);
      }
      // this.commonStorage.setItem(LocalVars.DEVICE_TOKEN, this.deviceToken);
    })

    let user = this.commonStorage.getDetailsItem(LocalVars.USER);

    // console.log(user, 'user')
    if (user) {
      this.user = user;
      this.role_id = this.user.role_slug;
      if (this.role_id == 'builder') {
        if(this.user.admin_approval == 0) {
          this.builderNotApproved = true;
        }

      }
      if (this.user) {
        this.homeLink = '/'
        this.user = {
          ...this.user
        }
      }
      this.profileUpdateSubs = this.commonService.profileUpdate.subscribe(r => {
        if (r['res']) {
          this.user = r['res'];
        }
      })
    }
    else {
      this.authService.authState.subscribe((user) => {
        // this.user = user;
        //console.log(user, 'USER')
        if (user != null) {
          this.userType = atob(this.commonStorage.getItem(LocalVars.SIGN_USER_TYPE));
          console.log(this.userType, "user-type")
          let params = {};
          params['social_profile_id'] = user['id']
          params['first_name'] = user['firstName']
          params['last_name'] = user['lastName']
          params['email'] = user['email']
          params['user_type'] = this.userType;
          params['profile_pic_url'] = user['photoUrl']
          params['login_type'] = user['provider'] == "FACEBOOK" ? 1 : (user['provider'] == "GOOGLE" ? 2 : 3)
          this.socialSignupUser = params;
          if (this.modalRef) {
            this.modalRef.hide()
          }
          let newVal = this.userType.split("_");
          console.log('newVal', newVal)
          if (newVal[1] && newVal[1] == 'login' ) {
            this.socialSignupSignIn();
          }
          if (!newVal[1]){
            this.validateSocialEmail(params['email']);
          }

          
        }


      });
    }
  }

  validateSocialEmail(email) {
    setTimeout(() => {
      // console.log(this.editProfile.get('email').invalid, 'email')
        let params = {
          email: email
        }
        this.loader.show();
        this.userService.validateEmail(params)
          .then(response => {
            this.loader.hide();
            this.socialSignupSignIn();
          }).catch(err => {
            this.loader.hide();
            // this.toaster.error('Email already exists!', 'Oops')
            if (err.status == 400) {
              if (err.error.data.errors.email[0]) {
                this.toaster.error(err.error.data.errors.email[0], 'Oops')
              } else {
                this.toaster.error(err.error.message, 'Oops')
              }
            } else if (err.message) {
              this.toaster.error(err.message, 'Oops')
            } else {
              this.toaster.error('Something went wrong!', 'Oops')
            }

          })

    }, 100);


  }

  socialSignupSignIn() {
    if (this.userType == 'builder') {
      this.openSocialSignupTemplate()
    } else if (this.userType == 'agent') {
      this.openSocialSignupAgentTemplate()
    } else {
      let typeArr = ['buyer_login', 'builder_login', 'agent_login']
      if (typeArr.includes(this.userType)) {
        this.isSocialLogIn = true;
        this.socialSignupUser['user_type'] = this.userType == 'buyer_login' ? 'buyer' : (this.userType == 'builder_login' ? 'builder' : (this.userType == 'agent_login' ? 'agent' : ''));
      } else {
        this.isSocialLogIn = false;
      }
      setTimeout(() => {
        this.socialSignupLogin()
      }, 1000);
    }
  }

  getSubsAgentList() {
    // this.loader.show();
    this.userService.getAgentSubs()
      .then(res => {
        // this.loader.hide();
        this.subscriptionAgentList = res.data.list.data;

      }).catch(err => {
        // this.loader.hide();
        this.loading = false;
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }

  sendNotification(msg) {
    this.notifier.show({
      message: msg,
      type: "warning",
      template: this.customNotificationTmpl
    });
  }

  addDeviceToken(params) {
    let param = {
      token: params
    }
    // console.log('params', param)
    this.commonService.addToken(param)
      .then(r => {
        // if(r['data']){
        // console.log(r['data'], 'Response')
        // }
      }).catch(err => {
        console.log('Something went wrong!', 'Oops')
      })
  }

  getHeaderContent() {
    let promises = [];
    this.loader.show()
    promises.push(this.cmsService.getHeaderFooterContents({}));
    Promise.all(promises).then(allData => {
      if (allData) {
        this.loader.hide();
        if (allData[0] && allData[0].data && allData[0].data.list) {
          this.contents = allData[0].data.list;
          this.contents.forEach(x => {
            if (x.key == "facebook_link") {
              this.fbLink = x.val ? x.val : '';
            }
            if (x.key == "instagram_link") {
              this.instaLink = x.val ? x.val : '';
            }
            if (x.key == "pinterest_link") {
              this.pinterestLink = x.val ? x.val : '';
            }
            if (x.key == "youtube_link") {
              this.youtubeLink = x.val ? x.val : '';
            }
            if (x.key == "rating_review") {
              this.isShowReview = x.val ? x.val : 0;
              this.commonService.updateReviewVisiblilty(this.isShowReview);
              this.commonStorage.setItem(LocalVars.IS_SHOW_REVIEW, btoa(this.isShowReview.toString()))
            }
          });

          //console.log('contacts-header', this.contents)
        }
      }
    }).finally(() => {
    })
  }

  getPWALocation(token) {
    this.updateAddressFromHomeSub = this.commonService.addressUpdateFromHome.subscribe(r => {
      let resp = r.res;
      // console.log(resp, 'resp')
      if (resp != null) {
        this.locations = r.res
      }

    })
    this.getAllNotification(token);


  }

  getAllNotification(token) {
    this.intervalObservable = interval(this.timeInterval).subscribe(x => {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      var element = document.getElementById('ux-web-header');
      if (isMobile && document.hasFocus()) {
        if (navigator.geolocation) {
          // this.additionalLat = this.additionalLat + .01;
          // this.additionalLng = this.additionalLng + .01;    
          //navigator.geolocation.getCurrentPosition(position => {
            //if (position.coords.latitude && position.coords.longitude) {
              // let newLat = 39.5296329 + this.additionalLat;
              // let newLng = -119.8138027 - this.additionalLng;
              // let params = {
              //   latitude : newLat,
              //   longitude : newLng,
              //   token : token
              // }
              let params = {
                //latitude: position.coords.latitude,
                //longitude: position.coords.longitude,
                latitude: DefaultLatLng[0]['lat'],
                longitude: DefaultLatLng[0]['lng'],
                token: token
              }
              // console.log(params, 'params')
              this.userService.getPropertyNearby(params)
                .then(r => {
                  if (r['data']) {
                    // console.log(r['data'], 'Response')
                    for (const iterator of r['data']) {
                      let uid = iterator.uid
                      this.currentDetailsLink = `/property/details/${uid}`;
                      this.sendNotification(iterator.custom_message)
                    }
                  }
                }).catch(err => {
                  console.log('Something went wrong!', 'Oops')
                })
            //}
          //})
        }
      }
    })
  }

  checkNavOpen() {
    // $('#nav-button-cls').click();
    if (this.isMobile) {
      let el: HTMLElement = this.navButton.nativeElement;
      el.click();
    }
  }

  // checkNvOpen(param) {
  //   // $('#nav-button-cls').click();
  //   if(this.isMobile){
  //     let el: HTMLElement = this.navButton.nativeElement;
  //     el.click();
  //   }
  //   // console.log(`${param}`)
  //   this.router.navigate([`${param}`]);
  //   setTimeout(() => {
  //     let win = (window as any);
  //     if(win.location.search !== '?loaded' ) {
  //         win.location.search = '?loaded';
  //         win.location.reload();
  //     }
  //   }, 500);

  // }

  getCurrentLocation() {
    /* try {

      navigator.permissions.query({
        name: 'geolocation'
      }).then(permission => {
        if (permission.state === "granted") {
          this.fetchCurrentLocation()
        } else if (permission.state == 'prompt') {
          if (navigator.geolocation) {

            this.fetchCurrentLocation()
          } else {
            alert("Geolocation is not supported by this browser.");
          }
        } else {
          this.locations = DefaultAddress;
          this.commonService.updateCurrentAddress(DefaultAddress);

          localStorage.setItem('currentLocationStr', DefaultAddress);
          localStorage.setItem('currentLocationObj', JSON.stringify(DefaultAddressArr));
          localStorage.setItem('currentLatLng', JSON.stringify(DefaultLatLng));
        }

      })

      interval(1000).subscribe(x => {
        navigator.permissions.query({
          name: 'geolocation'
        }).then(permission => {
          if (permission.state === "denied" && localStorage.getItem('currentLocationStr') == null) {
            this.locations = DefaultAddress;
            this.commonService.updateCurrentAddress(DefaultAddress);

            localStorage.setItem('currentLocationStr', DefaultAddress);
            localStorage.setItem('currentLocationObj', JSON.stringify(DefaultAddressArr));
            localStorage.setItem('currentLatLng', JSON.stringify(DefaultLatLng));
          }
        })
      })
    } catch (error) { */
      this.locations = DefaultAddress;
      this.commonService.updateCurrentAddress(DefaultAddress);
      localStorage.setItem('currentLocationStr', DefaultAddress);
      localStorage.setItem('currentLocationObj', JSON.stringify(DefaultAddressArr));
      localStorage.setItem('currentLatLng', JSON.stringify(DefaultLatLng));
      // console.log('Can not get location')
    //}

  }


  /* fetchCurrentLocation() {
    navigator.geolocation.getCurrentPosition(position => {
      this.loader.show();
      this.currLat = position.coords.latitude;
      this.currLng = position.coords.longitude;
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(this.currLat, this.currLng);
      this.request = {
        latLng: latlng
      };
      geocoder.geocode(this.request, (results, status) => {

        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0] != null) {
            this.handleAddressChange(results[0], 0);
            if (this.locations) {
              this.loader.hide()
            }
          } else {
            this.loader.hide()
            alert("No address available");
          }
        }
      })
    })


  } */


  public handleAddressChange(address, type) {
    //address.stopPropagation();
    var addArr = [];
    let addressStr = '';
    let latlng = [];
    let isValidCountry = false;
    for (const iterator of address.address_components) {
      if (iterator.types.includes('country')) {
        if (iterator.short_name == 'US') {
          isValidCountry = true;
        }
      }
    }
    if (isValidCountry) {
      latlng = [{ lat: address.geometry.location.lat() }, { lng: address.geometry.location.lng() }]
      for (const iterator of address.address_components) {
        if (iterator.types.includes('locality')) {
          addArr.push({ 'city': iterator.short_name })
          addressStr += iterator.short_name + ', '
        }
        if (iterator.types.includes('administrative_area_level_1')) {
          addArr.push({ 'state': iterator.short_name })
          addressStr += iterator.short_name
        }
        // if (iterator.types.includes('postal_code')) {
        //   addArr.push({ 'zipcode': iterator.long_name })
        //   addressStr += ', ' + iterator.long_name
        // }
      }

    } else {
      addressStr = DefaultAddress;
      addArr = DefaultAddressArr;
      latlng = DefaultLatLng;
    }
    if (addressStr) {
      // console.log('addressStr', addressStr, addArr, latlng)
      this.locations = addressStr
      if (type == 1) {
        this.autocompleteAddress = addressStr
      } else {
        this.autocompleteAddress = ''
      }
    }
    setTimeout(() => {
      this.commonService.updateCurrentAddress(address);
      localStorage.setItem('currentLocationStr', addressStr);
      localStorage.setItem('currentLocationObj', JSON.stringify(addArr));
      localStorage.setItem('currentLatLng', JSON.stringify(latlng));
    }, 500);

  }
  onBlurAddress() {
    // console.log('location', this.locations)
    // console.log('auto-location', this.autocompleteAddress)
    setTimeout(() => {
      if (this.locations == '') {
        this.commonService.updateCurrentAddress('');
        // localStorage.removeItem('currentLocationStr')
        // localStorage.removeItem('currentLocationObj')
        localStorage.setItem('currentLocationStr', '');
        localStorage.setItem('currentLocationObj', '');
        localStorage.setItem('currentLatLng', '');
      } else {
        if (this.autocompleteAddress == '' && this.locations != localStorage.getItem('currentLocationStr')) {
          this.locations = '';
          this.autocompleteAddress = '';
          this.commonService.updateCurrentAddress('');
          // localStorage.removeItem('currentLocationStr')
          // localStorage.removeItem('currentLocationObj')
          localStorage.setItem('currentLocationStr', '');
          localStorage.setItem('currentLocationObj', '');
          localStorage.setItem('currentLatLng', '');
        }
      }

    }, 1000);

  }

  private validateBlankValue(control: FormControl): any {
    if (this.signupForm) {
      return control.value && control.value.trim() === '' ? { emptyValue: true } : null;
    }
  }

  public closeLoginModal(event) {
    this.modalRef.hide();
    this.isLoginOpen = true;
  }

  openLoginTemplate() {
    // if (!this.isSignupOpen && this.modalRef) {
    //   this.modalRef.hide();
    //   this.isSignupOpen = true;
    // }
    // if (this.isLoginOpen) {
    this.openModal(this.loginTemplate, { class: 'modal-md', ignoreBackdropClick: true });
    //   this.isLoginOpen = false;
    // }

  }

  private openModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
    this.modalRef = this.modalService.show(template, options);
  }

  public cancelSignUp(event) {
    // alert('check-web-header')
    // console.log('cancel sign-up modal', event)
    // this.openSignupTemplate()
  }

  openSignupTemplate(value: any = '') {
    // if (!this.isLoginOpen && this.modalRef) {
    //   this.modalRef.hide();
    //   this.isLoginOpen = true;
    // }
    // if (this.isSignupOpen) {
    this.valueCheck = value;
    // console.log('open-signup-modal')
    this.openModal(this.signupTemplate, { class: 'modal-md', ignoreBackdropClick: true });
    if (this.valueCheck == 'agent') {
      setTimeout(() => {
        // console.log(document.getElementById('contact-tab'))
        document.getElementById('contact-tab').click();
      }, 1000);
    }
    //   this.isSignupOpen = false;
    // }

  }

  logout() {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      // html: `<h3 style="font-weight:500;">Are you sure you want to logout?</h3>`,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Sure',
      cancelButtonText: 'No',
      confirmButtonColor: '#f37135',
      width: '25rem'
    }).then((result) => {
      if (result.value) {
        this.logoutConfirm()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }
  logoutConfirm() {
    this.loader.show();
    this.socialLogout()

    setTimeout(() => {
      this.loader.hide();
      this.commonService.doLogOut();
      this.clearAll();
    }, 500);
  }

  private clearAll() {
    this.cookieService.deleteAll('/');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  public closeSocialSignupModal() {
    if (this.modalRef) {
      this.modalRef.hide();

    }
    if (this.modalBusinessRef) {
      this.modalBusinessRef.hide()
    }
    if (this.modalAgentBusinessRef) {
      this.modalAgentBusinessRef.hide();
    }


  }

  openSocialSignupTemplate() {
    this.openBusinessModal(this.socialSignupTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }

  openSocialSignupAgentTemplate() {
    this.openAgentBusinessModal(this.socialSignupAgentTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }


  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    }
    if (this.signupForm.valid) {

      this.socialSignupUser['business_name'] = this.signupForm.get('business_name').value
      this.invalidForm = false;
      this.loading = true;
      this.socialSignupLogin()
      this.submitted = false;
      this.signupForm.reset()

    } else {
      this.invalidForm = true;
    }

  }

  toggleAgent(type) {
    if (type == '2') {
      if(this.signupAgentForm.get('business_name').value && this.signupAgentForm.get('license_number').value && this.signupAgentForm.get('broker_name').value) {
        this.agentStep2 = true;
        this.agentStep1 = false;
      }
    }
    if (type == '1') {
      this.agentStep1 = true;
      this.agentStep2 = false;
    }
  }




  private openPaymentModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
    // console.log('open-payment-modal')
    this.modalPaymentRef = this.modalService.show(template, options);
  }

  // closeModal() {
  //   if(this.modalRef) {
  //     this.modalRef.hide();
  //   }
  // }

  public closeSignup(event) {
    // console.log('sign-up modal', event)

    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (event == 'AgentSubmit') {
      this.agentDetails = JSON.parse(atob(localStorage.getItem('agentDetails')));
      this.agentEmail = this.agentDetails.email;
      this.tierDetails = this.subscriptionAgentList.filter(val =>{
        return val.id == this.agentDetails.agent_subscription_id
      })
      // console.log('tierDetails', this.tierDetails)
      this.tierName = this.tierDetails[0].name;
      this.tierPrice = this.tierDetails[0].price;
      this.openPaymentTemplate()
    }
    // this.isSignupOpen = true;
  }

  openPaymentTemplate() {
    this.openPaymentModal(this.paymentTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }

  paymentModalClose(condition: any = null) {
    // console.log('sdgfg')
    // setTimeout(() => {
    this.modalPaymentRef.hide();
    if (condition != 'submit-payment') {
      this.openSignupTemplate('agent');
    }
    // this.cancelSignupModal.emit(true);
    // }, 200);
    // if(this.modalPaymentRef) {
    //   console.log('payment-modal')
    //   this.modalPaymentRef.hide();
    // }
  }

  savePaymentModal(value) {
    const params = this.socialSignupUser;
    // if (localStorage.getItem('agentDetails')) {
    //   this.agentDetails = JSON.parse(atob(localStorage.getItem('agentDetails')));
    // }
    // console.log('event', value, params);
    this.loader.show();
    if (value) {
      this.paymentModalClose('submit-payment');
      if (!this.socialSignupUser && this.agentDetails) {
        this.agentDetails['card_id'] = value['card_id'];
        this.agentDetails['stripe_customer_id'] = value['stripe_customer_id'];
        this.agentDetails['transaction_id'] = value['transaction_id'];
        this.userService.registrationForAgent(this.agentDetails)
          .then(response => {
            // console.log(response);
            this.loader.hide();
            if (response['data']) {
              localStorage.removeItem('agentDetails')
              this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
            } else {
              this.openSignupTemplate('agent');
              this.toaster.error(response.message, 'Oops')
            }
            this.loading = false;
          }).catch(err => {
            this.loader.hide();
            this.openSignupTemplate('agent');
            this.loading = false;
            if (err.status == 400) {
              if (err.error.data.errors.email[0]) {
                this.toaster.error(err.error.data.errors.email[0], 'Oops')
              } else {
                this.toaster.error(err.error.message, 'Oops')
              }

            } else if (err.message) {
              this.toaster.error(err.message, 'Oops')
            } else {
              this.toaster.error('Something went wrong!', 'Oops')
            }

          })
      }
      if (this.socialSignupUser) {
        this.socialSignupUser['card_id'] = value['card_id'];
        this.socialSignupUser['stripe_customer_id'] = value['stripe_customer_id'];
        this.socialSignupUser['transaction_id'] = value['transaction_id'];
        this.userService.socialSignupLogin(this.socialSignupUser)
          .then(r => {
            this.loader.hide();
            if (r['data']) {
              this.commonStorage.setItem(LocalVars.TOKEN_VAR, btoa(r['data']['token']));
              this.closeSocialSignupModal();
              this.toaster.success(r.message, 'Success')
              this.getUserDetails();
            } else {
              this.toaster.error(r.message, 'Oops')
            }
            this.loading = false;
          }).catch(err => {
            this.loader.hide();
            this.socialLogout();
            this.loading = false;
            if (err.status == 400) {
              if (err.error.data.errors.email && err.error.data.errors.email[0]) {
                this.toaster.error(err.error.data.errors.email[0], 'Oops')
              } else {
                this.toaster.error(err.error.message, 'Oops')
              }
            } else if (err.status == 401) {
              if (this.modalBusinessRef) {
                this.modalBusinessRef.hide()
              }
              if (this.modalRef) {
                this.modalRef.hide()
              }

              let msg = 'This account already exists, please try with another account'
              // if(this.isSocialLogIn == true) {
              //   msg = 'Please try login with another account, account already in use for other user.'
              // } else {
              //   msg = 'This account is already exists, please try signup with another account'
              // }
              this.toaster.error(msg, 'Sorry!')

            } else if (err.message) {
              this.toaster.error(err.message, 'Oops')
            } else {
              this.toaster.error('Something went wrong!', 'Oops')
            }

          })
      }

    }
  }


  onAgentSubmit() {
    this.submittedAgent = true;
    if (this.signupAgentForm.invalid) {
      return;
    }
    if (this.signupAgentForm.valid) {

      this.socialSignupUser['business_name'] = this.signupAgentForm.get('business_name').value
      this.socialSignupUser['license_number'] = this.signupAgentForm.get('license_number').value
      this.socialSignupUser['broker_name'] = this.signupAgentForm.get('broker_name').value
      this.socialSignupUser['agent_subscription_id'] = this.signupAgentForm.get('agent_subscription_id').value
      this.invalidAgentForm = false;
      this.loadingAgent = true;
      // this.socialSignupLogin()
      // if
      this.modalAgentBusinessRef.hide();
      this.agentEmail = this.socialSignupUser.email;
      this.tierDetails = this.subscriptionAgentList.filter(val =>{
        return val.id == this.socialSignupUser.agent_subscription_id
      })
      // console.log('tierDetails', this.tierDetails)
      this.tierName = this.tierDetails[0].name;
      this.tierPrice = this.tierDetails[0].price;
      this.openPaymentTemplate();
      this.submittedAgent = false;
      this.signupAgentForm.reset()

    } else {
      this.invalidAgentForm = true;
    }

  }

  socialSignupLogin() {
    this.loader.show();
    this.userService.socialSignupLogin(this.socialSignupUser)
      .then(r => {
        this.loader.hide();
        if (r['data']) {
          this.commonStorage.setItem(LocalVars.TOKEN_VAR, btoa(r['data']['token']));
          this.closeSocialSignupModal();
          this.toaster.success(r.message, 'Success')
          this.getUserDetails();
        } else {
          this.toaster.error(r.message, 'Oops')
        }
        this.loading = false;
      }).catch(err => {
        this.loader.hide();
        this.socialLogout();
        this.loading = false;
        if (err.status == 400) {
          if (err.error.data.errors.email && err.error.data.errors.email[0]) {
            this.toaster.error(err.error.data.errors.email[0], 'Oops')
          } else {
            this.toaster.error(err.error.message, 'Oops')
          }
        } else if (err.status == 401) {
          if (this.modalBusinessRef) {
            this.modalBusinessRef.hide()
          }
          if (this.modalRef) {
            this.modalRef.hide()
          }

          let msg = 'This account is already exists, please try with another account'
          // if(this.isSocialLogIn == true) {
          //   msg = 'Please try login with another account, account already in use for other user.'
          // } else {
          //   msg = 'This account is already exists, please try signup with another account'
          // }
          this.toaster.error(msg, 'Sorry!')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }

  getUserDetails() {
    this.userService.me()
      .then(r => {
        if (r['data'] && r['data']['user']) {
          this.completeLogin(r['data']['user']);
        } else {
          this.toaster.error('User details not found!', 'User fetch')
        }
      }).catch(err => {
        // console.log(err)
        const message = err && err.error && err.error.message || "Something went wrong!";
        this.toaster.error(message, 'Login Error')
      })
  }

  private completeLogin(user: any) {
    //this.user = user;
    this.commonStorage.setItem(LocalVars.USER_VAR, btoa(user.uid));
    this.commonStorage.setDetailsItem(LocalVars.USER, user);

    this.router.navigate([''], { replaceUrl: true });
  }

  public socialLogout() {
    this.authService.authState.subscribe((user) => {
      if (user != null) {
        this.authService.signOut();
      }
    })
  }

  goToPropertyList() {
    this.router.navigate(['/property/list']);
  }

  goToEditProfile() {
    this.router.navigate(['/profile/edit-profile']);
  }

  goToBusinessProfile() {
    this.router.navigate(['/profile/business-profile']);
  }

  goToFavorites() {
    this.router.navigate(['/property/favorite']);

  }

  goToContactList() {
    this.router.navigate(['/property/leads']);
  }

  goToCalendar() {
    this.router.navigate(['/property/calendar'])
  }
  goToSavedSearch() {
    this.router.navigate(['/property/saved-search']);

  }

  private openBusinessModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
    this.modalBusinessRef = this.modalService.show(template, options);
  }

  private openAgentBusinessModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
    this.modalAgentBusinessRef = this.modalService.show(template, options);
  }

  public ngOnDestroy(): void {
    if (this.updateAddressFromHomeSub) {
      this.updateAddressFromHomeSub.unsubscribe();
    }

    if (this.intervalObservable) {
      this.intervalObservable.unsubscribe();
    }

    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }

  }

  back() {
    this._location.back();
  }


  Scroll() {
    // if(this.target != 0) {
    $('html,body').animate({
      scrollTop: 0
    }, 200);
    // }
    // this.target++;
    // this.smothScroll();
    // if(this.target==3){
    //      this.target=0;
    // }
  }
  smothScroll() {
    if (this.target == 3) {
      $('html,body').animate({
        scrollTop: 0
      }, 0);
    }
    let top;
    if (this.target == 0) {
      top = 0;
      // console.log(top)
    }
    else if (this.target == 1) {
      top = document.getElementById("sec2").offsetTop - 250;
      // console.log(top)
    }
    else if (this.target == 2) {
      top = document.getElementById("sec3").offsetTop - 150;
      // console.log(top)
    }
    // console.log(top)
    $('html,body').animate({
      scrollTop: top
    }, 200);

    // this.target++;
    // if(this.target==3){
    //   this.target=0;
    // }
  }

  public onClick(event): void {
    // event.preventDefault();
    // event.stopPropagation();
    this.clicked = true;
  }

  myAccountClink() {
    this.clicked = false;
  }

  @HostListener('document:click', ['event'])
  private clickedOutside(event): void {
    if(this.isMobile) {
      console.log(this.clicked)
      if (this._el.nativeElement.querySelector('#navbarSupportedContent').classList.contains('show') && this.clicked) {
        //this._el.nativeElement.querySelector('#menu-toggle-button').click()
        // this._el.nativeElement.querySelector('#navbarSupportedContent').classList.add("collapsing")
          // this._el.nativeElement.querySelector('.dropdown-menu').classList.toggle('show')
      }
    }
  }


}
