<div class="modal-dialog">
    <strong class="ml-3">Model Review</strong>
    <button type="button" class="close assign-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
    <hr>
    
    <div class="modal-content" style="border: 0; margin: 0;">
        <!-- <div class="modal-header text-center">
         
          
        </div> -->
        
        <div class="modal-body">
          <form >
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group reviw_star_form">
                        <label for="exampleInputEmail1 ml-3">Rating</label>
                        <rating [(ngModel)]="overall_rating" name="overall_rating" [max]="max" [readonly]="false"></rating>
                    </div>
                </div>  

            </div>
            <div class="row text-div">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Comment</label>
                        <textarea [(ngModel)]="comment" name="comment" class="form-control ux-textarea" placeholder="Comment Here"></textarea>
                    </div>
                </div>
                <div class="col-sm-4">
                </div>               

            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group float-right">
                        <button class="btn btn-success" (click)="onSubmit()">Submit</button>
                    </div>
                </div>               

            </div>
            
        </form>
              
        </div>
    </div>
</div>

