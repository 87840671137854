<div>
    <!-- <h2 class="payment-title">Payment for {{tierDetails.name}}</h2> -->
    <div class="add-payments-new"><button class="btn  w-40 ux-submit add-card-new" (click)="newCardAdd()">Add New
            Card</button></div>
            <div class="card-sec-add">
                    <ng-container *ngFor="let card of cardList; index as i">
                            <!-- {{card.id}} -->
                                <div class="input-inner-new"><input [(ngModel)]="cardId" type="radio" id="{{'card-'+i}}" [value]="card.id">
                                    <div class="card-sec">
                                        <!-- <label for="{{'card-'+i}}">{{card.card_name}}</label>
                                    <strong class="card-num">{{card.card_last4}}</strong> -->
                    
                                        <label for="{{'card-'+i}}">{{card.card_brand}}</label>
                                        <strong class="card-num">**** **** **** {{card.card_last4}}</strong>
                                    </div>
                                </div>
                        </ng-container>
            </div>
    
    <div *ngIf="errorMsg" class="has-error">Please select one card to continue</div>
    <div class="btn-payments btn-payments-new">

        <button class="btn reg_loginbtn w-40 ux-submit orange-btn" (click)="savedCard()">Make Payment</button>
    </div>
</div>