import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
	private loaderSubject = new BehaviorSubject<any>(false);
	public loader = this.loaderSubject.asObservable();

	constructor() {
		this.loaderSubject.next(false);
	}

	show() {
		this.loaderSubject.next(true);
	}

	hide() {
		this.loaderSubject.next(false);
	}

}
