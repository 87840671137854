import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
@Injectable()
export class NotificationService {
 
currentMessage = new BehaviorSubject(null);
currentToken = new BehaviorSubject(null);
 
constructor(private angularFireMessaging: AngularFireMessaging) {
 
  this.angularFireMessaging.messages.subscribe(
    (msgings) => {
        console.log('msgings',msgings);
       // msgings.onMessage = msgings.onMessage.bind(msgings);
       //    msgings.onMessage = msgings.onMessage.bind(msgings);
       //    msgings.onTokenRefresh=msgings.onTokenRefresh.bind(msgings);
   })

  }
 
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
    (token) => {
      console.log('token', token);
      this.currentToken.next(token);
    });
  }
 
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
    (msg) => {
    console.log("show message!", msg);
    this.currentMessage.next(msg);
       })
    }
}
