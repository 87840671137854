import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';

import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminFooterComponent } from './admin-footer/admin-footer.component';
import { AdminSedebarComponent } from './admin-sedebar/admin-sedebar.component';
import { WebLayoutComponent } from './web-layout/web-layout.component';
import { WebHeaderComponent } from './web-header/web-header.component';
import { WebFooterComponent } from './web-footer/web-footer.component';
import { ComponentModule } from '../_components/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { CarouselModule } from 'ngx-bootstrap/carousel';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NotifierModule } from "angular-notifier";

@NgModule({
  declarations: [
    WebHeaderComponent, 
    WebFooterComponent, 
    AdminHeaderComponent, 
    AdminFooterComponent, 
    AdminSedebarComponent,
    WebLayoutComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    ComponentModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // CarouselModule,
    IvyCarouselModule,
	GooglePlaceModule,
    NotifierModule.withConfig({
			position: {
				horizontal: { position: 'right', distance: 12 },
				vertical: { position: 'top', distance: 12, gap: 10 }
			},
			theme: "material",
			behaviour: {
				autoHide: 5000,
				onClick: false,
				onMouseover: "pauseAutoHide",
				showDismissButton: true,
				stacking: 4
			},
			animations: {
				enabled: true,
				show: {
					preset: "slide",
					speed: 300,
					easing: "ease"
				},
				hide: {
					preset: "fade",
					speed: 300,
					easing: "ease",
					offset: 50
				},
				shift: {
					speed: 300,
					easing: "ease"
				},
				overlap: 150
			}
		}),
  ],
  exports: [
    WebHeaderComponent,
    WebFooterComponent,
    WebLayoutComponent
  ]
})
export class LayoutModule { }
