export const Regex = {
	email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	//password: `(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}`,
	password: /^(?=.*\d)(?=.*[a-zA-Z]).{6,16}$/,
	// phone: `^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*`,
	phone: /^\d{10}$/,
	coords: `(((-|)[0-9]*\.[0-9]*)|((-|)[0-9]*(\.)[0-9]*))`,
	singleWord: `[A-Za-z0-9:_\-]*`,
	deviceCreds: `[A-Za-z0-9_//\-]*`,
	domainName: `[A-Za-z0-9//\-]*`,
	paramName: `[a-zA-Z0-9_]*`,
	currency: `[0-9]+(\.[0-9][0-9])?`,
	number: `[0-9]*`,
	float: `^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$`,
	percentage: `(100([\.][0]{1,})?$|[0-9]{1,2}([\.][0-9]{1,})?)`,
	zipcode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
	url: `^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`,
	color: `^(#[0-9a-f]{3}|#(?:[0-9a-f]{2}){2,4}|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d\.]+%?\))$`,
	
};

const _localVarPrefix = 'new_built_buyer_';
export const LocalVars = {
	TOKEN_VAR: _localVarPrefix + 'Token',
	TOKEN_TEMP_VAR: _localVarPrefix + 'Token_TEMP',
	USER_VAR: _localVarPrefix + 'User',
	USER: _localVarPrefix + 'User_Details',
	DOMAIN: _localVarPrefix + 'Domain',
	PER_PAGE_ITEM: _localVarPrefix + 'PerPageItem',
	FILTER_PARMS: _localVarPrefix + 'FilterParams',
	SIGN_USER_TYPE: _localVarPrefix + 'UserType',
	BUILDER_ID: _localVarPrefix + 'BuilderId',
	STL_FILTER_PARMS: _localVarPrefix + 'STLFilterParams',
	DEVICE_TOKEN: _localVarPrefix + 'Device_Token',
	PROPERTY_LIST_FILTER_PARMS:  _localVarPrefix + 'PropertyListFilterParams',
	IS_SHOW_REVIEW: _localVarPrefix + 'IsShowReview',
	ADMIN_TOKEN_VAR: _localVarPrefix + 'AdminToken',
};

export const RolePermission = {
	'admin' : [ 'dashboard', 'doctor', 'patient', 'appointment', 'medicine', 'tests' ],
	'sub-admin' : [ 'dashboard', 'doctor', 'patient', 'appointment', 'medicine', 'tests' ]
}

export const CountryID = 231;

export const CurrencyName = 'Dollar';
export const CurrencyValue = '$';
export const DefaultAddress = 'Phoenix, AZ';
export const DefaultAddressArr = [{city : 'Phoenix'}, {state : 'AZ'}];
export const DefaultLatLng = [{lat : 33.4484}, {lng : -112.074036}];




