<!-- <div class="menu-fixed"> -->
<!-- <div id="home-scroll" (click)="Scroll()"  [ngClass]="target == 2 ? 'rotate' : ''">
    <span class="arrow-bounce">&#8595;</span>
</div> -->
<div class="backtotop" [hidden]="currentRoute == 'property/search'">
    <a id="ux-web-header"></a>
    <!--content here-->
    <a (click)="Scroll()"> <i class="fa fa-arrow-circle-up" aria-hidden="true"></i></a>
</div>
<div class="toploginbar d-none d-sm-block" id="ux-web-header">
    <div class="container">
        <div class="row">
            <div class="col-lg-44 col-md-4">
                <div class="topleftSocialicon">
                    <ul>
                        <li class="facebook" *ngIf="fbLink">
                            <a [href]="fbLink" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        </li>
                        <li class="printersr" *ngIf="pinterestLink">
                            <a [href]="pinterestLink" target="_blank"><i class="fa fa-pinterest-p"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li class="instagram" *ngIf="instaLink">
                            <a [href]="instaLink" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        </li>
                        <li class="youtube" *ngIf="youtubeLink">
                            <a [href]="youtubeLink" target="_blank"><i class="fa fa-youtube-play"
                                    aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="toprightLogin">
                    <ul *ngIf="!isAdminLogin">
                        <li *ngIf="builderNotApproved" class="builder"><a class="builder-approved ribbon-both">Account not approved by Admin, please contact</a></li>
                        <li *ngIf="!user"><a (click)="openLoginTemplate()" class="ux-pointer"><img
                                    src="../../../assets/images/login_icon.png" /> Login</a></li>
                        <li *ngIf="!user"><a class="active" (click)="openSignupTemplate()" class="ux-pointer"><img
                                    src="../../../assets/images/create_account.png" /> Create an account</a></li>
                        <li *ngIf="user && !isAdminLogin" class="my-acc">
                            <a class="nav-link ux-pointer" data-toggle="dropdown" href="javascript:void(0)"><span
                                    class="prof_pic"><img
                                        src="{{user?.avatar?.thumb || '../../../assets/images/download.png'}}"
                                        class="prof_img"></span>
                                My Account <i class="fa fa-angle-down ml-2" aria-hidden="true"></i></a>

                            <div class="profileDropdown dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <ul *ngIf="role_id =='buyer'">
                                    <li><a href="javascript:void(0)" (click)="goToEditProfile()"><span><i
                                                    class="fa fa-user" aria-hidden="true"></i></span> My Profile</a>
                                    </li>
                                    <!-- <li><a routerLink="/property/inbox"><span><i class="fa fa-envelope"
                                                    aria-hidden="true"></i></span>My Inbox</a></li> -->
                                    <li><a href="javascript:void(0)" (click)="goToSavedSearch()"><span><i
                                                    class="fa fa-search" aria-hidden="true"></i></span> Saved
                                            Searches</a></li>
                                    <li><a routerLink="/property/saved-look"><span><i class="fa fa-plus"
                                                    aria-hidden="true"></i></span> Saved Looks</a></li>
                                    <li><a routerLink="/property/recents"><span><i class="fa fa-eye"
                                                    aria-hidden="true"></i></span> Recently Seen</a></li>
                                    <!-- <li><a href="javascript:void(0)" (click)="goToCalendar()"><span><i
                                                        class="fa fa-tasks"></i></span>My Task</a>
                                        </li> -->
                                    <li><a href="javascript:void(0)" (click)="goToFavorites()"><span><i
                                                    class="fa fa-heart" aria-hidden="true"></i></span> Favorites</a>
                                    </li>
                                    <!-- <li *ngIf="isShowReview == 1"><a routerLink="/profile/reviews"><span><i class="fa fa-list-ul"
                                                    aria-hidden="true"></i></span> My Reviews</a></li> -->
                                    <li><a routerLink="/buyer/builders"><span><i class="fa fa-list-alt"
                                                    aria-hidden="true"></i></span> Builders List</a></li>
                                    <!-- <li><a routerLink="/buyer/agents"><span><i class="fa fa-th-list"
                                                    aria-hidden="true"></i></span> Agent List</a></li> -->
                                    <!-- <li><a routerLink="/buyer/shared-list"><span><i class="fa fa-th-list"
                                                    aria-hidden="true"></i></span> Shared Models</a></li> -->
                                    <li><a routerLink="/buyer/neighbourhoods"><span><i class="fa fa-list"
                                                    aria-hidden="true"></i></span> Neighborhoods</a></li>
                                    <li><a class="ux-pointer" (click)="logout()"><span><i class="fa fa-sign-out"
                                                    aria-hidden="true"></i></span> Log Out</a></li>
                                </ul>

                                <!-- this ul for builder -->

                                <ul *ngIf="role_id =='builder'">
                                    <li><a class="ux-pointer" href="javascript:void(0)"
                                            (click)="goToPropertyList()"><span><i class="fa fa-user"
                                                    aria-hidden="true"></i></span> Model Listing</a></li>
                                    <!-- <li><a href="javascript:void(0)"><span><i class="fa fa-envelope"
                                                        aria-hidden="true"></i></span> Client Management</a></li> -->
                                    <!-- <li *ngIf="isShowReview == 1"><a href="javascript:void(0)" routerLink="/profile/reviews"><span><i
                                                    class="fa fa-search" aria-hidden="true"></i></span> Model
                                            Reviews</a></li> -->
                                    <li><a href="javascript:void(0)" (click)="goToBusinessProfile()"><span><i
                                                    class="fa fa-plus" aria-hidden="true"></i></span> Business
                                            Profile</a></li>

                                    <li><a href="javascript:void(0)" (click)="goToEditProfile()"><span><i
                                                    class="fa fa-eye" aria-hidden="true"></i></span> My Profile</a></li>
                                    <!-- <li><a href="javascript:void(0)" (click)="goToCalendar()"><span><i
                                                    class="fa fa-tasks" aria-hidden="true"></i></span> My Tasks</a></li> -->
                                    <!-- <li><a routerLink="/property/inbox"><span><i class="fa fa-envelope"
                                                    aria-hidden="true"></i></span>My Inbox</a></li> -->
                                    <!-- <li><a href="javascript:void(0)"><span><i class="fa fa-heart"
                                                        aria-hidden="true"></i></span> Paid Features</a></li> -->
                                    <!-- <li><a href="javascript:void(0)" (click)="goToContactList()"><span><i
                                                    class="fa fa-list-ul" aria-hidden="true"></i></span> My Leads</a>
                                    </li> -->
                                    <li><a class="ux-pointer" (click)="logout()"><span><i class="fa fa-sign-out"
                                                    aria-hidden="true"></i></span> Log Out</a></li>
                                </ul>

                                <!-- for agent ul -->

                                <ul *ngIf="role_id=='agent'">
                                    <!-- <li><a class="ux-pointer" (click)="goToPropertyList()"><span><i class="fa fa-user"
                                                    aria-hidden="true"></i></span> Lead Models</a></li> -->
                                    <!-- <li><a routerLink="/property/inbox"><span><i class="fa fa-envelope"
                                                    aria-hidden="true"></i></span>My Inbox</a></li> -->
                                    <li><a href="javascript:void(0)" (click)="goToBusinessProfile()"><span><i
                                                    class="fa fa-plus" aria-hidden="true"></i></span> Business
                                            Profile</a></li>
                                    <li><a href="javascript:void(0)" (click)="goToEditProfile()"><span><i
                                                    class="fa fa-eye" aria-hidden="true"></i></span> My Profile</a></li>
                                    <!-- <li><a href="javascript:void(0)" *ngIf="role_id == 'agent'" (click)="goToCalendar()"><span><i
                                                    class="fa fa-tasks" aria-hidden="true"></i></span> My Appointments</a></li> -->
                                    <li>
                                        <a href="javascript:void(0)" (click)="goToSavedSearch()"><span><i class="fa fa-search" aria-hidden="true"></i></span> Saved Searches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/property/saved-look"><span><i class="fa fa-plus" aria-hidden="true"></i></span> Saved Looks</a>
                                    </li>
                                    <li>
                                        <a routerLink="/property/recents"><span><i class="fa fa-eye" aria-hidden="true"></i></span> Recently Seen</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="goToFavorites()"><span><i class="fa fa-heart" aria-hidden="true"></i></span> Favorites</a>
                                    </li>
                                    <li>
                                        <a routerLink="/buyer/builders"><span><i class="fa fa-list-alt" aria-hidden="true"></i></span> Builders List</a>
                                    </li>
                                    <li>
                                        <a routerLink="/buyer/neighbourhoods"><span><i class="fa fa-list" aria-hidden="true"></i></span> Neighborhoods</a>
                                    </li>
                                    <!-- <li><a href="javascript:void(0)"><span><i class="fa fa-heart"
                                                        aria-hidden="true"></i></span> Paid Features</a></li> -->
                                    <!-- <li><a href="javascript:void(0)" (click)="goToContactList()"><span><i
                                                    class="fa fa-list-ul" aria-hidden="true"></i></span> My Leads</a>
                                    </li> -->
                                    <!-- <li><a href="javascript:void(0)" routerLink="/agent/clients"><span><i
                                        class="fa fa-list-ul" aria-hidden="true"></i></span> My Clients</a>
                                    </li> -->
                                    <!-- <li><a href="javascript:void(0)" routerLink="/agent/invitees"><span><i
                                        class="fa fa-list-ul" aria-hidden="true"></i></span> My Invitees</a>
                                    </li> -->
                                    <li><a class="ux-pointer" (click)="logout()"><span><i class="fa fa-sign-out" aria-hidden="true"></i></span> Log Out</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mainNav">
    <div class="container" *ngIf="!isAdminLogin">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a *ngIf="isMobile && currentRoute != 'home'" class="back-button" (click)="back()"> <i class="fa fa-chevron-left" aria-hidden="true"></i> </a>
            <a class="navbar-brand" routerLink="{{homeLink}}"><img src="../../../assets/images/logo.png" /></a>
            
            <button class="navbar-toggler" type="button" (click)="onClick($event)" #navButtonCls data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" id="menu-toggle-button" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto ">
                    <li class="nav-item active" [ngClass]="{'active': currentRoute == 'home'}">
                        <a (click)="checkNavOpen()" class="nav-link" [routerLink]="homeLink">Home</a>
                    </li>
                    <li class="nav-item" [ngClass]="{'active': currentRoute == 'property/search'}">
                        <a (click)="checkNavOpen()" class="nav-link" routerLink="/property/search">Search</a>
                    </li>
                    <li class="nav-item" [ngClass]="{'active': currentRoute == 'property/shop-the-look-list'}">
                        <a class="nav-link" (click)="checkNavOpen()" routerLink="/property/shop-the-look-list">SHOP THE
                            LOOK</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" (click)="checkNavOpen()" href="https://newbuilt-blog.glohtesting.com/">BLOG</a>
                    </li> -->
                    <li class="nav-item" [ngClass]="{'active': currentRoute == 'contact'}">
                        <a class="nav-link" (click)="checkNavOpen()" routerLink="/contact">Contact</a>
                    </li>
<li class="d-block d-sm-none for-mobile-myaccount for-mobile-myaccountli">
    <div class="col-lg-6 col-md-6">
        <div class="toprightLogin">
            <ul *ngIf="!isAdminLogin">
                
                <li *ngIf="!user"><a (click)="openLoginTemplate()" class="ux-pointer"><img
                            src="../../../assets/images/login_icon.png" /> Login</a></li>
                <li *ngIf="!user"><a class="active" (click)="openSignupTemplate()"
                        class="ux-pointer"><img
                            src="../../../assets/images/create_account.png" /> Create an
                        account</a></li>
                <li *ngIf="user && !isAdminLogin" class="my-acc">
                    <a (click)="myAccountClink()" class="nav-link for-mobile-myaccount" data-toggle="dropdown" href="javascript:void(0)"
                        class="ux-pointer for-mobile-myaccounta"><span class="prof_pic"><img
                                src="{{user?.avatar?.thumb || '../../../assets/images/download.png'}}"
                                class="prof_img"></span>
                        My Account <i class="fa fa-angle-down ml-2" aria-hidden="true"></i></a>

                    <div
                        class="profileDropdown dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <ul *ngIf="role_id =='buyer'" (click)="checkNavOpen()">
                            <li><a href="javascript:void(0)"
                                    (click)="goToEditProfile()"><span><i class="fa fa-user"
                                            aria-hidden="true"></i></span> My Profile</a>
                            </li>
                            <!-- <li><a routerLink="/property/inbox"><span><i class="fa fa-envelope"
                                            aria-hidden="true"></i></span>My Inbox</a></li> -->
                            <li><a href="javascript:void(0)"
                                    (click)="goToSavedSearch()"><span><i class="fa fa-search"
                                            aria-hidden="true"></i></span> Saved
                                    Searches</a></li>
                            <li><a routerLink="/property/saved-look"><span><i class="fa fa-plus"
                                            aria-hidden="true"></i></span> Saved Looks</a></li>
                            <li><a routerLink="/property/recents"><span><i class="fa fa-eye"
                                            aria-hidden="true"></i></span> Recently Seen</a></li>
                            <!-- <li><a href="javascript:void(0)" (click)="goToCalendar()"><span><i
                                        class="fa fa-tasks"></i></span>My Task</a>
                        </li> -->
                            <li><a href="javascript:void(0)" (click)="goToFavorites()"><span><i
                                            class="fa fa-heart" aria-hidden="true"></i></span>
                                    Favorites</a>
                            </li>                                                    
                            <li *ngIf="isShowReview == 1"><a routerLink="/profile/reviews"><span><i class="fa fa-list-ul"
                                            aria-hidden="true"></i></span> My Reviews</a></li>
                            <li><a routerLink="/buyer/builders"><span><i class="fa fa-list-alt"
                                            aria-hidden="true"></i></span> Builders List</a>
                            </li>
                            <!-- <li><a routerLink="/buyer/agents"><span><i class="fa fa-th-list"
                                            aria-hidden="true"></i></span> Agent List</a></li> -->
                            <!-- <li><a routerLink="/buyer/shared-list"><span><i class="fa fa-th-list"
                                aria-hidden="true"></i></span> Shared Models</a></li> -->
                            <li><a routerLink="/buyer/neighbourhoods"><span><i
                                            class="fa fa-list" aria-hidden="true"></i></span>
                                    Neighborhoods</a></li>
                            <li><a class="ux-pointer" (click)="logout()"><span><i
                                            class="fa fa-sign-out"
                                            aria-hidden="true"></i></span> Log Out</a></li>
                        </ul>

                        <!-- this ul for builder -->

                        <ul *ngIf="role_id =='builder'" (click)="checkNavOpen()">
                            <li><a class="ux-pointer" href="javascript:void(0)"
                                    (click)="goToPropertyList()"><span><i class="fa fa-user"
                                            aria-hidden="true"></i></span> Model Listing</a>
                            </li>
                            <!-- <li><a href="javascript:void(0)"><span><i class="fa fa-envelope"
                                        aria-hidden="true"></i></span> Client Management</a></li> -->
                            <li *ngIf="isShowReview == 1"><a routerLink="/profile/reviews"><span><i class="fa fa-search"
                                            aria-hidden="true"></i></span> Model Reviews</a>
                            </li>
                            <li><a href="javascript:void(0)"
                                    (click)="goToBusinessProfile()"><span><i class="fa fa-plus"
                                            aria-hidden="true"></i></span> Business
                                    Profile</a></li>

                            <li><a href="javascript:void(0)"
                                    (click)="goToEditProfile()"><span><i class="fa fa-eye"
                                            aria-hidden="true"></i></span> My Profile</a></li>
                            <!-- <li><a href="javascript:void(0)" (click)="goToCalendar()"><span><i
                                            class="fa fa-tasks" aria-hidden="true"></i></span>
                                    My
                                    Tasks</a></li> -->
                            <!-- <li><a routerLink="/property/inbox"><span><i class="fa fa-envelope"
                                            aria-hidden="true"></i></span>My Inbox</a></li> -->
                            <!-- <li><a href="javascript:void(0)"><span><i class="fa fa-heart"
                                        aria-hidden="true"></i></span> Paid Features</a></li> -->
                            <!-- <li><a href="javascript:void(0)"
                                    (click)="goToContactList()"><span><i class="fa fa-list-ul"
                                            aria-hidden="true"></i></span> My Leads</a></li> -->
                            <li><a class="ux-pointer" href="javascript:void(0)"
                                    (click)="logout()"><span><i class="fa fa-sign-out"
                                            aria-hidden="true"></i></span> Log Out</a></li>
                        </ul>

                        <!-- for agent ul -->

                        <ul *ngIf="role_id=='agent'" (click)="checkNavOpen()">
                            <!-- <li><a (click)="goToPropertyList()"><span><i class="fa fa-user"
                                            aria-hidden="true"></i></span> Lead Models</a></li> -->
                            <!-- <li><a routerLink="/property/inbox"><span><i class="fa fa-envelope"
                                            aria-hidden="true"></i></span>My Inbox</a></li> -->
                            <li><a href="javascript:void(0)"
                                    (click)="goToBusinessProfile()"><span><i class="fa fa-plus"
                                            aria-hidden="true"></i></span> Business
                                    Profile</a></li>
                            <li><a href="javascript:void(0)"
                                    (click)="goToEditProfile()"><span><i class="fa fa-tasks"
                                            aria-hidden="true"></i></span> My profile</a></li>
                            <!-- <li><a href="javascript:void(0)" (click)="goToCalendar()"><span><i
                                            class="fa fa-eye" aria-hidden="true"></i></span> My
                                    Appointments</a></li> -->
                            <li><a href="javascript:void(0)"
                                (click)="goToSavedSearch()"><span><i class="fa fa-search"
                                        aria-hidden="true"></i></span> Saved
                                Searches</a></li>
                            <!-- <li><a href="javascript:void(0)"><span><i class="fa fa-heart"
                                        aria-hidden="true"></i></span> Paid Features</a></li> -->
                            <!-- <li><a href="javascript:void(0)"
                                    (click)="goToContactList()"><span><i class="fa fa-list-ul"
                                            aria-hidden="true"></i></span> My Leads</a></li> -->
                            <!-- <li><a href="javascript:void(0)" routerLink="/agent/clients"><span><i
                                class="fa fa-list-ul" aria-hidden="true"></i></span> My Clients</a>
                            </li> -->
                            <!-- <li><a href="javascript:void(0)" routerLink="/agent/invitees"><span><i
                                class="fa fa-list-ul" aria-hidden="true"></i></span> My Invitees</a>
                            </li> -->
                            <li><a href="javascript:void(0)" class="ux-pointer"
                                    (click)="logout()"><span><i class="fa fa-sign-out"
                                            aria-hidden="true"></i></span> Log Out</a></li>
                        </ul>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</li>

                </ul>


                <form class="form-inline my-2 my-lg-0">
                    <input type="text" [(ngModel)]="locations" name='location' ngx-google-places-autocomplete [options]="{
                            types: [],
                            componentRestrictions: { country: 'US' }
                            }" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event, 1)"
                        (blur)="onBlurAddress()" class="form-control" placeholder="" />
                    <!-- <input class="form-control" type="search" id="searchTextField" (keyup)="enterAddress()" placeholder="Search" aria-label="Search"> -->
                    <button class="btn navserach_btn my-2 my-sm-0" type="submit" (click)="getCurrentLocation()"
                        title="Click To Set Current Location"><img
                            src="../../../assets/images/search_icon.png" /></button>
                </form>
                <!-- <div class="d-sm-none builder-approved" *ngIf="builderNotApproved"><a class=" ">Account not approved by Admin, please contact</a></div> -->

                <div class="toploginbar d-block d-sm-none
                    ">
                    <div class="container">
                        <div class="row">
                           
                            <div class="col-lg-6 col-md-6">
                                <div class="topleftSocialicon">
                                    <ul>
                                        <li class="facebook" *ngIf="fbLink">
                                            <a [href]="fbLink" target="_blank"><i class="fa fa-facebook"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li class="printersr" *ngIf="pinterestLink">
                                            <a [href]="pinterestLink" target="_blank"><i class="fa fa-pinterest-p"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li class="instagram" *ngIf="instaLink">
                                            <a [href]="instaLink" target="_blank"><i class="fa fa-instagram"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li class="youtube" *ngIf="youtubeLink">
                                            <a [href]="youtubeLink" target="_blank"><i class="fa fa-youtube-play"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="builder" *ngIf="builderNotApproved"><div class="builder-approved ribbon-both d-sm-none" ><a class=" ">Account not approved by Admin, please contact</a></div></div>
        </nav>
    </div>
</div>

<notifier-container *ngIf="isMobile"></notifier-container>
<ng-template #customNotification let-notificationData="notification">
    <div  style="color: black;">
        <h4>New Notification</h4>
        {{ notificationData.message}}
        <!-- <p><a href="{{currentDetailsLink}}">Go To Property Details Page</a></p> -->
    </div>
</ng-template>


<!-- </div> -->


<!-- <h5>Landing</h5>
    <button (click)="openLoginTemplate()"></button>
    -->

<ng-template #loginTemplate>

    <!-- <div class="modal-header">
                <button type="button" class="close" (click)="modalRef.hide()">&times;</button>
            </div> -->
    <!-- <div class="modal-body"> -->
    <app-login (closeLoginModal)="closeLoginModal($event)"></app-login>
    <!-- </div> -->



</ng-template>
<ng-template #signupTemplate>
    <!-- <div class="modal-header">
            <button type="button" class="close" (click)="modalRef.hide()">&times;</button>
        </div> -->
    <!-- <div class="modal-body"> -->
    <app-signup (closeSignupModal)="closeSignup($event)" (cancelSignupModal)="cancelSignUp($event)"></app-signup>
    <!-- </div> -->
</ng-template>
<ng-template #socialSignupTemplate>
    <div class="modal-dialog new-sm-modal ">
        <div class="modal-content ux-margin-0 connt">
            <strong class="ml-3">Please provide your business name to <br>complete your registration as a
                {{userType}}</strong>
            <div class="modal-body">
                <div class="registermodal_ctrl registermodal_ctrl-new">

                    <div class="ux-business">
                        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">

                            <input type="text" formControlName="business_name" class="form-control mb-2"
                                [ngClass]="{ 'is-invalid': submitted && signupForm.controls.business_name.errors }"
                                placeholder="Enter Business Name" />
                            <div *ngIf="(submitted && signupForm.controls.business_name.invalid) || (!signupForm.get('business_name').value && invalidForm) || (signupForm.get('business_name').invalid && signupForm.get('business_name').touched)"
                                class="has-error"> Business Name is required
                            </div>
                            <button class="btn reg_loginbtn w-100" [disabled]="loading"><span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                        </form>


                    </div>


                </div>

            </div>

        </div>
    </div>
    <!-- <app-social-signup (closeSocialSignupModal)="closeSocialSignupModal($event)" ></app-social-signup> -->
</ng-template>
<ng-template #socialSignupAgentTemplate>
    <div class="modal-dialog new-sm-modal buyer-log">
        <div class="modal-content ux-margin-0 bg-none">
            <strong class="ml-3">Please provide these details to <br>complete your registartion as an
                Agent</strong>
            <div class="modal-body">
                <div class="registermodal_ctrl">

                    <div class="ux-business-1">
                        <form [formGroup]="signupAgentForm">
                            <ng-container *ngIf="agentStep1">
                                <input type="text" formControlName="business_name" class="form-control mb-2"
                                    [ngClass]="{ 'is-invalid': submittedAgent && signupAgentForm.controls.business_name.errors }"
                                    placeholder="Enter Business Name" />
                                <div *ngIf="(submittedAgent && signupAgentForm.controls.business_name.invalid) || (!signupAgentForm.get('business_name').value && invalidAgentForm) || (signupAgentForm.get('business_name').invalid && signupAgentForm.get('business_name').touched)"
                                    class="has-error"> Business Name is required
                                </div>
                                <input type="text" formControlName="license_number" class="form-control mb-2"
                                    [ngClass]="{ 'is-invalid': submittedAgent && signupAgentForm.controls.license_number.errors }"
                                    placeholder="Enter License Number" />
                                <div *ngIf="(submittedAgent && signupAgentForm.controls.license_number.invalid) || (!signupAgentForm.get('license_number').value && invalidAgentForm) || (signupAgentForm.get('license_number').invalid && signupAgentForm.get('license_number').touched)"
                                    class="has-error"> Business Name is required
                                </div>
                                <input type="text" formControlName="broker_name" class="form-control mb-2"
                                    [ngClass]="{ 'is-invalid': submittedAgent && signupAgentForm.controls.broker_name.errors }"
                                    placeholder="Enter Broker Name" />
                                <div *ngIf="(submittedAgent && signupAgentForm.controls.broker_name.invalid) || (!signupAgentForm.get('broker_name').value && invalidAgentForm) || (signupAgentForm.get('broker_name').invalid && signupAgentForm.get('broker_name').touched)"
                                    class="has-error"> Broker Name is required
                                </div>
                                <button class="btn reg_loginbtn w-100" (click)="toggleAgent('2')">Next</button>
                            </ng-container>
                            <ng-container *ngIf="agentStep2">
                                <div class="input-sec" *ngFor="let subList of subscriptionAgentList; index as i">
                                  <div class="input-inner"><input formControlName="agent_subscription_id" type="radio" id="{{'tier-'+i}}" [value]="subList.id">
                                    <div class="new-social"><label for="{{'tier-'+i}}">{{subList.name}}</label><strong>{{subList.price | currency}}</strong></div></div>
                                  <div>
                                    <p class="txt-left subsceri">{{subList.description}}
                                    </p>
                                  </div>
                                </div>
              
                                <!-- <div class="input-sec">
                                  <div class="input-inner"><input type="radio" id="tier-2" name="tier" value="tier2">
                                    <label for="tier-2">Tier 2</label>
                                  </div>
                                  <div>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used.<strong>$200.00</strong>
                                    </p>
                                  </div>
                                </div> -->
                                <button class="btn reg_loginbtn w-20 " (click)="toggleAgent('1')">Back</button>
                                <!-- <button class="btn reg_loginbtn w-50" [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Payment</button> -->
                                <button class="btn reg_loginbtn w-50 ml-2" (click)="onAgentSubmit()">Pay</button>
                                <!-- <button class="btn reg_loginbtn w-100" [disabled]="loadingAgent"><span *ngIf="loadingAgent"
                                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button> -->
                              </ng-container>
                            
                        </form>


                    </div>


                </div>

            </div>

        </div>
    </div>
    <!-- <app-social-signup (closeSocialSignupModal)="closeSocialSignupModal($event)" ></app-social-signup> -->
</ng-template>
<ng-template #paymentTemplate>

    <div class="new-modal-sec">
      <div class="modal-header">
        <div class="am-center"><h2 class="payment-title">Payment for {{tierName}} </h2>
            <h2 class="payment-title"><span class="amount-title">(Amount: {{tierPrice | currency}})</span></h2>
        </div>
            <!-- <h3 class="payment-sub-title">(Amount: ${{tierPrice}})</h3> -->
        <button type="button" class="close" (click)="paymentModalClose()">&times;</button>
      </div>
      <div class="modal-body">
        <app-payment [emailAgent]="agentEmail" [tier]="tierDetails" [pageType]="'web-header'" (saved)="savePaymentModal($event)"></app-payment>
      </div>
    </div>
  
  
  
  </ng-template>
<!-- 
  <script>
      $(document).ready(function () {
    $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("navbar-collapse in");
        if (_opened === true && !clickover.hasClass("navbar-toggle")) {
            $("button.navbar-toggle").click();
        }
    });
});
  </script> -->