<form [formGroup]="subscriptionForm" class="px-2 pt-2">
    <div class="row">
        <div class="col-lg-12 col-md-12" *ngFor="let subList of subscriptionBuilderList; index as i">
            <div class="input-sec go-pre-sec " > 
                <div class="inputGroup">   
                    <div class="input-inner subs-input busi-pro "><div >
                    <input formControlName="subscription_id" type="radio" id="{{'tier-'+i}}" [value]="subList.id">
                    <label for="{{'tier-'+i}}">{{subList.name}}</label>
                </div> 
                <p class="edit-profile-price"><strong>{{subList.price | currency}}</strong></p>
            </div>
        </div>
        <div class="pay-list-sub">
            <p class="subsceri">{{subList.description}}</p>
        </div>
    </div> 
    </div>
        <div class="col-lg-12 col-md-12">
            <p class="buildnotice">* If you would rather be invoiced, please contact <a href="mailto:info@newbuilt.com" target="_blank" class="getintuch_email">info@newbuilt.com</a></p>
            <button class="mt-1 btn pl-4 pr-4 reg_loginbtn1" (click)="changeSubscriptionPlan()">Proceed</button>
        </div>
    </div>
</form>
