import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { CommonStorageService } from 'src/app/services/common-storage.service'
import { LocalVars, Regex } from '../../_constants/others.constants';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';


@Component({
  selector: 'app-claim-building',
  templateUrl: './claim-building.component.html',
  styleUrls: ['./claim-building.component.css']
})
export class ClaimBuildingComponent implements OnInit {
  @Input('propertyDetails') set setUserDetails(val) {
    if(val) {
      this.propertyDetails = val;
      if(this.propertyDetails) {
        this.getClaimUserDetails(this.propertyDetails)
      }
    }
  } 
  // @Input('show') show : any = null;
  @Output('closed') closed: EventEmitter<any> = new EventEmitter;
  @Output('submittedClaim') submit: EventEmitter<any> = new EventEmitter;

  addForm : FormGroup;
  submitted : boolean = false;
  public submittedClaim : boolean = false; 
  loading : boolean = false
  public invalidForm = false;
  public propertyDetails : any = null;

  constructor(
    private toaster: ToastrService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public titleService: TitleService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
    private formBuilder: FormBuilder,
    private propertyService : PropertyService,
  ) { }

  ngOnInit(): void {
    // console.log(this.propertyDetails, 'propertyDetails')
    this.addForm = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email)]),
      phone: new FormControl('', [Validators.required, Validators.pattern(Regex.phone)]),
    });
  }

  closeModal(){
    this.closed.emit(null);
    this.submit.emit(this.submittedClaim);
  }

  // claimBuilding(){    
  //   this.submitted = true;  
  //   let params = this.addForm.getRawValue();
  //   params['property_id'] = this.propertyDetails.id;
  //   params['form_skipped'] = 0;
  //   if (this.addForm.valid){
  //     this.loader.show();
  //     this.loading = true;
  //     this.propertyService.saveClaimProperty(params)
  //     .then(r => {
  //       this.loading = false;
  //       this.loader.hide();
  //       this.closeModal();
  //       this.submittedClaim = true;
  //       this.submit.emit(this.submittedClaim);
  //       this.toaster.success('Property claimed successfully', 'Success')
  //     }).catch(err => {
  //       this.loader.hide();
  //       this.loading = false;
  //       if(err.status == 400) {
  //         if(err.error.data.errors.email[0]) {
  //           this.toaster.error(err.error.data.errors.email[0], 'Oops')
  //         } else {
  //           this.toaster.error(err.error.message, 'Oops')
  //         }
					
	// 			} else if(err.message){
	// 				this.toaster.error(err.message, 'Oops')
	// 			} else {
	// 				this.toaster.error('Something went wrong!', 'Oops')
	// 			}

  //     })

  //   } else {
  //     this.invalidForm = true;
  //   }

  // }

  getClaimUserDetails(propertyDetails) {
    let params = {};
    params['property_id'] = propertyDetails.id;
      this.loader.show();
      this.propertyService.showUserClaimDetails(params)
      .then(r => {
        this.loader.hide();
        // console.log('check-details', r)
        if(r && r.data) {
          let userDetails = r.data[0];
          if(this.addForm) {
            this.addForm.patchValue({
              // state : this.state_code
              first_name: userDetails ? userDetails.first_name : '',
              last_name: userDetails ? userDetails.last_name : '',
              email: userDetails ? userDetails.email : '',
              phone: userDetails ? userDetails.phone : ''
            })
          }
        }
        // this.toaster.success('Property claimed successfully', 'Success')
      }).catch(err => {
        this.loader.hide()
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }

  claimBuilding(form: FormGroup){ 
    const formVals = this.addForm.getRawValue();
    const params = {
			...formVals
		}
    params['property_id'] = this.propertyDetails.id;
    console.log('params', params)
    // this.addForm.clearValidators();
    // this.addForm.updateValueAndValidity();
    // if (this.addForm.valid){
      this.loading = true;
      this.loader.show();
      this.propertyService.updateClaimContactProperty(params)
      .then(r => {
        this.loading = false;
        this.loader.hide();
        this.closeModal();
        this.submittedClaim = true;
        this.submit.emit(this.submittedClaim);
        this.toaster.success('Changes Saved Successfully', 'Success')
      }).catch(err => {
        this.loader.hide();
        this.loading = false;
        if(err.status == 400) {
          if(err.error.data.errors.email[0]) {
            this.toaster.error(err.error.data.errors.email[0], 'Oops')
          } else {
            this.toaster.error(err.error.message, 'Oops')
          }
					
				} else if(err.message){
					this.toaster.error(err.message, 'Oops')
				} else {
					this.toaster.error('Something went wrong!', 'Oops')
				}

      })

    // } else {
    //   this.invalidForm = true;
    // }

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    else {
      this.invalidForm = false;
      this.submitted = true;
    }
  }


}
