<div>
  <h5 class="heading-new font-bold">See Something Missing?</h5>
  <form [formGroup]="reportForm" (ngSubmit)="reportOfProperty()">
    <div class="form-group">
      <label for="reportPropertyComments">Please report missing or erroneous model info</label>
      <textarea formControlName="reportPropertyComments" name="reportPropertyComments" id="reportPropertyComments" rows="4" class="form-control h-auto"></textarea>
      <!-- <div *ngIf="reportFormControl.reportPropertyComments.hasError('required')">
        Please enter a comment.
      </div> -->
    </div>
    <button type="submit" class="btn reportpropertybtn w-100" [disabled]="!reportForm.valid">Report</button>
  </form>
</div>
