import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Regex, LocalVars } from '../../_constants/others.constants';
import * as moment from 'moment';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { CommonStorageService } from 'src/app/services/common-storage.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.css']
})
export class ContactCardComponent implements OnInit {

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  // @Input('propertyDetails') set setValues(val) {
  //   if(val) {
  //     this.propertyDetails = val;
  //     if(this.propertyDetails.claim_status != 1) {
  //       this.getLocalAgents(this.propertyDetails.id);
  //     }
  //   }
  // }
  @Input('uid') set setValues(val) {
    if (val) {
      this.uid = val;
      setTimeout(() => {
        this.getPropertyDetails();
      }, 500);
    }
  }


  @ViewChild('contactUsTemplate', { static: true }) contactUsTemplate: TemplateRef<any>;
  @ViewChild('claimBuildingTemplate', { static: true }) claimBuildingTemplate: TemplateRef<any>;
  @ViewChild('subscriptionTemplate', { static: true }) subscriptionTemplate: TemplateRef<any>;
  @ViewChild('paymentTemplate', { static: true }) paymentTemplate: TemplateRef<any>;
  @ViewChild('savedCardTemplate', { static: true }) savedCardTemplate: TemplateRef<any>;

  public uid: string;
  public localAgentDetails: any = {};
  public leftAgentDetails: any = {};
  public rightAgentDetails: any = {};
  public propertyDetails: any;
  public submitted = false;
  public contactForm: FormGroup;
  public invalidForm: boolean = false;
  public loading: boolean = false;
  public isShowSchedule: boolean = false;
  public loggedIn: boolean = false;
  public show_more_btn: boolean = false;
  public modalRef: BsModalRef;
  public user: any;
  public claimSubmit: boolean = false;
  public claimBtnText: string = '';
  public propertyId: any;
  public dateConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-green',
    rangeInputFormat: 'MM/DD/YYYY',
    minDate: new Date(),
    showWeekNumbers: false
  };
  public today: Date = new Date();
  public leftCard: boolean = true;
  public rightCard: boolean = false;
  public agentOpen: boolean = false;
  public letf_agent_id: string;
  public subscriptionForm: FormGroup;
  public modalPaymentRef: BsModalRef;
  public modalCardRef: BsModalRef;
  public subscriptionRef: BsModalRef;
  public tierDetails: any;
  public tierName: any;
  public tierPrice: any;
  public builderSubscription: any = {};
  public checkBuilderSubscription: boolean = false;
  public cardList: Array<any> = [];
  public loadingCard: boolean = true;
  public noPreferredAgent: boolean = true;
  public isClaimedLeft: boolean = false;
  public isMobile: boolean = false;
  public direction: any;
  public firstAppear: Boolean = false;

  constructor(
    private loader: LoaderService,
    private propertyService: PropertyService,
    private toaster: ToastrService,
    private modalService: BsModalService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
    private router: Router,
    public userService: UserService,
    private formBuilder: FormBuilder
  ) {
    this.contactForm = this.formBuilder.group({
      email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(Regex.email)]),
      name: new FormControl({ value: '', disabled: false }, [Validators.required]),
      phone: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(Regex.phone)]),
      message: new FormControl('', []),
      scheduled_time: new FormControl('', []),
      scheduled_date: new FormControl('', []),
      isChecked: new FormControl(false, []),
      agent_name: new FormControl('', [])
    });


    this.subscriptionForm = new FormGroup({
      subscription_id: new FormControl('', [Validators.required])
    })


    this.firstAppear = false;

    let user = this.commonStorage.getDetailsItem(LocalVars.USER);
    if (user) {
      this.user = user;
      this.builderSubscription = user.builder_subscription;
      // console.log('p', this.user);
      if (user.preferred_agent_id == 0) {
        this.noPreferredAgent = true;
      }
      if (user.preferred_agent_id != 0) {
        this.noPreferredAgent = false;
      }
      if (this.builderSubscription && this.builderSubscription.subscription_plan && this.builderSubscription.subscription_plan.type == 1) {
        this.checkBuilderSubscription = true;
      }
    }
    else {
      this.noPreferredAgent = true;
    }
  }

  ngOnInit(): void {
    this.detectMob()
  }

  getPropertyDetails() {
    let user = this.commonStorage.getDetailsItem(LocalVars.USER);
    // console.log('property-user', user)
    let isLogin = user ? true : false;
    this.loader.show();
    this.propertyService.fetchDetails({}, this.uid, isLogin)
      .then(response => {
        this.setDetails(response)

        this.loader.hide()
      }).catch(err => {
        this.loader.hide()
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }

  detectMob() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  // getLocalAgents(id) {
  //   this.loader.show();
  //   let params = {
  //     property_id: id
  //   }
  //   this.propertyService.getLocalAgent(params)
  //     .then(res => {
  //       this.loader.hide()
  //       if (res && res.data) {
  //         this.localAgentDetails = res.data[0];
  //       }
  //     }).catch(err => {
  //       this.loader.hide()
  //       if (err.status == 400) {
  //         this.toaster.error(err.error.message, 'Oops')

  //       } else if (err.message) {
  //         this.toaster.error(err.message, 'Oops')
  //       } else {
  //         this.toaster.error('Something went wrong!', 'Oops')
  //       }

  //   })
  // }

  setDetails(response) {
    this.propertyDetails = response['data']['details'];
    this.propertyId = this.propertyDetails.id;
    if (this.user) {
      this.getLeftAgentDetailsWithAuth(this.propertyId);
      // this.getRightAgentDetailsWithAuth(this.propertyId);
    }
    else {
      this.getLeftAgentDetailsWithoutAuth(this.propertyId);
      // this.getRightAgentDetailsWithoutAuth(this.propertyId);
    }
    // this.detailsLink = environment.SITE_URL+'/property/details/'+btoa(this.propertyDetails.uid);
    // if(this.propertyDetails.claim_status != 1) {
    //   this.getLocalAgents(this.propertyDetails.id);
    // }
    //
    this.claimBtnText = this.propertyDetails.claim_status == 0 ? 'Claim Property' : (this.propertyDetails.claim_status == 3 ? 'Property Claimed' : '')
    this.contactForm.controls['message'].setValue('I am interested in The' + ' ' + this.propertyDetails.model_name + ' (' + this.propertyDetails.model_id + ')');
    this.propertyId = response['data']['details']['id']
    if (this.propertyDetails.builder) {
      this.propertyDetails['builder_name'] = this.propertyDetails.builder.first_name.charAt(0).toUpperCase() + this.propertyDetails.builder.first_name.slice(1) + ' ' + this.propertyDetails.builder.last_name.charAt(0).toUpperCase() + this.propertyDetails.builder.last_name.slice(1);

    }
  }


  private openModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
    this.modalRef = this.modalService.show(template, options);
  }

  openContactModal() {
    this.isShowSchedule = false;
    this.contactForm.controls['message'].setValue('I am interested in The' + ' ' + this.propertyDetails.model_name + ' (' + this.propertyDetails.model_id + ')');
    this.contactForm.patchValue({ 'scheduled_time': { hour: 12, minute: 30 } });
    if (this.user) {
      this.loggedIn = true;
      let name = this.user.first_name + ' ' + this.user.last_name;
      this.contactForm.controls['email'].setValue(this.user.email);
      this.contactForm.controls['name'].setValue(name);
      this.contactForm.controls['phone'].setValue(this.user.phone);
    }
    this.openModal(this.contactUsTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }

  openClaimModal() {
    // this.propertyDetails = property;

    this.openModal(this.claimBuildingTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  }

  toggleSchedule() {
    this.isShowSchedule = !this.isShowSchedule;
  }

  public closeContactModal() {
    this.modalRef.hide();
  }

  public closeClaimModal() {
    this.modalRef.hide();
  }

  claimSubmitCheck(event) {
    if (event) {
      this.claimSubmit = true;
    }
  }

  showMoreClick(e) {
    e.stopPropagation();
    this.show_more_btn = true;

  }

  showLessClick(e) {
    e.stopPropagation();
    this.show_more_btn = false
  }

  onSubmit(form) {

    this.submitted = true;
    if (this.contactForm.invalid) {
      this.toaster.error('Please fill out all mandatory fields !', 'Sorry')
      return
    }
    if (this.contactForm.valid) {
      this.invalidForm = false;
      this.loading = true;
      let assignedTo: string;
      if (this.leftCard && !this.rightCard) {
        // console.log('left-card', this.propertyDetails.claim_status, this.leftAgentDetails)
        this.localAgentDetails.id = this.leftAgentDetails.id;
        assignedTo = this.propertyDetails.claim_status != 1 ? this.localAgentDetails.id : (this.propertyDetails && this.propertyDetails.builder_id);
      }
      if (!this.leftCard && this.rightCard) {
        // console.log('right-card',  this.rightAgentDetails)
        this.localAgentDetails.id = this.rightAgentDetails.id;
        assignedTo = this.localAgentDetails.id;
      }

      const params = {
        property_id: this.propertyDetails.id,
        builder_id: this.propertyDetails.builder_id,
        assigned_to: assignedTo ? assignedTo : 0,
        name: this.contactForm.value.name,
        phone: form.value.phone,
        email: this.contactForm.value.email,
        message: form.value.message,
      }
      if (this.agentOpen) {
        params['agent_name'] = this.contactForm.value.agent_name
      }
      if (form.value.scheduled_date != '') {
        let time = ('0' + this.contactForm.value.scheduled_time.hour).slice(-2) + ':' + ('0' + this.contactForm.value.scheduled_time.minute).slice(-2);
        let schedule_date = moment(form.value.scheduled_date).format('YYYY-MM-DD');
        params['scheduled_time'] = time;
        params['scheduled_date'] = schedule_date;
      }
      this.loader.show();
      let isLogin = this.user ? true : false;
      console.log('params', params, isLogin)
      this.propertyService.addContact(params, isLogin)
        .then(r => {
          this.loader.hide();
          if (r['data']) {

            this.contactForm.reset();
            this.contactForm.markAsPristine();
            this.submitted = false;
            this.toaster.success('Thank you for your request, We will contact you shortly.', 'Success')
            this.closeContactModal();

          } else {
            this.toaster.error(r.message, 'Oops')
          }
          this.loading = false;
        }).catch(err => {
          this.loader.hide();
          this.loading = false;
          if (err.status == 400) {
            if (err.error.data.errors.assigned_to[0]) {
              this.toaster.error('Property does not have any representative, Please contact admin', 'Sorry')
            } else {
              this.toaster.error(err.error.message, 'Oops')
            }

          } else if (err.message) {
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }

        })

    } else {
      this.invalidForm = true;
    }

  }

  gotoBuilderPropertyList(uid) {
    this.commonStorage.setItem(LocalVars.BUILDER_ID, btoa(JSON.stringify(this.propertyDetails.builder.id)));
    this.router.navigate(['/property/builder/' + btoa(uid)]);
  }

  onChangeDate() {
    // console.log(this.contactForm.get('scheduled_date').value, 'val')
    // this.contactForm.patchValue({
    //   scheduled_date : moment(this.contactForm.get('scheduled_date').value).format('MM/DD/YYYY')
    // })
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    else {
      this.invalidForm = false;
      this.submitted = true;
    }
  }

  toggleLeft() {
    this.leftCard = true;
    this.rightCard = false
  }

  onSwipe(event, type) {
    const x =
      Math.abs(
        event.deltaX) > 40 ? (event.deltaX > 0 ? "Right" : "Left") : "";
    console.log('x-val', x)
    if(type == 0 && x == "Left") {
      console.log('Left')
      this.toggleRight();
    }
    if(type == 1 && x == "Right") {
      console.log('Right')
      this.toggleLeft();
    }
    if(type == 1 && x == "Left" && !this.isClaimedLeft && this.noPreferredAgent) {
      console.log('Left')
      this.toggleRight();
    }
}

toggleRight() {
  this.firstAppear = true;
  this.leftCard = false;
  this.rightCard = true;
  // console.log(this.rightAgentDetails)
  if (!this.isClaimedLeft && this.noPreferredAgent && Object.keys(this.rightAgentDetails).length != 0) {
    this.leftAgentDetails = this.rightAgentDetails;
    this.letf_agent_id = this.leftAgentDetails.id;
  }
  if (this.user) {
    this.getRightAgentDetailsWithAuth(this.propertyId)
  }
  else {
    this.getRightAgentDetailsWithoutAuth(this.propertyId)
  }
}

getLeftAgentDetailsWithAuth(id) {
  this.loader.show();
  let params = {
    property_id: id
  }
  this.loadingCard = true;
  this.propertyService.getLeftAgentWithAuth(params)
    .then(res => {
      this.loader.hide()
      this.loadingCard = false;
      if (res && res.data) {
        this.leftAgentDetails = res.data[0];
        if (this.leftAgentDetails && this.leftAgentDetails.claim_status == 1) {
          this.isClaimedLeft = true;
        }
        if (this.leftAgentDetails && this.leftAgentDetails.claim_status != 1) {
          this.isClaimedLeft = false;
        }
        this.letf_agent_id = this.leftAgentDetails.id;
      }
    }).catch(err => {
      this.loader.hide();
      this.loadingCard = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
}

getLeftAgentDetailsWithoutAuth(id) {
  this.loader.show();
  let params = {
    property_id: id
  }
  this.loadingCard = true;
  this.propertyService.getLeftAgentWithoutAuth(params)
    .then(res => {
      this.loader.hide();
      this.loadingCard = false;
      if (res && res.data) {
        this.leftAgentDetails = res.data[0];
        if (this.leftAgentDetails && this.leftAgentDetails.claim_status == 1) {
          this.isClaimedLeft = true;
        }
        if (this.leftAgentDetails && this.leftAgentDetails.claim_status != 1) {
          this.isClaimedLeft = false;
        }
        this.letf_agent_id = this.leftAgentDetails.id;
      }
    }).catch(err => {
      this.loader.hide()
      this.loadingCard = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
}

getRightAgentDetailsWithAuth(id) {
  // this.loader.show();
  let params = {
    property_id: id,
    letf_agent_id: this.letf_agent_id
  }
  this.loadingCard = true;
  this.propertyService.getRightAgentWithAuth(params)
    .then(res => {
      // this.loader.hide();
      this.loadingCard = false;
      if (res && res.data) {
        this.rightAgentDetails = res.data[0];
      }
    }).catch(err => {
      // this.loader.hide();
      this.loadingCard = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
}

getRightAgentDetailsWithoutAuth(id) {
  // this.loader.show();
  let params = {
    property_id: id,
    letf_agent_id: this.letf_agent_id
  }
  this.loadingCard = true;
  this.propertyService.getRightAgentWithoutAuth(params)
    .then(res => {
      // this.loader.hide();
      this.loadingCard = false;
      if (res && res.data) {
        this.rightAgentDetails = res.data[0];
      }
    }).catch(err => {
      // this.loader.hide();
      this.loadingCard = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
}

  private validateBlankValue(control: FormControl): any {
  if (this.contactForm) {
    return control.value.trim() === '' ? { emptyValue: true } : null;
  }
}

  private setValidators(group, name) {
  group.get(name).setValidators([Validators.required]);
  group.get(name).updateValueAndValidity();
}

	private clearValidators(group, name) {
  group.get(name).clearValidators();
  group.get(name).updateValueAndValidity();
}

checkBuilderContact(value) {
  if (value) {
    this.agentOpen = true;
    this.setValidators(this.contactForm, 'agent_name')
  }
  else {
    this.agentOpen = false;
    this.clearValidators(this.contactForm, 'agent_name')
  }
}


  
  private openPaymentModal(template: TemplateRef < any >, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
  // console.log('open-payment-modal')
  this.modalPaymentRef = this.modalService.show(template, options);
}

  private openCardModal(template: TemplateRef < any >, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
  // console.log('open-card-payment-modal')
  this.modalCardRef = this.modalService.show(template, options);
}

  private openSubscriptionModal(template: TemplateRef < any >, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
  // console.log('open-card-payment-modal')
  this.subscriptionRef = this.modalService.show(template, options);
}

openPaymentTemplate() {
  this.openPaymentModal(this.paymentTemplate, { class: 'modal-md', ignoreBackdropClick: true });
}

openCardModalTemplate() {
  this.openCardModal(this.savedCardTemplate, { class: 'modal-md', ignoreBackdropClick: true });
}

openSubscriptionModalTemplate() {
  this.openSubscriptionModal(this.subscriptionTemplate, { class: 'modal-md', ignoreBackdropClick: true });
}

paymentModalClose() {
  this.modalPaymentRef.hide();
}

savedCardModalClose() {
  this.modalCardRef.hide();
}

subscriptionModalClose() {
  this.subscriptionRef.hide()
}


goPremium() {
  if (!this.checkBuilderSubscription) {
    this.openSubscriptionModalTemplate();
  }
}

subscriptionValue(value) {
  console.log('val', value)
  if (value) {
    this.subscriptionRef.hide();
    this.tierDetails = value[0];
    this.tierName = this.tierDetails.name;
    this.tierPrice = this.tierDetails.price;
    this.subscriptionForm.get('subscription_id').setValue(this.tierDetails.id);
    this.getAllCard();
  }
}

  public getAllCard() {
  // this.loader.show()
  this.userService.getCardList()
    .then(res => {
      // this.loader.hide();
      this.cardList = res.data[0].data;
      console.log('card-list', this.cardList)
      if (this.cardList.length == 0) {
        this.openPaymentTemplate()
      }
      if (this.cardList.length > 0) {
        this.openCardModalTemplate();
      }
    }).catch(err => {
      // this.loader.hide();
      // this.loading = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
}

savePaymentModal(value) {
  if (value) {
    this.paymentModalClose();
    const params = this.subscriptionForm.getRawValue();
    params['card_id'] = value['id'];
    console.log('payment-params', params)
    this.loader.show();
    this.userService.submitBuilderSubscription(params)
      .then(r => {
        this.loader.hide();
        if (r['data']) {
          this.toaster.success(r.message, 'Success');
          this.getUserDetails();
          this.getLeftAgentDetailsWithAuth(this.propertyId)
        } else {
          this.toaster.error(r.message, 'Oops')
        }
        this.loading = false;
      }).catch(err => {
        this.loader.hide();
        this.loading = false;
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }
}

newCardCheck(val) {
  // console.log('newCardCheck', val)
  if (val) {
    this.savedCardModalClose();
    this.openPaymentTemplate();
  }
}

savedCardCheck(value) {
  // console.log('savedCardCheck', value);
  if (value) {
    this.savedCardModalClose();
    const params = this.subscriptionForm.getRawValue();
    params['card_id'] = value;
    console.log('saved-params', params)
    this.loader.show();
    this.userService.submitBuilderSubscription(params)
      .then(r => {
        this.loader.hide();
        if (r['data']) {
          this.toaster.success(r.message, 'Success');
          this.getUserDetails();
        } else {
          this.toaster.error(r.message, 'Oops')
        }
        this.loading = false;
      }).catch(err => {
        this.loader.hide();
        this.loading = false;
        if (err.status == 400) {
          this.toaster.error(err.error.message, 'Oops')

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }

      })
  }
}

getUserDetails() {
  this.userService.me()
    .then(r => {
      if (r['data'] && r['data']['user']) {

        this.builderSubscription = r['data']['user'].builder_subscription;
        if (this.builderSubscription && this.builderSubscription.subscription_plan && this.builderSubscription.subscription_plan.type == 1) {
          this.checkBuilderSubscription = true;
        }
        this.commonStorage.setDetailsItem(LocalVars.USER, r['data']['user']);
      } else {
        this.toaster.error('User details not found!', 'User fetch')
      }
    }).catch(err => {
      // console.log(err)
      const message = err && err.error && err.error.message || "Something went wrong!";
      this.toaster.error(message, 'Login Error')
    })
}


}
