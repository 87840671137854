import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { HttpHeaders } from '@angular/common/http';
import { CommonStorageService } from 'src/app/services/common-storage.service';
import { LocalVars } from '../_constants/others.constants';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  stripe: Stripe;

  constructor(public commonStorage: CommonStorageService) {
    this.loadStripe();
  }

  async loadStripe() {
    this.stripe = await loadStripe('pk_test_yq4HDWUf2rhjNhHYX86aWgSG00PSSlaO2z');
  }

  async createPaymentIntent(payment_api:any, subscription_id: number, token_id: any, subscription_type:any) {
    console.log(subscription_type);
    
    const authToken = atob(this.commonStorage.getItem(LocalVars.TOKEN_VAR)); // Replace with your actual auth token
    let response;
    if(subscription_type!=""){
      response = await fetch('https://newbuild-dev.dreamztesting.com/api/v1/'+payment_api, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ subscription_id, token_id, subscription_type })
      });
    }else{
      response = await fetch('https://newbuild-dev.dreamztesting.com/api/v1/'+payment_api, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ subscription_id, token_id })
      });
    }
    
    if (!response.ok) {
        throw new Error('Failed to create payment intent');
    }
    
    const responseData = await response.json();    
    return responseData.message;
  }

  async createToken(cardElement: any) {
    const { token, error } = await this.stripe.createToken(cardElement);
    if (error) {
      console.error(error);
    } else {
      return token;
    }
  }
}
