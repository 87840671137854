import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoaderComponent } from './_plugins/loader/loader.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';

 
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LayoutModule } from './layout/layout.module';
import { CookieStorageService } from './services/cookie-storage.service';
import { BaseService } from './services/base.service';

import { ToastrModule } from 'ngx-toastr';
import { LoaderService } from './services/loader.service';
import { CommonService } from './services/common.service';
import { TitleService } from './services/title.service';

import { AuthGuard, LoginGuard, ResetPaswordPageGuard,
  AdvanceSearchDeactivateGuard ,BuilderGuard,  BuyerGuard, 
  AgentGuard, BuilderAgentGuard, BuyerAgentGuard, PropertyDetailsDeactivateGuard,
  STLDisplayDeactivateGuard, PropertyListDeactivateGuard, AdminSTLDeactivateGuard} from './_guards/auth.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentModule } from './_components/component.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PipesModule } from './_pipes/pipes.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  //AmazonLoginProvider,
} from 'angularx-social-login';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GoogleMapsModule } from '@angular/google-maps'
import { NgxSelectModule } from 'ngx-select-ex';
import { RatingModule } from 'ngx-bootstrap/rating';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha"

import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import  timeGrigPlugin from '@fullcalendar/timegrid';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgmCoreModule } from '@agm/core';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from 'ngx-mask'

// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NotificationService } from './services/notification.service';

import{AngularFireMessagingModule}from'@angular/fire/messaging';
import { AsyncPipe } from '@angular/common';
import { GoogleAnalyticsServiceService } from './services/google-analytics-service.service';
import { SharedModule } from './shared/shared.module';
import { HammerService } from './services/hammer.service';
import { CommonStorageService } from './services/common-storage.service';
// import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';




FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
   timeGrigPlugin
]);


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AngularFireMessagingModule,
    /* AgmCoreModule.forRoot({
      apiKey: 'AIzaSyApcUn_nzDAGRdFmaTGtQGCD23xOxyYD78'
    }), */
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC9ipC0GTpR_svrl4EpWEVprk2Ijbl5oWs'
    }),
    PdfViewerModule,
    ComponentModule,
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    ToastrModule.forRoot({ closeButton: true, preventDuplicates: true, resetTimeoutOnDuplicate: true }),
    ModalModule.forRoot(),
    GooglePlaceModule,
    BrowserModule,
    GoogleMapsModule,
    SocialLoginModule,
    NgxDropzoneModule,
    NgxSelectModule,
    RatingModule.forRoot(),
    NgxMaskModule.forRoot(),
    FullCalendarModule,
    FontAwesomeModule,
    NgxCurrencyModule,
    PipesModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    SharedModule,
    RecaptchaV3Module,
    FormsModule,
    ReactiveFormsModule,
    // NgxPageScrollCoreModule
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    ResetPaswordPageGuard,
    AdvanceSearchDeactivateGuard,
    PropertyDetailsDeactivateGuard,
    STLDisplayDeactivateGuard,
    PropertyListDeactivateGuard,
    BuilderGuard, 
    BuyerGuard,
    AgentGuard,
    BuilderAgentGuard,
    BuyerAgentGuard,
    AdminSTLDeactivateGuard,
    CookieStorageService,
    CommonStorageService,
    BaseService,
    LoaderService,
    CommonService,
    TitleService,
    NotificationService,
    AsyncPipe,
    GoogleAnalyticsServiceService,
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LesxdEaAAAAACYrFOvqn3AS59BZ1t7SlZYcPn3t" },
    /* { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfW1tEaAAAAAPJ8UKJYS9RfvIbh3fijh-5kAvbO" }, */
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LemAkcpAAAAANNwtgPn8Hr-efAzdr5wyhFxg1W1" },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '964632165213-hkiqt11k45b965h2c8e6gq549jkcl6n2.apps.googleusercontent.com'
              //'312218382806-1h26dr6th2sve6bhl913imch85hfiucl.apps.googleusercontent.com'
              //'451887516947-h4gpgl0prqa1pu4g9d6tc9m7p882q5nm.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('275653867489074'),
            // provider: new FacebookLoginProvider('751830892041735'),
          },
          //{
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider(
          //     'clientId'
          //   ),
          // },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerService,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
