import { Component, OnInit, Output, EventEmitter, Input  } from '@angular/core';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import * as $ from 'jquery';
import { GoogleAnalyticsServiceService } from 'src/app/services/google-analytics-service.service';
@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.css']
})
export class ViewFileComponent implements OnInit {
  @Output('closeViewModal') closeViewModal: EventEmitter<any> = new EventEmitter; 
  @Output('getAll') getAll: EventEmitter<any> = new EventEmitter; 
  @Input('user') user : any = null;
  @Input('fileDetails') fileDetails : any = null
  mapConfig = {
    fade: true,
    "alwaysOn": true,
    "neverOn": false,
    "fill": true,
    "fillColor": "#FF0000",
    "fillOpacity": 0.4,
    "stroke": true,
    "strokeColor": "#4d0ec0",
    "strokeOpacity": 1,
    "strokeWidth": 1,
    "shadow": true,
    "shadowColor": "#000000",
    "shadowOpacity": 0.8,
    "shadowRadius": 10
  }
  currentImageMap: any = '';
  currentMapCoords: Array<any> = []; 
  responsiveXratio: any;
  responsiveYratio: any;
  delay: string = '15000';
  docWidth: any;
  docHeight: any;
  xAdditional: any;
  yAdditional: any;
  isShowMap : boolean = false;
  public isMobile : boolean = false;
  constructor(
    private propertyService: PropertyService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private googleAnalyticsService: GoogleAnalyticsServiceService
  ) { }

  ngOnInit(): void {
    console.log(this.fileDetails,'fileDetails')
    this.showMapImage();
    this.detectMob();
  }

  closeModal(){
    this.isShowMap = false;
    // document.querySelector("body").style.overflow = 'visible';
    this.closeViewModal.emit(null)
    window.removeEventListener("touchmove", this.handleTouchMove);
  }

  showMapImage() {
    // document.querySelector("body").style.overflow = 'hidden';
    this.isShowMap = true;
    let row = this.fileDetails;
    if (row && row.shop_the_look && row.shop_the_look.length) {
      this.currentMapCoords = [];
      this.currentImageMap = row.file;
      var coordArr = [];
      setTimeout(() => {
        this.docWidth = $('#ux-modal-body').width();
        this.docHeight = $('#ux-modal-body').height();
        console.log(this.docHeight, 'this.docHeight')
        if (this.docWidth <= 575) {
          // this.xAdditional = this.docWidth * 4 / 100;
          this.xAdditional = 0;
          this.yAdditional = this.docHeight * 75 / 100

        } else {
          this.xAdditional = this.docWidth * 35 / 100;
          this.yAdditional = this.docHeight * 32 / 100

        }
        var image = document.getElementById('zoom-image') as HTMLImageElement;
        var originalWidth = image.naturalWidth;
        var originalHeight = image.naturalHeight;

        var responsiveWidth = image.offsetWidth;
        var responsiveHeight = image.offsetHeight;
        // let calTop = responsiveHeight/2;
        // let top = 'calc(50% - '+calTop+'px)';
        // console.log(top, 'Top calculation')
        // $('.modal-open .modal').css('top', top);
        this.responsiveXratio = originalWidth / responsiveWidth;
        this.responsiveYratio = originalHeight / responsiveHeight;
        let left_position = 0;
        let top_position = 0;
        for (const iterator of row.shop_the_look) {
          left_position = 0;
          top_position = 0;
          let xOriginal = Math.round(iterator['x_axis'] / this.responsiveXratio);
          let yOriginal = Math.round(iterator['y_axis'] / this.responsiveYratio);
          let title_with_type = iterator.title;
          if(iterator.product_type_id != 0){
            title_with_type += '('+iterator.product_type.name+')';
          }
          left_position = xOriginal + this.xAdditional;
          top_position = yOriginal + this.yAdditional;
          let position = {
            top: top_position
          }
          if(!this.isMobile){
            position['left'] = left_position
          }
          let coordsObj = {
            id: iterator.id,
            is_saved: iterator.is_saved,
            xAxis: xOriginal,
            yAxis: yOriginal,
            coord: xOriginal + ',' + yOriginal + ',' + '10',
            title: title_with_type,
            description: iterator.description,
            url: iterator.url,
            myopt: {
              'trigger': 'click',
              'hide-delay': this.delay,
              'hideDelayTouchscreen' : this.delay,
              'position': position,
              'z-index': 99999,
              pointerEvents: 'auto',
              autoPlacement: true,
              placement: 'top'
            }
          }
          coordArr.push(coordsObj);
        }


      }, 50);
      setTimeout(() => {
        console.log(coordArr, 'coordArr')
        this.currentMapCoords = coordArr;
        window.addEventListener("touchmove", this.handleTouchMove, {
          passive: false
        });
      }, 100);

    }

  }

  handleTouchMove(e) {
    e.preventDefault();
  }

  saveSLT(e, row, element) {
    setTimeout(() => {
      if (this.user && this.user.role_slug == 'buyer' && row.id) {
        let params = {
          shop_the_look_id: row.id,
        }
        this.propertyService.saveViewSLT(params)
          .then(r => {
            if (r['data']) {
              this.toaster.success('Shop the look saved successfully', 'Success')

              let newMap = [];
              let cureentMap = this.currentMapCoords;
              this.currentMapCoords = [];
              for (const iterator of cureentMap) {
                if (iterator.id == row.id) {
                  iterator['is_saved'] = 1
                }
                newMap.push(iterator);
              }
              setTimeout(() => {
                this.currentMapCoords = newMap;
              }, 100);
              element.innerHTML = 'Saved Look'
              element.removeEventListener("click", this.saveSLT);
              this.getAll.emit()
            } else {
              this.toaster.error(r.message, 'Oops')
            }

          }).catch(err => {
            if (err.status == 400) {
              this.toaster.error(err.error.message, 'Oops')
            } else if (err.message) {
              this.toaster.error(err.message, 'Oops')
            } else {
              this.toaster.error('Something went wrong!', 'Oops')
            }

          })

      } else {
        console.log('SLT id not exist')
      }
    }, 500);
  }

  setClick(i, row) {
    let id = 'save_link_' + i;
    let linkId = 'view_link_' + i;
    let close_id = 'ux_tooltip_close_'+i;
    $('.ux-tooltip').hide();
    this.googleAnalyticsService.eventEmitter("shop_the_look_View", row.title, "details", "click", 1);
    setTimeout(() => {
      let tooltip_link = 'ux_tooltip_link_'+ i;
      $('.'+tooltip_link).show()
      let ele = document.getElementsByClassName(id);
      if (ele && ele[0]) {
        ele[0].addEventListener("click", (event) => this.saveSLT(event, row, ele[0]));
        if(row && row.description && row.description.length > 50){
          ele[0].parentElement.parentElement.className += ' tooltip-max'
        }
        
      }
      let linkEle = document.getElementsByClassName(linkId);
      if (linkEle && linkEle[0]) {
        linkEle[0].addEventListener("click", (event) => this.viewCountSLT(event, row, linkEle[0]));
      }

      let close_ele = document.getElementsByClassName(close_id);
      if (close_ele && close_ele[0]) {
        close_ele[0].addEventListener("click", (event) => this.closeTooltip(event, i, close_ele[0]));
      }

    }, 50);

  }
  setViewClick(i, row) {
    // console.log(row, 'test')
    $('.ux-tooltip').hide();
    let id = 'view_link_' + i;
    let close_id = 'ux_tooltip_close_'+i;
    setTimeout(() => {
      let tooltip_link = 'ux_tooltip_link_'+ i;
      $('.'+tooltip_link).show()
      let ele = document.getElementsByClassName(id);
      if (ele && ele[0]) {
        ele[0].addEventListener("click", (event) => this.viewCountSLT(event, row, ele[0]));
        if(row && row.description && row.description.length > 50){
          ele[0].parentElement.parentElement.className += ' tooltip-max'
        }
      }

      let close_ele = document.getElementsByClassName(close_id);
      if (close_ele && close_ele[0]) {
        close_ele[0].addEventListener("click", (event) => this.closeTooltip(event, i, close_ele[0]));
      }


    }, 50);

  }

  closeTooltip(e, i, element){
    let tooltip_link = 'ux_tooltip_link_'+ i;
    $('.'+tooltip_link).hide()
    
  }

  viewCountSLT(e, row, element) {
    setTimeout(() => {
      if (row.id) {
        let params = {
          stl_id: row.id,
        }
        this.propertyService.viewCountSLT(params)
          .then(r => {
            if (r['data']) {
             
            } else {
              this.toaster.error(r.message, 'Oops')
            }

          }).catch(err => {
            if (err.status == 400) {
              this.toaster.error(err.error.message, 'Oops')
            } else if (err.message) {
              this.toaster.error(err.message, 'Oops')
            } else {
              this.toaster.error('Something went wrong!', 'Oops')
            }

          })

      } else {
        console.log('SLT id not exist')
      }
    }, 500);
  }

  detectMob() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    var element = document.getElementById('container_details');
    if (isMobile) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }


}
