<div class="apermentShell">
  <div class="container">
      <div class="apertmentAdd">
          
          <div class="row">
            <div class="col-sm-6" *ngFor="let item of bottomList;">
              <ng-container *ngIf="item?.url">
                <a [href]="item?.url" target="_blank"><img src="{{item?.banner_image?.file}}" class="bottom-banner" (click)="bannerViewCount(item)"/></a>
              </ng-container>
              <ng-container *ngIf="item?.url == '' || item?.url == null">
                <img src="{{item?.banner_image?.file}}" class="bottom-banner" />
              </ng-container>
                
            </div>
            <div class="col-sm-12" >

            <div class="advertisement">
              <!-- <p>ADVERTISEMENT</p> -->
            </div>
            </div>
            
          </div>
      </div>
      <div class="apertmentshellContent" *ngIf="isShowBrowseByBuilder">
          <h3>Browse By Builder</h3>
          <!-- <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5> -->
          <div class="aprtLogolist mb-3">
            <carousel autoplay="true" id="footer-carousel" loop="true" autoplayInterval="3000" [cellsToShow]="sliderValue">
                <!-- <carousel autoplay="true" id="footer-carousel" cellWidth="100%" cellsToShow="4" cellsToScroll="4"> -->
                  <ng-container *ngFor="let data of imageArr;  let i=index;">
                    <div class="carousel-cell ux-footer-img">
                        <img src="{{data.file}}" (click)="gotoBuilderPropertyList(data?.uid, data?.id)" title="Browse Builder"/>
                    </div>

                  </ng-container>
                </carousel>
                <!-- <ngb-carousel>
                  <ng-template ngbSlide *ngFor="let data of imageArr;  let i=index;">
                    <div class="row">
                        <div class="col-sm-3" *ngFor="let item of data;">
                          <div class="item text-center">
                            <a (click)="gotoBuilderPropertyList(item?.uid, item?.id)" title="Browse Builder">
                              <img src="{{item.file}}" width="200" height="200"/>
                            </a> 
                           
                            <strong>{{item?.name || ''}}</strong>                          
                          </div>

                        </div>
                    </div>
                      
                     
                  </ng-template>
                  
                </ngb-carousel> -->
          </div>
      </div>
      <!-- <div class="readyGetStarted">
          <h3>Ready To Get Started?</h3>
          <p>
              The first step to buying a new home is getting pre-qualified! This shows the builder’s you’re a serious
              customer as well as helps you establish your budget. It’s quick, free and easy. Plus it doesn’t affect
              your credit score!
          </p>
      </div> -->
  </div>
</div>
<div class="topFooter">
    <div class="container">
      <div class="backtotop">
        <!-- <a id="ux-web-header"></a> -->
        <!--content here-->
        <!-- <a href="#ux-web-header"> <i class="fa fa-arrow-circle-up" aria-hidden="true"></i></a> -->
      </div>
      <!-- <h3>Featured Ads<span></span></h3> -->
      <h3>Partners<span></span></h3>
        <div class="row mx-0-m">
         
            <div class="col-lg-9 col-md-9 ">
                
                <div class="latestmodel_list">
                  <ul>
                    <li >
                      <div class="latestmodel_image w-100">
                        <div class="row mx-0-m">
                          <!-- <div class="col-sm-6  mt-2 mb-2 img-footer" *ngFor="let item of footerList"> -->
                          <div class="col-6 col-sm-6  mt-2 mb-3" *ngFor="let item of footerList">
                            <ng-container *ngIf="item?.url">
                              <!-- <a [href]="item?.url" target="_blank"><img src="{{item?.banner_image?.file}}" class="footer-banner" (click)="bannerViewCount(item)"/></a> -->
                              <a [href]="item?.url" target="_blank" class="adsimagefooter" style="background: url({{item?.banner_image?.file}});" (click)="bannerViewCount(item)">
                              </a>
                            </ng-container>
                            <ng-container *ngIf="item?.url == '' || item?.url == null">
                              <a target="_blank" class="adsimagefooter" style="background: url({{item?.banner_image?.file}});"></a>
                              <!-- <img style="width: 100%;height: 50%;" src="{{item?.banner_image?.file}}"/> -->
                            </ng-container>
                            
                          </div>
                        </div>
                        
                      </div>
                      <!-- <div class="latestmodel_content">
                          <h3>{{item?.name}}</h3>
                      </div> -->
                    </li>
                    <!-- <li>
                      <div class="latestmodel_image">
                        <img src="../../../assets/images/lmodel_02.jpg"/>
                      </div>
                      <div class="latestmodel_content">
                          <h3>Aracena</h3>
                          <p>Starting At $ 600.995</p>
                      </div>
                    </li>
                    <li>
                      <div class="latestmodel_image">
                        <img src="../../../assets/images/lmodel_03.jpg"/>
                      </div>
                      <div class="latestmodel_content">
                          <h3>Oracle Plan 5080</h3>
                          <p>Starting At $ 342.990</p>
                      </div>
                    </li>
                    <li>
                      <div class="latestmodel_image">
                        <img src="../../../assets/images/lmodel_04.jpg"/>
                      </div>
                      <div class="latestmodel_content">
                          <h3>Hudson</h3>
                          <p>Starting At $ 327.990</p>
                      </div>
                    </li> -->
                  </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
              <h3>Get in Touch<span></span></h3>
              <div class="getintouch_call" *ngIf="conpanyPhone"><img src="../../../assets/images/getin_tuch.png">{{conpanyPhone}}</div>
              <div class="getintuch_address">

              </div>
              <a href="mailto:{{conpanyMail}}" target="_blank" class="getintuch_email"><i class="fa fa-envelope-o" aria-hidden="true"></i>  {{conpanyMail}}</a>
              <h3>Follow Us</h3>
              <div class="footer_socialicon">
                <ul>
                  <li class="facebook" *ngIf="fbLink">
                      <a [href]="fbLink" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                  </li>
                  <li class="printersr" *ngIf="pinterestLink">
                      <a [href]="pinterestLink" target="_blank"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a>
                  </li>
                  <li class="instagram" *ngIf="instaLink">
                      <a [href]="instaLink" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                  </li>
                  <li class="youtube" *ngIf="youtubeLink">
                      <a [href]="youtubeLink" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                  </li>
                </ul>
              </div>

            </div>
        </div>
    </div>
</div>
<div class="footerBottom">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-7">
                <div class="copyright_txt">Copyright {{currentYear}} | {{conpanyName}}. All Rights Reserved</div>
            </div>
            <div class="col-lg-5 col-md-5">
              <div class="privacy_txt" *ngIf="!isAdminLogin">
                <a href="privacy-policy" target="_blank">Privacy Policy </a>                |  
                <a href="terms-of-use" target="_blank"> Terms of Use</a>
              </div>
            </div>
        </div>
    </div>
</div>
