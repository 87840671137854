import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-web-social-share-qr',
  templateUrl: './web-social-share-qr.component.html',
  styleUrls: ['./web-social-share-qr.component.css']
})
export class WebSocialShareQrComponent implements OnInit {

  @Input('qr') set setVals (val) {
    if(val) {
      this.qr = val;
    }
  } 
  @Output('closed') closed: EventEmitter<any> = new EventEmitter;
  public qr: string;
  // public detailsLink : any = ''
  // public builderName : any = ''
  // public modelName : any = ''
  constructor() { }

  ngOnInit(): void {
    //  console.log(this.propertyDetails, 'propertyDetails')
    //  this.modelName = this.propertyDetails.model_name;
    //  this.builderName = this.propertyDetails.builder.business_profile.business_name;
    //  this.detailsLink = environment.SITE_URL+'/account/create/'+ this.qr;
  }

  closeModal(){
    // console.log('social')
    this.closed.emit(null)
  }

  closeAssignModal() {
    this.closed.emit(null)
  }

}
