<div class="loader" *ngIf="showLoader">
    <!-- <div class="lds-css ng-scope">
        <div class="lds-blocks" style="width:100%;height:100%">
            <div style="left:80px;top:80px;animation-delay:0s"></div>
            <div style="left:94px;top:80px;animation-delay:0.125s"></div>
            <div style="left:108px;top:80px;animation-delay:0.25s"></div>
            <div style="left:80px;top:94px;animation-delay:0.875s"></div>
            <div style="left:108px;top:94px;animation-delay:0.375s"></div>
            <div style="left:80px;top:108px;animation-delay:0.75s"></div>
            <div style="left:94px;top:108px;animation-delay:0.625s"></div>
            <div style="left:108px;top:108px;animation-delay:0.5s"></div>
        </div>
    </div> -->
    <div class=" lds-css ng-scope loadingio-spinner-double-ring-ge8rd8hm9gb">
        <div class="ldio-2b65bw4pae8">
            <div></div>
            <div></div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
    </div>
</div>