import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-saved-card-list',
  templateUrl: './saved-card-list.component.html',
  styleUrls: ['./saved-card-list.component.css']
})
export class SavedCardListComponent implements OnInit, AfterViewInit {

  @Input('tier') set setValues(val) {
    if(val) {
      if( val[0] ) {
        this.tierDetails = val[0];
      }
      else {
        this.tierDetails = val;
      }
    }
  }

  @Output('newCard') private newCardSaveEvent: EventEmitter<any> = new EventEmitter();
  @Output('savedCard') private savedCardSaveEvent: EventEmitter<any> = new EventEmitter();

  public cardList : Array<any> = [];
  public cardId : any = '';
  public errorMsg: boolean = false;
  public tierDetails : any;

  constructor(
    private userService: UserService,
    private toaster: ToastrService,
    private loader: LoaderService
  ) {
    console.log('check-open-card') }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.getAllCard();
    
  }



  savedCard() {
    // console.log('cardId', this.cardId)
    if(this.cardId) {
      this.savedCardSaveEvent.emit(this.cardId)
    }
    else {
      this.errorMsg = true;
    }
  }

  newCardAdd() {
    this.newCardSaveEvent.emit(true)
  }

  public getAllCard() {
    // this.loader.show()
    this.userService.getCardList()
    .then(res => {
      // this.loader.hide();
      this.cardList = res.data[0].data;
      if(this.cardList.length == 0) {
        this.newCardSaveEvent.emit(true);
      }
      console.log('card-list', this.cardList)
    }).catch(err => {
      // this.loader.hide();
      // this.loading = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
  }

}
