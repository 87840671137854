
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxSocialShareModule } from 'ngx-social-share';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { MaphilightModule } from 'ng-maphilight';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

// Components
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ReviewComponent } from './review/review.component';
import { WebSocialShareComponent } from './web-social-share/web-social-share.component';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ViewFileComponent } from './view-file/view-file.component';
import { ClaimBuildingComponent } from './claim-building/claim-building.component';
import { PaymentComponent } from './payment/payment.component';
import { NgxMaskModule } from 'ngx-mask';
import { SavedCardListComponent } from './saved-card-list/saved-card-list.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { PipesModule } from '../_pipes/pipes.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { WebSocialShareQrComponent } from './web-social-share-qr/web-social-share-qr.component';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';
import { BuilderSubscriptionListComponent } from './builder-subscription-list/builder-subscription-list.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../environments/environment';
import { ReportPropertyComponent } from './report-property/report-property.component';
import { DirectiveModule } from '../directive/directive.module';



@NgModule({
	imports: [
		// BrowserModule,
		DirectiveModule,
		CommonModule,
		RouterModule,
        FormsModule,
		ReactiveFormsModule,
		NgxStripeModule.forRoot(environment.stripeConfig.stripe.publicKey),
		NgbModule,
		TabsModule,
		RatingModule,
		NgxSocialShareModule,
		ShareButtonsModule,
		ShareIconsModule,
		TooltipModule,
		MaphilightModule,
		AutocompleteLibModule,
		PipesModule,
		NgxMaskModule,
		BsDatepickerModule.forRoot(),
		
	],
	declarations: [
        NotFoundComponent,
        LoginComponent,
        SignupComponent,
        ReviewComponent,
        WebSocialShareComponent,
        ViewFileComponent,
        ClaimBuildingComponent,
        PaymentComponent,
        SavedCardListComponent,
        ContactCardComponent,
        WebSocialShareQrComponent,
        SubscriptionListComponent,
        BuilderSubscriptionListComponent,
        StripePaymentComponent,
        ReportPropertyComponent
	],
	exports: [
		NotFoundComponent,
		LoginComponent,
		SignupComponent,
		ReviewComponent,
		WebSocialShareComponent,
		ViewFileComponent,
		ClaimBuildingComponent,
		PaymentComponent,
		SavedCardListComponent,
		ContactCardComponent,
		WebSocialShareQrComponent,
		SubscriptionListComponent,
		BuilderSubscriptionListComponent,
		StripePaymentComponent,
		ReportPropertyComponent
	],
	bootstrap: [ComponentModule]
})

export class ComponentModule { }
