import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { PropertyService } from 'src/app/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { TitleService } from 'src/app/services/title.service';
import { Regex } from '../../_constants/others.constants';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  @Output('closeReviewModal') closeReviewModal: EventEmitter<any> = new EventEmitter;
  max: number = 5;
  overall_rating: number = 0;
  comment : any;
  @Input('propertyDetails') propertyDetails : any = null;
  @Input('user') user : any = null;
  @Input('editReviewDetails') editReviewDetails : any = null;
  
  reviewDetails : any;
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public titleService: TitleService
  ) { }

  ngOnInit(): void {
    this.getReviewDetails();
    
  }

  closeModal(){
    this.closeReviewModal.emit(null)
  }

  onSubmit(){
    this.loader.show();
    if(this.overall_rating != 0 ){
      let params = {
        overall_rating : this.overall_rating,
        comment : this.comment,
        property_id : this.propertyDetails ? this.propertyDetails.id : this.reviewDetails.entity_id
      }
      let ratingId = this.reviewDetails && this.reviewDetails.id ? this.reviewDetails.id : 0
      this.propertyService.saveReview(params, ratingId)
        .then(response => {
          this.closeReviewModal.emit(response)
          let msg = 'Review has been successfully posted';
          if(ratingId != 0){
            msg = 'Review has been successfully updated';
          }
          this.toaster.success(msg, 'Success')
          this.loader.hide()
        }).catch(err => {
          this.loader.hide()
          if(err.status == 400) {          
              this.toaster.error(err.error.message, 'Oops')
            
          } else if(err.message){
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }
          
        })

    } else {
      this.loader.hide()
      this.toaster.error('Please provide rating', 'Oops')
    }
    

  }
  getReviewDetails(){
    if(this.editReviewDetails) {
      this.reviewDetails = this.editReviewDetails;
      this.overall_rating = this.editReviewDetails.overall_rating
      this.comment = this.editReviewDetails.comment

    } else {
      if(this.propertyDetails.is_rating_done == 1 && this.propertyDetails.ratings && this.propertyDetails.ratings.length){
        for (const iterator of this.propertyDetails.ratings) {
          if(iterator.user_id == this.user.id) {
            this.reviewDetails = iterator;
            this.overall_rating = iterator.overall_rating
            this.comment = iterator.comment
          }
          console.log(this.reviewDetails, 'reviewDetails');
        }
  
      }

    }
    
  }

}
