<app-loader></app-loader>
<!-- <button (click)="executeImportantAction()"></button> -->
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-ios-popup></app-ios-popup>

<ng-template #paymentTemplate>
    <div class="new-modal-sec">
        <div class="modal-header">
                <div class="am-center-new">
                    <h2 class="payment-title">Payment for {{tierDetails.name}} </h2>
                    <h2 class="payment-title"><span class="amount-title">(Amount: {{tierDetails.price | currency}})</span></h2>
                </div>
            <!-- <h2 class="payment-title">Payment for {{tierDetails.name}}</h2>
            <h2 class="payment-title">   <span class="amount-title"> (Amount: {{tierDetails.price | currency}})</span></h2> -->
            <!-- <button type="button" class="close" (click)="paymentModalClose()">&times;</button> -->
        </div>
        <div class="modal-body">
            <ng-container *ngIf="existingAgent">
                <app-payment [pageType]="'existing-agent'" [tier]="tierDetails" (saved)="savePaymentModal($event)">
                </app-payment>
            </ng-container>
            <ng-container *ngIf="!existingAgent">
                <app-payment [pageType]="'edit-profile'" [tier]="tierDetails" (saved)="savePaymentModal($event)">
                </app-payment>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #savedCardTemplate>
    <div class="new-modal-sec">
        <div class="modal-header">
            <h2 class="payment-title payment-orange">Please pay your subscription<br> charges to continue</h2>
            <!-- <button type="button" class="close" (click)="savedCardModalClose()">&times;</button> -->

        </div>

        <div class="modal-body">
            <div class="am-center">
                <h2 class="payment-title">Payment for {{tierDetails.name}} </h2>
                <h2 class="payment-title"><span class="amount-title">(Amount: {{tierDetails.price | currency}})</span></h2>
            </div>
                <app-saved-card-list [tier]="tierDetails" (savedCard)="savedCardPayment($event)"
                (newCard)="newCardCheck($event)">
            </app-saved-card-list>
            <div class="btn-payments-new"><p>Not now, [<a href="javascript:void(0)" (click)="logout('pay')"> Logout </a>]</p></div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #subscriptionTemplate>
    <div class="new-modal-sec">
        <div class="modal-header">
            <h2 class="payment-title payment-orange">Please select your subscription<br> to continue</h2>

        </div>

        <div class="modal-body">
            <app-subscription-list (subsVal)="subscriptionValue($event)">
            </app-subscription-list>
            <div class="btn-payments-new"><p>Not now, [<a href="javascript:void(0)" (click)="logout('subs')"> Logout </a>]</p></div>
        </div>
    </div>
</ng-template> -->