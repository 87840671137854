import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import * as API from '../_constants/api.constants';

@Injectable({
  providedIn: 'root'
})
export class PropertyService extends BaseService {
  
  add(params : any){
    return this.callApiFileUpload(API.Property.SAVE , 'POST', params);
  }

  addContact(params : any, isLogin){
    if(isLogin){
      return this.callApi(API.Property.ADD_CONTACT , 'POST', params);
    } else {
      return this.callLoginApi(API.Property.ADD_CONTACT_WITHOUT_LOGIN , 'POST', params);

    }
    
  }

  update(params: any, uid) {
    return this.callApiFileUpload(API.Property.SAVE + '/' + uid, 'PUT', params);
  }


  fetch(params: any, isLogin) {
    if(isLogin){
      return this.callApi(API.Property.FETCH, 'GET', {}, true, params);
    } else {
      return this.callApi(API.Property.FETCH_WITHOUT_LOGIN, 'GET', {}, false, params);
    }
  }

  propertyListByNeighbour(params) {
    return this.callApi(API.Property.FETCH_PROPERTY_NEIGHBOUR, 'GET', {}, true, params);
  }

  propertySharedByClient(params: any, uid: any) {
    return this.callApi(API.Property.SHARED_PROPERTY_WITH_CLIENTS + '/' + uid, 'GET', {}, true, params);
  }

  fetchCluster(params: any, isLogin) {
      return this.callApi(API.Property.FETCH_CLUSTER, 'GET', {}, true, params);
  }

  fetchFavourites(params: any, isLogin: any) {
  
      return this.callApi(API.Property.FETCH_FAVOURITE_PROPERTY, 'GET', {}, isLogin, params);

  }

  fetchContactList(params: any ) {
  
    return this.callApi(API.Property.FETCH_CONTACT, 'GET', {}, true, params);

  }
  

  getContacts(params:  any) {
    return this.callApi(API.Property.GET_CONTACT, 'GET', {}, true, params);
  }

  getMsgs(params:  any) {
    return this.callApi(API.Property.GET_MESSAGE, 'GET', {}, true, params);
  }

  addMsgs(params:  any) {
    return this.callApi(API.Property.GET_MESSAGE, 'POST', params);
  }

  contactAgent(params:  any) {
    return this.callApi(API.Property.CONATCT_AGENT, 'POST', params);
  }

  makePrefferedAgent(params:  any) {
    return this.callApi(API.Property.MAKE_PREFFER_AGENT, 'POST', params);
  }

  removePreferredAgent() {
    return this.callApi(API.Property.REMOVE_PREFER_AGENT, 'POST');
  }

  fetchDetails(params:any, uid, isLogin)
  {
    console.log('prms', params, 'uid',  uid, 'login', isLogin)
    if(isLogin) {
      return this.callApi(API.Property.FETCH_DETAILS + '/' + uid, 'GET',{},true, params);
    } else {
      return this.callApi(API.Property.FETCH_DETAILS_WITHOUT_LOGIN + '/' + uid, 'GET',{},false, params);
    }
    

  }

  getQuickStatResult(uid) {
    return this.callApi(API.Property.PROPERTY_QUICK_STAT + '/' + uid, 'GET',{}, true, {});
  }

  viewPropertyCount(params: any) {
    return this.callApi(API.Property.PROPERTY_VIEW, 'POST', params);
  }
  
  getLocalAgent(params: any) {
    return this.callApi(API.Property.GET_LOCAL_AGENT, 'GET', {}, false, params);
  }

  getLeftAgentWithAuth(params: any) {
    return this.callApi(API.Property.GET_LOCAL_AGENT_AUTH_LEFT, 'GET', {}, true, params);
  }

  getLeftAgentWithoutAuth(params: any) {
    return this.callApi(API.Property.GET_LOCAL_AGENT_AUTH_WITHOUT_LEFT, 'GET', {}, false, params);
  }

  getRightAgentWithAuth(params: any) {
    return this.callApi(API.Property.GET_LOCAL_AGENT_AUTH_RIGHT, 'GET', {}, true, params);
  }

  getRightAgentWithoutAuth(params: any) {
    return this.callApi(API.Property.GET_LOCAL_AGENT_AUTH_WITHOUT_RIGHT, 'GET', {}, false, params);
  }
  
  fetchBuilders(params: any){
    return this.callApi(API.Property.FETCH_BUILDERS, 'GET', {}, false, params);
  }

  fetchSavedSearches(params: any){
    return this.callApi(API.Property.SAVED_SEARCH, 'GET', {}, true, params);
  }

  fetchAllAmenityList(params: any){
    return this.callApi(API.Property.AMENITY_LIST, 'GET', {}, false, params);
  }


  amenitiesSubmit(params:any)
	{
		return this.callApi(API.Property.SET_AMENITIES, 'POST', params);
		
  }
  
  fileDetailsSubmit(params:any){
		return this.callApiFileUpload(API.Property.DETAILS_FILE_SUBMIT, 'POST', params);
		
  }
  
  neighborfileDetailsSubmit(params:any){
		return this.callApiFileUpload(API.Property.NEIGHBORHOOD_FILE_SUBMIT, 'POST', params);
		
	}


  uploadView(params: any, fileType, type){
    if(fileType == 1){
      if(type == 'interior') {
        return this.callApiFileUpload(API.Property.UPLOAD_INTERIOR_IMAGE, 'POST', params);
      } else if(type == 'exterior'){
        return this.callApiFileUpload(API.Property.UPLOAD_EXTERIOR_IMAGE, 'POST', params);
      } else {
        return this.callApiFileUpload(API.Property.UPLOAD_FLOOR_PLAN_IMAGE, 'POST', params);
      }      
    } else {
      if(type == 'interior') {
        return this.callApiFileUpload(API.Property.UPLOAD_INTERIOR_VIDEO, 'POST', params);
      } else if(type == 'exterior'){
        return this.callApiFileUpload(API.Property.UPLOAD_EXTERIOR_VIDEO, 'POST', params);
      }  

      
    }
  } 

  setPropertyDefaultImage(params: any, uid){
      return this.callApi( API.Property.SET_PROPERTY_DEFAULT_IMAGE + '/' + uid, 'PUT', params)
  }

  submitDescriptionMsg(params: any, uid){
      return this.callApi( API.Property.MEDIA_DESCRIPTION + '/' + uid, 'PUT', params)
  }

  removeFile(params: any, uid){
    return this.callApi( API.Property.DELETE_FILE + '/' + uid, 'DELETE', params)
  }

  fetchAgents(params: any){
    return this.callApi(API.Property.FETCH_AGENTS, 'GET', {}, false, params);
  }

  assignUnassignProperty(params: any, uid){
    return this.callApi( API.Property.SAVE + '/' + uid, 'PUT', params);
  }

  updateIncentiveAgent(params: any, uid) {
    return this.callApi( API.Property.SAVE + '/' + uid, 'PUT', params)
  }


  addOrRemovetoFavourite(params : any){
    return this.callApi(API.Property.FAVOURITE , 'POST', params);
  }

  savedSearch(params : any){
    return this.callApi(API.Property.SAVED_SEARCH , 'POST', params);
  }

  saveReview(params : any, id){
    if(id != 0){
      return this.callApi(API.Property.SAVE_RATING +'/'+id , 'PUT', params);
    } else {
      return this.callApi(API.Property.SAVE_RATING , 'POST', params);
    }
    
  }

  getRecentSeen(params : any){
    return this.callApi(API.Property.FETCH_RECENT_SEEN , 'GET',{}, true, params);
  }

  setRecentSeen(params : any){
    return this.callApi(API.Property.SET_RECENT_SEEN , 'POST', params);
  }

  upload360View(params: any, fileType){
      return this.callApiFileUpload(API.Property.UPLOAD_360_IMAGE_VIDEO, 'POST', params);
  } 

  fetchAgentList(params: any ) {
  
    return this.callApi(API.Property.FETCH_AGENT_FOR_CONTACT, 'GET', {}, true, params);

  }

  fetchAllContactOfBuyer(params: any ) {
  
    return this.callApi(API.Property.FETCH_CONTACT_FOR_BUYER, 'GET', {}, true, params);

  }

  fetchAllReview(params: any, role_slug ) {
    if(role_slug == 'buyer'){
      return this.callApi(API.Property.FETCH_MY_REVIEWS, 'GET', {}, true, params);

    } else if(role_slug == 'builder'){
      return this.callApi(API.Property.FETCH_ALL_REVIEW, 'GET', {}, true, params);

    }
    

  }

  propertyListForDropdown(params: any ) {
  
    return this.callApi(API.Property.FETCH_PROPERTY_FOR_DROP_DOWN, 'GET', {}, true, params);

  }

  allPropertyListForDropdown(params: any ) {
  
    return this.callApi(API.Property.FETCH_ALL_PROPERTY_FOR_DROP_DOWN, 'GET', {}, true, params);

  }

  advanceSearchFilterList(params: any ) {
  
    return this.callApi(API.Property.FETCH_ADVANCE_SEARCH_FILTER, 'GET', {}, true, params);

  }

  fetchSLTForProperty(params: any ) {
  
    return this.callApi(API.Property.FETCH_SHOP_THE_LOOK, 'GET', {}, true, params);

  }

  removeSLTForProperty(params: any, uid){
    return this.callApi( API.Property.REMOVE_SHOP_THE_LOOK + '/' + uid, 'DELETE', params)
  }

  saveSLTForProperty(params: any, uid){
    if(uid){
      return this.callApi(API.Property.SAVE_SHOP_THE_LOOK + '/' + uid, 'PUT', params);
    } else {
      return this.callApi(API.Property.SAVE_SHOP_THE_LOOK , 'POST', params);
    }
    
  }

  saveViewSLT(params : any){
    return this.callApi(API.Property.SAVE_VIEW_SHOP_THE_LOOK , 'POST', params);
  }

  removeSLTForBuyer(params : any, uid){
    return this.callApi(API.Property.SAVE_VIEW_SHOP_THE_LOOK + '/' + uid, 'DELETE', params);
  }

  getAllSavedSLTForBuyer(params : any){
    return this.callApi(API.Property.LIST_SAVED_SHOP_THE_LOOK , 'GET', {}, true, params);
  }

  removeReview(params: any, uid){
    return this.callApi( API.Property.DELETE_REVIEW + '/' + uid, 'DELETE', params)
  }

  replyLeads(params:any){
    return this.callApi(API.Property.REPLY_LEAD , 'POST', params);

  }

  fetchAllSLT(params : any, isLogin){
    if(isLogin){
      return this.callApi(API.Property.LIST_AUTH_SHOP_THE_LOOK , 'GET', {}, true, params);
    } else {
      return this.callApi(API.Property.LIST_WITHOUT_AUTH_SHOP_THE_LOOK , 'GET', {}, false, params);
    }
   
  }

  viewCountSLT(params : any){
    return this.callApi(API.Property.VIEW_COUNT_SHOP_THE_LOOK , 'POST', params);
  }
  
  getEventsList(params: any) {
    return this.callApi(API.Property.CREATE_APPOINTMENT, 'GET', {}, true, params);

  }

  createAppointment(params:any){
    return this.callApi(API.Property.CREATE_APPOINTMENT , 'POST', params);

  }

  cancelAppointment(id){
    return this.callApi(API.Property.CANCEL_APPOINTMENT + '/' + id, 'PUT', {});

  }

  acceptAppointment(params:any){
    return this.callApi(API.Property.ACCEPT_APPOINTMENT , 'POST', params);

  }

  validateAddress(params:any){
    return this.callApi(API.Property.VALIDATE_ADDRESS , 'POST', params);

  }

  removeSavedSearch(params: any, uid){
    return this.callApi( API.Property.REMOVE_SAVED_SEARCH + '/' + uid, 'DELETE', params)
  }
  
  propertyCount(params:any)
	{
		return this.callApi(API.Property.MODEL_COUNT, 'POST', params);
		
  }

  setCustomMsgProperty(params: any, uid){
    return this.callApi( API.Property.SAVE + '/' + uid, 'PUT', params);
  }

  fecthAllRooms(params: any){
    return this.callApi(API.Property.FETCH_ROOM_TYPES, 'GET', {}, true, params);
  }

  fecthAllProducts(params: any){
    return this.callApi(API.Property.FETCH_PRODUCT_TYPES, 'GET', {}, true, params);
  }

  showUserClaimDetails(params: any){
    return this.callApi(API.Property.SHOW_CLAIM_DETAILS, 'GET', {}, true, params);
  }

  saveRoomType(params: any){
    return this.callApi( API.Property.SAVE_ROOM_TYPE, 'POST', params);
  }

  saveClaimProperty(params: any){
    return this.callApi( API.Property.SAVE_CLAIM_DETAILS, 'POST', params);
  }

  updateClaimContactProperty(params: any){
    return this.callApi( API.Property.UPDATE_CLAIM_DETAILS, 'POST', params);
  }

  shareAgentProperty(params: any){
    return this.callApi( API.Property.SHARE_AGENT_PROPERTY, 'POST', params);
  }


  fetchSimilarProperties(params: any){
    return this.callApi(API.Property.FETCH_SIMILAR_PROPERTY, 'GET', {}, false, params);
  }

  propertySharedList(params: any, uid: any) {
    return this.callApi(API.Property.SHARED_PROPERTY_LIST, 'GET', {}, true, params);
  }

  addReportOfProperty(params : any, isLogin){
    if(isLogin){
      return this.callApi(API.Property.ADD_REPORT_OF_PROPERTY , 'POST', params);
    }
  }
  
  removeProperty(params: any, uid){
    return this.callApi( API.Property.DELETE_PROPERTY + '/' + uid, 'DELETE', params)
  }
}
