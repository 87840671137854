import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Regex, LocalVars } from '../../_constants/others.constants';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { CommonStorageService } from 'src/app/services/common-storage.service';
import { SocialUser } from "angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output('closeLoginModal') closeLoginModal: EventEmitter<any> = new EventEmitter;
  loginForm: FormGroup;
  loginFormforBuilder: FormGroup;
  loginFormforAgent: FormGroup;
  forgotPassword: FormGroup;
  forgotPasswordAgent: FormGroup;
  forgotPasswordBuilder: FormGroup;
  loading = false;
  submitted = false;
  public invalidForm = false;
  submittedBuilder: boolean = false;
  public invalidFormForBuilder = false;

  public invalidFormAgent = false;
  submittedAgent: boolean = false;
  submittedEmail: boolean = false;
  public invalidForm2 = false;
  submittedEmail1: boolean = false;
  public invalidForm3 = false;
  submittedEmail2: boolean = false;
  public invalidForm4 = false;
  onlyEmailShow: boolean = false;
  builderEmailShow: boolean = false;
  buyerEmailShow: boolean = false;
  
  public userType: string = 'buyer'
  public isMobile : boolean = false;
  public user: SocialUser = new SocialUser;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: SocialAuthService
  ) {    
   }

  ngOnInit(): void {
    /* this.authService.authState.subscribe(user => {
      this.user = user;
      console.log(user);
    }); */

    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
      password: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)])
    });

    this.loginFormforBuilder = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
      password: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)])
    });

    this.loginFormforAgent = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
      password: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)])
    });
    this.forgotPassword = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
    });

    this.forgotPasswordBuilder = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
    });

    this.forgotPasswordAgent = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
    });

    this.toggleUserType('buyer_login')
    this.detectMob();
  }

  detectMob() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }


  private validateBlankValue(control: FormControl): any {
    if (this.loginForm || this.loginFormforBuilder || this.loginFormforAgent) {
      return control.value.trim() === '' ? { emptyValue: true } : null;
    }
  }
  onSubmit(form, type) {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.invalidForm = false;
      this.loading = true;
      // const params = this.loginForm.getRawValue();
      const params = { email: form.value.email, password: form.value.password, type: type }

      this.loader.show();
      this.userService.login(params)
        .then(r => {
          console.log(r);
          if (r['data'] && r['data']['token']) {
            this.commonStorage.setItem(LocalVars.TOKEN_VAR, btoa(r['data']['token']));
            this.closeLoginModal.emit(r['data']);
            this.toaster.success(r.message, 'Login Success');
            this.getUserDetails();
          } else {
            this.toaster.success('Token not found!', 'Login Error');
          }
          this.loader.hide();
          this.loading = false;
        }).catch(err => {
          const message = err && err.error && err.error.message || "Something went wrong!";
          this.toaster.error(message, 'Login Error')
          this.loader.hide();
          this.loading = false;
        })
    } else {
      this.invalidForm = true;
    }
  }

  //for builder

  onSubmitBuilderForm(form, type) {
    this.submittedBuilder = true;
    if (this.loginFormforBuilder.valid) {
      this.invalidFormForBuilder = false;
      this.loading = true;
      // const params = this.loginFormforBuilder.getRawValue();
      const params = { email: form.value.email, password: form.value.password, type: type }
      this.loader.show();
      this.userService.login(params)
        .then(r => {
          console.log(r);
          if (r['data'] && r['data']['token']) {
            this.commonStorage.setItem(LocalVars.TOKEN_VAR, btoa(r['data']['token']));
            this.closeLoginModal.emit(r['data']);
            this.toaster.success(r.message, 'Login Success');
            this.getUserDetails();
          } else {
            this.toaster.success('Token not found!', 'Login Error');
          }
          this.loader.hide();
          this.loading = false;
        }).catch(err => {
          const message = err && err.error && err.error.message || "Something went wrong!";
          this.toaster.error(message, 'Login Error')
          this.loader.hide();
          this.loading = false;
        })
    } else {
      this.invalidFormForBuilder = true;
    }
  }

  //for Agent

  onSubmitAgentForm(form, type) {
    console.log(type)
    this.submittedAgent = true;
    if (this.loginFormforAgent.valid) {
      this.invalidFormAgent = false;
      this.loading = true;
      // const params = this.loginFormforAgent.getRawValue();
      const params = { email: form.value.email, password: form.value.password, type: type }
      this.loader.show();
      this.userService.login(params)
        .then(r => {
          console.log(r);
          if (r['data'] && r['data']['token']) {
            this.commonStorage.setItem(LocalVars.TOKEN_VAR, btoa(r['data']['token']));
            this.closeLoginModal.emit(r['data']);
            this.toaster.success(r.message, 'Login Success');
            this.getUserDetails();
          } else {
            this.toaster.success('Token not found!', 'Login Error');
          }
          this.loader.hide();
          this.loading = false;
        }).catch(err => {
          const message = err && err.error && err.error.message || "Something went wrong!";
          this.toaster.error(message, 'Login Error')
          this.loader.hide();
          this.loading = false;
        })
    } else {
      this.invalidFormAgent = true;
    }
  }

  getUserDetails() {
    this.userService.me()
      .then(r => {
        if (r['data'] && r['data']['user']) {
          this.completeLogin(r['data']['user']);
        } else {
          this.toaster.error('User details not found!', 'User fetch')
        }
      }).catch(err => {
        console.log(err, "ERROR")
        const message = err && err.error && err.error.message || "Something went wrong!";
        this.toaster.error(message, 'Init')
      })
  }

  private completeLogin(user: any) {
    //this.user = user;
    //user['business_profile']
    this.commonStorage.setItem(LocalVars.USER_VAR, btoa(user.uid));
    this.commonStorage.setDetailsItem(LocalVars.USER, user);
    
    this.router.navigate([''], { replaceUrl: true });
  }

  closeLogin() {
    this.closeLoginModal.emit(null);
    // setTimeout(() => {
    //   this.router.navigate(['/home'])      
    // }, 2000);  
  }

  signInWithGoogle(): void {
    this.toaster.error('No valid GoogleLoginProvider is available');
    //this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    let params = {};
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

  }

  /* googleLoginOptions = {
    scope: 'profile email'
  }; */ // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
  
    
  
  /* signInWithGoogle(): void {
    const xyz = this.authService.signIn(GoogleLoginProvider.PROVIDER_ID, this.googleLoginOptions );
      setTimeout(() => {
        xyz.then((data) => {
          console.log(data);
        }).catch(data => {
          console.log(data);
          
        });
      }, 1500);

  } */

  signInWithFB(): void {
    this.toaster.error('No valid FacebookLoginProvider is available');
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  signInWithPinterest() {
    // PDK.init({
    //   appId: "1460969",
    //   cookie: true
    // });
    // console.log(PDK, 'pdk')
    //           //login
    // PDK.login({ scope : 'read_relationships,read_public' }, function(response){
    //     if (!response || response.error) {
    //       //  alert('Error occurred');
    //     } else {
    //         //console.log(JSON.stringify(response));
    //     }
    // //get board info
    // var pins = [];
    // PDK.request('/v1/me/', function (response) {
    //   if (!response || response.error) {
    //     //alert('Error occurred');
    //   } else {
    //     console.log(JSON.stringify(response));
    //       //  alert('success');
    //         console.log(PDK.getSession().accessToken);

    //         var yahoo = $( "#result" ).load( "https://api.pinterest.com/v1/me/?access_token="+PDK.getSession().accessToken+"&fields=counts" );
    //         console.log(yahoo);
    //        // PDK.logout();
    //   }
    // });

    // //end get board info
    // });
  }

  toggleUserType(type) {
    this.userType = type;
    console.log('type-login', type)
    this.commonStorage.setItem(LocalVars.SIGN_USER_TYPE, btoa(type));
  }


  onSubmitForgotPassword(form) {
    this.submittedEmail = true;
    if (this.forgotPassword.valid) {
      this.invalidForm2 = false;
      this.loading = true;
      const params = { email: form.value.email }

      this.loader.show();
      this.userService.forgotPassword(params)
        .then(r => {

          console.log(r);
          if (r['data']) {
            this.loader.hide();
            this.loading = false;
            this.toaster.success(r.message, 'Email successfully sent');
          }
          else {
            this.toaster.error('Email Error', 'User Does not exist');
          }
        }).catch(err => {
          const message = err && err.error && err.error.message || "Something went wrong!";
          this.toaster.error(message, 'Login Error')
          this.loader.hide();
          this.loading = false;
        })
    } else {
      this.invalidForm = true;
    }
  }

  onSubmitForgotPasswordBuilder(form) {
    this.submittedEmail1 = true;
    if (this.forgotPasswordBuilder.valid) {
      this.invalidForm3 = false;
      this.loading = true;
      const params = { email: form.value.email }

      this.loader.show();
      this.userService.forgotPassword(params)
        .then(r => {

          console.log(r);
          if (r['data']) {
            this.loader.hide();
            this.loading = false;
            this.toaster.success(r.message, 'Email successfully sent');
          }
          else {
            this.toaster.error('Email Error', 'User Does not exist');
          }
        }).catch(err => {
          const message = err && err.error && err.error.message || "Something went wrong!";
          this.toaster.error(message, 'Login Error')
          this.loader.hide();
          this.loading = false;
        })
    } else {
      this.invalidForm3 = true;
    }
  }

  onSubmitForgotPasswordAgent(form) {
    this.submittedEmail2 = true;
    if (this.forgotPasswordAgent.valid) {
      this.invalidForm4 = false;
      this.loading = true;
      const params = { email: form.value.email }

      this.loader.show();
      this.userService.forgotPassword(params)
        .then(r => {

          console.log(r);
          if (r['data']) {
            this.loader.hide();
            this.loading = false;
            this.toaster.success(r.message, 'Email successfully sent');
          }
          else {
            this.toaster.error('Email Error', 'User Does not exist');
          }
        }).catch(err => {
          const message = err && err.error && err.error.message || "Something went wrong!";
          this.toaster.error(message, 'Login Error')
          this.loader.hide();
          this.loading = false;
        })
    } else {
      this.invalidForm4 = true;
    }
  }


  forgotYourPassword() {
    this.onlyEmailShow = true;
  }
  builderEmail() {
    this.builderEmailShow = true;
  }

  ForLoginBuilder() {
    this.builderEmailShow = false;
  }

  ForLogin() {
    this.onlyEmailShow = false;
  }

  forgotPasswordBuyer() {
    this.buyerEmailShow = true;
  }
  ForLoginBuyer() {
    this.buyerEmailShow = false;
  }

  buyerMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.loginForm.get('email').setValue(convertedMail)
  }

  builderMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.loginFormforBuilder.get('email').setValue(convertedMail)
  }

  agentMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.loginFormforAgent.get('email').setValue(convertedMail)
  }

  buyerForgotMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.forgotPassword.get('email').setValue(convertedMail)
  }

  builderForgotMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.forgotPasswordBuilder.get('email').setValue(convertedMail)
  }

  agentForgotMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.forgotPasswordAgent.get('email').setValue(convertedMail)
  }
}
