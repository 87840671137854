import { Injectable } from '@angular/core';
import $ from 'jquery'

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor() { }

  setTitle(title) {
    $('title').html(title)
  }
}
