import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePipe } from './custom-date.pipe';
import { CustomDatetimePipe } from './custom-datetime.pipe';
import { CustomTimePipe } from './custom-time.pipe';
import { PhonePipe } from './phone.pipe';



@NgModule({
  declarations: [
    CustomDatePipe,
    CustomDatetimePipe,
    CustomTimePipe,
    PhonePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomDatePipe,
    CustomDatetimePipe,
    CustomTimePipe,
    PhonePipe
  ]
})
export class PipesModule { }
