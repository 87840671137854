<!-- <div class="modal-header">
    <button type="button" class="close" (click)="closePaymentModal()">&times;</button>
</div>  -->
<!-- <h2 class="payment-title">Payment for {{tierDetails.name}}</h2> -->
<form [formGroup]="paymentForm" (ngSubmit)="onSubmit(paymentForm)" class="new-frm">
        <div>
            <input mask="0000 0000 0000 0000" type="text" formControlName="card_number" placeholder="Card Number">
        </div>
        <div *ngIf="!paymentForm.get('card_number').value && (invalidForm || paymentForm.get('card_number').touched)" 
         class="has-error">Card Number is required
        </div>
        <div *ngIf="paymentForm.get('card_number').value && paymentForm.get('card_number').invalid && (invalidForm || paymentForm.get('card_number').touched)" 
         class="has-error">Please Enter a correct Card Number
        </div>
        <div>
            <input type="text" formControlName="card_holder_name" placeholder="Name">
        </div>
        <div *ngIf="paymentForm.get('card_holder_name').invalid && (invalidForm || paymentForm.get('card_holder_name').touched)"
         class="has-error">Card Holder Name is required
        </div>
        <!-- <label>Card Number</label> -->
        <div [hidden]="true">
            <input type="text" formControlName="amount" placeholder="Amount" readonly>
        </div>
        <div *ngIf="paymentForm.get('amount').invalid && (invalidForm || paymentForm.get('amount').touched)"
         class="has-error">Amount is required
        </div>
        <!-- <label>Date of Expiry</label> -->
        <div>
            <input  mask="00/0000" type="text" formControlName="date" placeholder="Expiry Date(MM/YYYY)">
        </div>
        <div *ngIf="paymentForm.get('date').invalid && (invalidForm || paymentForm.get('date').touched)"
            class="has-error" class="has-error">Expiry Date is required
        </div>
        <div>
            <input type="password" formControlName="cvc" placeholder="CVC">
        </div>
        <div *ngIf="paymentForm.get('cvc').invalid && (invalidForm || paymentForm.get('cvc').touched)"
            class="has-error">CVC is required
        </div>
        
        <button class="btn reg_loginbtn-new w-100" [disabled]="loading">Submit</button>
</form>