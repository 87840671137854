import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import * as API from '../_constants/api.constants';
import { LocalVars } from '../_constants/others.constants';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class CommonService extends BaseService {

	
	private profileUpdateSubject = new BehaviorSubject<any>({});
	public profileUpdate = this.profileUpdateSubject.asObservable();

	private addressUpdateSubject = new BehaviorSubject<any>({});
	public addressUpdate = this.addressUpdateSubject.asObservable();

	private addressFromHomeSubject = new BehaviorSubject<any>({});
	public addressUpdateFromHome = this.addressFromHomeSubject.asObservable();

	private updateReviewVisibilitySubject = new BehaviorSubject<any>({});
	public updateReviewVisibilityFromAdmin = this.updateReviewVisibilitySubject.asObservable();


	isLoggedIn() {
		return this.cookieStorage.checkItem(LocalVars.TOKEN_VAR);
	}

	getUser() {
		return this.callApi(API.User.ME);
	}

	doLogOut() {
		return this.callApi(API.User.LOGOUT, 'POST');
	}

	me() {
		return this.callApi(API.User.ME, 'GET');
	}

	storeProfileUpdate(data) {
		this.profileUpdateSubject.next({res: data});
	}

	updateCurrentAddress(data) {
		this.addressUpdateSubject.next({res: data});
	}

	getSiteSettingDetails(params){
		return this.callApi(API.Common.FETCH_SITE_SETTING, 'GET');
	}

	saveContactUs(params){
		return this.callApi(API.Common.SAVE_CONTACT_US, 'POST', params);
	}

	updateAddressFromHome(data) {
		this.addressFromHomeSubject.next({res: data});
	}

	fecthCityList(params: any ) {  
		return this.callApi(API.Common.FETCH_CITIES, 'GET', {}, true, params);	
	}

	fecthStateList(params: any ) {  
		return this.callApi(API.Common.FETCH_STATES, 'GET', {}, true, params);	
	}

	addToken(params : any) {
		return this.callApi(API.Common.WEB_TOKEN, 'POST', params);	
	}
	
	viewCountBanner(params : any){
		return this.callApi(API.Common.VIEW_COUNT_BANNER , 'POST', params);
	}

	updateReviewVisiblilty(data) {
		this.updateReviewVisibilitySubject.next({res: data});
	}

	fecthAllCityList(params: any ) {  
		return this.callApi(API.Common.FETCH_ALL_CITIES, 'GET', {}, true, params);	
	}

	fecthAllStateList(params: any ) {  
		return this.callApi(API.Common.FETCH_ALL_STATES, 'GET', {}, true, params);	
	}

	
}
