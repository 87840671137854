import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IosPopupComponent } from './ios-popup/ios-popup.component';



@NgModule({
  declarations: [IosPopupComponent],
  imports: [
    CommonModule
  ],
  exports: [
    IosPopupComponent
  ]
})
export class SharedModule { }
