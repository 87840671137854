import { Component, OnInit, Output,EventEmitter,HostListener, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Regex, LocalVars } from '../../_constants/others.constants';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
declare var PDK: any;
import $ from 'jquery'
import { CommonService } from 'src/app/services/common.service';
import { CommonStorageService } from 'src/app/services/common-storage.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  @Output('closeSignupModal') closeSignupModal: EventEmitter<any> = new EventEmitter;
  @Output('cancelSignupModal') cancelSignupModal: EventEmitter<any> = new EventEmitter;
  @Output('userTypeForSignUp') userTypeForSignUp: EventEmitter<any> = new EventEmitter;

  // @ViewChild('paymentTemplate', { static: true }) paymentTemplate: TemplateRef<any>;
  
  signupForm: FormGroup;
  loading = false;
  submitted = false;
  public invalidForm = false;
	public invalidPassword: boolean = false;
  public mismatchPassword: boolean = false;
  public googleUser : any;
  signupForBuilder: FormGroup;
  submitted1:boolean=false;
  public invalidForm1 = false;
  signupForAgent:FormGroup;
  public invalidFormAgent:boolean=false;
  public submittedAgent:boolean=false;
  public userType : string = 'buyer'
  builderStep1 : boolean = true;
  builderStep2 : boolean = false;
  builderStep1Value : any
  builderStep2Value : any
  public agentStep2 = false;
  public agentStep1 = true;
  public stateList : any;
  public cityList : any;
  keyword = 'text';
  cityKeyword = 'name';
  state_code : string = null;
  state_name : string = null;
  city_name : string = null;
  public isMobile : boolean = false; 
  public modalPaymentRef: BsModalRef;
  public subscriptionAgentList : Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cookieStorage: CookieStorageService,
    private commonStorage: CommonStorageService,
    private toaster: ToastrService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: SocialAuthService,
    public commonService : CommonService,
    private modalService: BsModalService,
  ) {

    this.getSubsAgentList();

    
   }

  ngOnInit(): void {
    this.detectMob();
    this.signupForm = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      last_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
			email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
      password: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      c_password: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)])
    });
    
    //for builder 
    this.signupForBuilder=this.formBuilder.group({
      first_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      last_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
      password: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      c_password: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      business_name: new FormControl('',  []),
      address_line_1: new FormControl('',  []),
      city: new FormControl('',  []),
      state: new FormControl('',  []),
      postal_code: new FormControl('',  [])

    });

    //for agent
    this.signupForAgent=this.formBuilder.group({
      first_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      last_name: new FormControl('', [Validators.required, this.validateBlankValue.bind(this)]),
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email), this.validateBlankValue.bind(this)]),
      password: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      c_password: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      business_name: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      license_number: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      broker_name: new FormControl('',  [Validators.required, this.validateBlankValue.bind(this)]),
      agent_subscription_id: new FormControl('', [Validators.required]),

    });


    if(localStorage.getItem('agentDetails')) {
      let valAgent = JSON.parse(atob(localStorage.getItem('agentDetails')));
      if(valAgent) {
        // console.log('valag', valAgent);
        this.setAgentFormValue(valAgent);
      }
    }
   
    this.toggleUserType('buyer')
   // this.getAllStates();
 
   
  }

  private validateBlankValue(control: FormControl): any {
		if (this.signupForm || this.signupForBuilder || this.signupForAgent) {
			return control.value && control.value.trim() === '' ? { emptyValue: true } : null;
		}
  }
  
  getSubsAgentList() {
    // this.loader.show();
    this.userService.getAgentSubs()
    .then(res => {
      // this.loader.hide();
      this.subscriptionAgentList = res.data.list.data;

    }).catch(err => {
      // this.loader.hide();
      this.loading = false;
      if (err.status == 400) {
        this.toaster.error(err.error.message, 'Oops')

      } else if (err.message) {
        this.toaster.error(err.message, 'Oops')
      } else {
        this.toaster.error('Something went wrong!', 'Oops')
      }

    })
  }


  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
        return;
    }
    if ( this.signupForm.valid && !this.mismatchPassword){
      this.invalidForm = false;
      this.loading = true;
      const params = this.signupForm.getRawValue();      
      this.loader.show();
      this.userService.registration(params)
      .then(r => {
        this.loader.hide(); 
        if ( r['data']) {
          this.closeSignupModal.emit(r['data']);
          this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')     
        } else {
          this.toaster.error(r.message, 'Oops')
        }
        this.loading = false;
      }).catch(err => {
        this.loader.hide();
        this.loading = false;
        if(err.status == 400) {
          if(err.error.data.errors.email[0]) {
            this.toaster.error(err.error.data.errors.email[0], 'Oops')
          } else {
            this.toaster.error(err.error.message, 'Oops')
          }
					
				} else if(err.message){
					this.toaster.error(err.message, 'Oops')
				} else {
					this.toaster.error('Something went wrong!', 'Oops')
				}
        
      })

    } else {
      this.invalidForm = true;
    }
    
  }
  closeSignup() {
		this.closeSignupModal.emit(null);
  }

  detectMob() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }




  //builder signup form submit

  onSubmitBuilderSignUpForm() {
    this.submitted1 = true;
    if (this.signupForBuilder.invalid) {
      return;
    }

    if (this.signupForBuilder.valid && !this.mismatchPassword && this.state_code && this.city_name) {
      this.invalidForm1 = false;
      this.loading = true;
      const params = this.signupForBuilder.getRawValue();
      params['state'] = this.state_code
      params['city'] = this.city_name
      this.loader.show();
      this.userService.registrationForBuilder(params)
        .then(response => {
          
          this.loader.hide();
          if (response['data']) {
            this.signupForBuilder.reset()
            this.closeSignupModal.emit(response['data']);
            this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
          } else {
            this.toaster.error(response.message, 'Oops')
          }
          this.loading = false;
        }).catch(err => {

          this.loader.hide();
          this.loading = false;
          if (err.status == 400) {
            if (err.error.data.errors.email[0]) {
              this.toaster.error(err.error.data.errors.email[0], 'Oops')
            } else {
              this.toaster.error(err.error.message, 'Oops')
            }

          } else if (err.message) {
            this.toaster.error(err.message, 'Oops')
          } else {
            this.toaster.error('Something went wrong!', 'Oops')
          }

        })


    }
    else if(this.state_code == null){
      this.invalidForm1 = true;
      this.signupForBuilder.get('state').patchValue('');
    } else if(this.city_name == null){
      this.signupForBuilder.get('city').patchValue('');
    }
  }


  //Agent signup form submit

  onSubmitAgentSignUpForm() {

    this.submittedAgent = true;
    if (this.signupForAgent.invalid) {
      return;
    }
    // console.log('des', this.signupForAgent.valid)
    if (this.signupForAgent.valid && !this.mismatchPassword ) {
      this.invalidFormAgent= false;
      // this.loading = true;
      
      // this.loader.show();
      const params = this.signupForAgent.getRawValue();
      localStorage.setItem('agentDetails', btoa(JSON.stringify(params)))
      this.closeSignupModal.emit('AgentSubmit');
      // this.openPaymentTemplate();
     



    }
    else {
      this.invalidFormAgent = true;
    }
  }

//builder confirm password

  compareConfirmPasswordWithNewForBuilder(event: any) {
		if ( this.signupForBuilder && event.target.value) {
			var newPassword = this.signupForBuilder.get('password').value;
			if ( event.target.value !== newPassword ) {
				this.mismatchPassword = true;
			} else {
				this.mismatchPassword = false;
			}
      // console.log('this.mismatchPassword', this.mismatchPassword)
			// return control.value !== newPassword ? { mismatchPassword: true } : null;
		}
  }


  //builder password validation
  
  passwordValidationForBuilder(event: any): any{
		if (this.signupForBuilder && event.target.value ) {
			
			if(this.signupForBuilder && event.target.value && this.signupForBuilder.get('c_password').value) {
				var newPassword = this.signupForBuilder.get('c_password').value;
				if ( event.target.value !== newPassword ) {
					this.mismatchPassword = true;
				} else {
					this.mismatchPassword = false;
				}
			}
		}
		return null;
  }
  

  //Agent Compare password and confirm password

  compareConfirmPasswordWithNewForAgent(event: any) {
		if ( this.signupForAgent && event.target.value) {
			var newPassword = this.signupForAgent.get('password').value;
			if ( event.target.value !== newPassword ) {
				this.mismatchPassword = true;
			} else {
				this.mismatchPassword = false;
			}
      // console.log('this.mismatchPassword', this.mismatchPassword)
			// return control.value !== newPassword ? { mismatchPassword: true } : null;
		}
  }


  //builder password validation
  
  passwordValidationForAgent(event: any): any{
		if (this.signupForAgent && event.target.value ) {
			
			if(this.signupForAgent && event.target.value && this.signupForAgent.get('c_password').value) {
				var newPassword = this.signupForAgent.get('c_password').value;
				if ( event.target.value !== newPassword ) {
					this.mismatchPassword = true;
				} else {
					this.mismatchPassword = false;
				}
			}
		}
		return null;
  }


  //for buyer check password and confirm password
  
  compareConfirmPasswordWithNew(event: any) {
		if ( this.signupForm && event.target.value) {
			var newPassword = this.signupForm.get('password').value;
			if ( event.target.value !== newPassword ) {
				this.mismatchPassword = true;
			} else {
				this.mismatchPassword = false;
			}
      // console.log('this.mismatchPassword', this.mismatchPassword)
			// return control.value !== newPassword ? { mismatchPassword: true } : null;
		}
  }
  
  passwordValidator(event: any): any{
		if (this.signupForm && event.target.value ) {
			// if (event.target.value.match(/^(?=.*\d)(?=.*[a-zA-Z]).{6,16}$/)) 
			// {
			// 	this.invalidPassword = false;
			// }
			// else
			// {
			// 	this.invalidPassword = true;
			// }
			if(this.signupForm && event.target.value && this.signupForm.get('c_password').value) {
				var newPassword = this.signupForm.get('c_password').value;
				if ( event.target.value !== newPassword ) {
					this.mismatchPassword = true;
				} else {
					this.mismatchPassword = false;
				}
			}
		}
		return null;
  }
  
  signInWithGoogle(): void {
    this.toaster.error('No valid GoogleLoginProvider is available');
    let params = {};
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
 
  signInWithFB(): void {
    this.toaster.error('No valid FacebookLoginProvider is available');
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
 
  signOut(): void {
    this.authService.signOut();
  }

  signInWithPinterest(){
      // PDK.init({
      //   appId: "1460969",
      //   cookie: true
      // });
      // console.log(PDK, 'pdk')
      //           //login
      // PDK.login({ scope : 'read_relationships,read_public' }, function(response){
      //     if (!response || response.error) {
      //       //  alert('Error occurred');
      //     } else {
      //         //console.log(JSON.stringify(response));
      //     }
      // //get board info
      // var pins = [];
      // PDK.request('/v1/me/', function (response) {
      //   if (!response || response.error) {
      //     //alert('Error occurred');
      //   } else {
      //     console.log(JSON.stringify(response));
      //       //  alert('success');
      //         console.log(PDK.getSession().accessToken);

      //         var yahoo = $( "#result" ).load( "https://api.pinterest.com/v1/me/?access_token="+PDK.getSession().accessToken+"&fields=counts" );
      //         console.log(yahoo);
      //        // PDK.logout();
      //   }
      // });

      // //end get board info
      // });
  }

  toggleUserType(type){
    this.userType = type;
    console.log('type-sign', type)
    this.commonStorage.setItem(LocalVars.SIGN_USER_TYPE, btoa(type));
  }

  toggleView(type){
    // this.builderStep1 = !this.builderStep1
    // this.builderStep2 = !this.builderStep2
    if(type == '1'){
      this.submitted1 = true;
      if (!this.signupForBuilder.invalid && !this.mismatchPassword) {
        this.builderStep1Value = this.signupForBuilder.getRawValue();
        this.builderStep1 = false
        this.builderStep2 = true
        this.submitted1 = false;
      } else {
        this.builderStep1 = true
        this.builderStep2 = false
      }
      this.builderStep2Value = this.signupForBuilder.getRawValue();
      this.signupForBuilder.get('business_name').setValidators([Validators.required]);
      this.signupForBuilder.get('address_line_1').setValidators([Validators.required]);
      this.signupForBuilder.get('state').setValidators([Validators.required]);
      this.signupForBuilder.get('city').setValidators([Validators.required]);
      this.signupForBuilder.get('postal_code').setValidators([Validators.required]);
      if(this.state_code){
        // console.log(this.state_name, 'this.state_name')
        this.signupForBuilder.patchValue({
          // state : this.state_code
          state : this.state_name
        })
      }
      if(this.city_name){
        this.signupForBuilder.patchValue({
          city : this.city_name
        })
      }
    } else {
      this.builderStep1 = true
      this.builderStep2 = false
      this.signupForBuilder.get('business_name').clearValidators();
      this.signupForBuilder.get('business_name').updateValueAndValidity();
      this.signupForBuilder.get('address_line_1').clearValidators();
      this.signupForBuilder.get('address_line_1').updateValueAndValidity();
      this.signupForBuilder.get('state').clearValidators();
      this.signupForBuilder.get('state').updateValueAndValidity();
      this.signupForBuilder.get('city').clearValidators();
      this.signupForBuilder.get('city').updateValueAndValidity();
      this.signupForBuilder.get('postal_code').clearValidators();
      this.signupForBuilder.get('postal_code').updateValueAndValidity();
        
    }
    

  }

  toggleAgent(type) {
    if(type == '2') {
     this.submittedAgent = true;
     if(this.signupForAgent.get('first_name').valid && this.signupForAgent.get('last_name').valid && this.signupForAgent.get('email').valid && this.signupForAgent.get('password').valid && !this.mismatchPassword && this.signupForAgent.get('business_name').valid && this.signupForAgent.get('license_number').valid && this.signupForAgent.get('broker_name').valid) {
      this.agentStep2 = true;
      this.agentStep1 = false;
     }
    }
    if(type == '1') {
      this.agentStep1 = true;
      this.agentStep2 = false;
    }
  }


  getAllStates(param){
    let promises = [];
    // console.log('statelist') //fecthStateList
    // promises.push(this.commonService.fecthAllStateList(param));
    promises.push(this.commonService.fecthStateList(param));
    Promise.all(promises).then(allData => {
      if (allData && allData.length == 1) {
        if (allData[0] && allData[0].data && allData[0].data.list) {
          this.stateList = allData[0].data.list.map(s => {
            return {
              code: s.state_code,
              text: s.state_name,
            }
          })
        }
       
      }
    })
  }
  
  stateChanged(data) {
		if (data) {
      //this.signupForBuilder.get('state').patchValue(data.state);
      // this.commonService.fecthAllCityList(data).then(cities => {
			this.commonService.fecthCityList(data).then(cities => {
				if (cities && cities.data && cities.data.list) {
					this.cityList = cities.data.list.map(s => {
						return {
							id: s.city_name,
							name: s.city_name,
						}
					})
				}
				
			}).catch(e => {
				
			})
		}
  }
  
  selectEvent(item, type) {
    // console.log(item, 'item 123')
    if(type=='state_code') {
      this.state_code = item.code;
      this.state_name = item.text;
      let param = {
        state : item.code
      }
      this.stateChanged(param)
    } else {
      this.city_name = item.id
    }
   
  }

  onChangeSearch(search: string, type) {
    if(search != '' && type=='state_code'){
      this.city_name = null;
      this.cityList = [];
      this.signupForBuilder.get('city').patchValue('');
      let param = {
        state_name : search
      }
      this.getAllStates(param)
    } else if(search != '' && type=='city_name'){
      let param = {
        state : this.state_code,
        city_name : search
      }
      this.stateChanged(param)
    } else if(search == ''){
      this[type] = null;
    }
    
  }

  onFocused(e) {
    // do something
  }
  onClean(type){
    this[type] = null;
    if(type == 'state_code'){
      this.cityList = []
      this.city_name = null;
    }
    
  }

  buyerMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.signupForm.get('email').setValue(convertedMail)
  }

  builderMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.signupForBuilder.get('email').setValue(convertedMail)
  }

  agentMailCheck(value) {
    let convertedMail = value.toLowerCase( )
    this.signupForAgent.get('email').setValue(convertedMail)
  }

  // private openPaymentModal(template: TemplateRef<any>, options: ModalOptions = { class: 'modal-md', ignoreBackdropClick: true }) {
  //   console.log('open-payment-modal')
  //   this.modalPaymentRef = this.modalService.show(template, options);
  // }

  // closeModal() {
  //   if(this.modalRef) {
  //     this.modalRef.hide();
  //   }
  // }

  // openPaymentTemplate() {
  //   this.openPaymentModal(this.paymentTemplate, { class: 'modal-md', ignoreBackdropClick: true });
  // }

  // paymentModalClose() {
  //   console.log('sdgfg')
      
  //   // this.cancelSignupModal.emit(true);
  //   this.closeSignupModal.emit('incorrect');
  //   if(this.modalPaymentRef) {
  //     console.log('payment-modal close')
  //     this.modalPaymentRef.hide();
  //   }
  // }

  // savePaymentModal(value) {
  //   const params = this.signupForAgent.getRawValue();
  //   console.log('event', value, params);
  //   this.loader.show();
  //   if(value) {
  //       this.paymentModalClose();
  //       params['card_id'] = value['card_id'];
  //       params['stripe_customer_id'] = value['stripe_customer_id'];
  //       params['transaction_id'] = value['transaction_id'];
  //       this.userService.registrationForAgent(params)
  //       .then(response => {
  //         console.log(response);
  //         this.loader.hide();
  //         if (response['data']) {
  //           this.closeSignupModal.emit(response['data']);
  //           this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
  //         } else {
  //           this.closeSignupModal.emit(response['data']);
  //           this.toaster.error(response.message, 'Oops')
  //         }
  //         this.loading = false;
  //       }).catch(err => {
  //         this.loader.hide();
  //         this.loading = false;
  //         if (err.status == 400) {
  //           if (err.error.data.errors.email[0]) {
  //             this.toaster.error(err.error.data.errors.email[0], 'Oops')
  //           } else {
  //             this.toaster.error(err.error.message, 'Oops')
  //           }

  //         } else if (err.message) {
  //           this.toaster.error(err.message, 'Oops')
  //         } else {
  //           this.toaster.error('Something went wrong!', 'Oops')
  //         }

  //       })
  //   }
  // }

  onSubmitAgentSignUpFormWithoutPayment(){
    const params = this.signupForAgent.getRawValue();
     console.log('event', params);
     this.loader.show();
      this.userService.registrationForAgent(params)
      .then(response => {
        console.log(response);
        this.loader.hide();
        if (response['data']) {
          this.closeSignupModal.emit(response['data']);
          this.toaster.success('Registration has been completed successfully, Please verify your email for login.', 'Success')
        } else {
          this.closeSignupModal.emit(response['data']);
          this.toaster.error(response.message, 'Oops')
        }
        this.loading = false;
      }).catch(err => {
        this.loader.hide();
        this.loading = false;
        if (err.status == 400) {
          if (err.error.data.errors.email[0]) {
            this.toaster.error(err.error.data.errors.email[0], 'Oops')
        } else {
            this.toaster.error(err.error.message, 'Oops')
          }

        } else if (err.message) {
          this.toaster.error(err.message, 'Oops')
        } else {
          this.toaster.error('Something went wrong!', 'Oops')
        }
      })
  }

  setAgentFormValue(val) {
    // console.log('val', val)
    this.signupForAgent.patchValue({
        first_name: val.first_name,
        last_name: val.last_name,
        email: val.email,
        password: val.password,
        c_password: val.c_password,
        business_name: val.business_name,
        license_number: val.license_number,
        broker_name: val.broker_name,
        agent_subscription_id: val.agent_subscription_id
    })
  }

  

}
