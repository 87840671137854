import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-popup',
  templateUrl: './ios-popup.component.html',
  styleUrls: ['./ios-popup.component.css']
})
export class IosPopupComponent implements OnInit {

    public showIosPop: Boolean = false;
    constructor() { }

    ngOnInit() {
      setTimeout(() => {
        if (navigator.userAgent.match(/(iPhone|iPad)/i) && navigator.userAgent.match(/WebKit/i)) {
          if (localStorage.getItem('iospop') === null || localStorage.getItem('iospop') === '') {
            this.showIosPop = true;
            setTimeout(() => {
              this.closeIosPop();
            }, 10000);
          }
        }
      }, 5000);
    }

    closeIosPop() {
      this.showIosPop = false;
      localStorage.setItem('iospop', 'DENIEDBYUSER');
    }


}
