import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(rawNum : string) {
    
      let newStr = "";
      if(rawNum) {
        let phoneNumber : string = rawNum.toString();
    
        // for (let i = 0; i < rawNum.length - 1; i++) {
          let first = phoneNumber.charAt(0) + phoneNumber.charAt(1) + phoneNumber.charAt(2);
          let middle = phoneNumber.charAt(3) + phoneNumber.charAt(4) + phoneNumber.charAt(5);
          let last = phoneNumber.charAt(6) + phoneNumber.charAt(7) + phoneNumber.charAt(8) + phoneNumber.charAt(9);
          newStr = '(' + first + ') ' + middle + '-' + last;
        // }

      }
  
      return newStr;
  }

}
